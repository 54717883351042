/**
 * Fx.Spr
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ImportStatus = 'Started' | 'Processing' | 'Failed' | 'Finished' | 'Revoke';

export const ImportStatus = {
    Started: 'Started' as ImportStatus,
    Processing: 'Processing' as ImportStatus,
    Failed: 'Failed' as ImportStatus,
    Finished: 'Finished' as ImportStatus,
    Revoke: 'Revoke' as ImportStatus
};

