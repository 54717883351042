import { Nullable } from '@lib-utils';

export interface RenderedServicesActDto {
  partnerRepresentative?: Nullable<string>;
  representativeDocument?: Nullable<string>;
  bankRepresentativeRole?: Nullable<string>;
  bankRepresentative?: Nullable<string>;
  bankRepresentativeForSign?: Nullable<string>;
  tax?: Nullable<string>;
  signerRole?: Nullable<string>;
  signer?: Nullable<string>;
}
