import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { PaginatedResult } from '@lib-widgets/grid';
import {
  autoAgentChangesListMock,
  autoAgentChangesMock,
  autoAgentsKbMock,
  autoAgentsMock,
  autoDealerMock,
  autoDealerRequisitesMock,
  autoDealersMock,
  autoOrganizationsMock,
  autoShowroomsMock,
} from '../mocks';
import { AutoAgentChangesListDto, AutoDealerDto } from '../models';

@Injectable({
  providedIn: 'root',
})
export class AutoAdminService {
  isMonthActExportActive$ = new BehaviorSubject(false);

  getAgentChangesList(page: number, perPage: number): Observable<PaginatedResult<AutoAgentChangesListDto>> {
    return of({
      data: autoAgentChangesListMock,
      page: {
        pages: 10,
        items: 1000,
        page,
        perPage,
      },
    }).pipe(delay(800));
  }

  getDealersList(page: number, perPage: number): Observable<PaginatedResult<AutoDealerDto>> {
    return of({
      data: autoDealersMock,
      page: {
        pages: 10,
        items: 1000,
        page,
        perPage,
      },
    }).pipe(delay(500));
  }

  getAgentChange(_id: number) {
    return of({ data: autoAgentChangesMock }).pipe(delay(200));
  }

  getDealer(_id: number) {
    return of(autoDealerMock).pipe(delay(200));
  }

  getOrganizations(_term: string | null) {
    return of({ data: autoOrganizationsMock }).pipe(delay(500));
  }

  getRequisites(_term: string | null) {
    return of({ data: autoDealerRequisitesMock }).pipe(delay(500));
  }

  getShowrooms(_term: string | null) {
    return of({ data: autoShowroomsMock }).pipe(delay(500));
  }

  getAgents(_term: string | null) {
    return of({ data: autoAgentsMock }).pipe(delay(500));
  }

  getAgentsKb(_term: string | null) {
    return of({ data: autoAgentsKbMock }).pipe(delay(500));
  }
}
