/**
 * FxAuto.Lookup.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DataCreditProductDto } from './dataCreditProductDto';
import { DealerOfficialStatus } from './dealerOfficialStatus';
import { State } from './state';
import { DataMakeDto } from './dataMakeDto';
import { DataOrganizationBranchDto } from './dataOrganizationBranchDto';
import { DataAdditionalProductDto } from './dataAdditionalProductDto';
import { DataShowroomDto } from './dataShowroomDto';


export interface DataDealerDto { 
    id?: number;
    legacyId?: number | null;
    state?: State;
    name?: string | null;
    officialStatus?: DealerOfficialStatus;
    clusterName?: string | null;
    companyGroupName?: string | null;
    showrooms?: Array<DataShowroomDto> | null;
    organizationBranches?: Array<DataOrganizationBranchDto> | null;
    defaultOrganizationBranch?: DataOrganizationBranchDto;
    creditProducts?: Array<DataCreditProductDto> | null;
    makes?: Array<DataMakeDto> | null;
    additionalProducts?: Array<DataAdditionalProductDto> | null;
}
export namespace DataDealerDto {
}


