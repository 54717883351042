/**
 * Fx.Spr
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type LayoutKind = 'Sequential' | 'Explicit' | 'Auto';

export const LayoutKind = {
    Sequential: 'Sequential' as LayoutKind,
    Explicit: 'Explicit' as LayoutKind,
    Auto: 'Auto' as LayoutKind
};

