/**
 * FxAuto.Lookup.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { DataBankDetailsDto } from '../model/dataBankDetailsDto';
// @ts-ignore
import { DataCreateBankDetailsDto } from '../model/dataCreateBankDetailsDto';
// @ts-ignore
import { DataDealerDto } from '../model/dataDealerDto';
// @ts-ignore
import { DataLinkShowroomsBranchDto } from '../model/dataLinkShowroomsBranchDto';
// @ts-ignore
import { DataOrganizationBranchDto } from '../model/dataOrganizationBranchDto';
// @ts-ignore
import { DataOrganizationDto } from '../model/dataOrganizationDto';
// @ts-ignore
import { DataSetBankDetailsStateDto } from '../model/dataSetBankDetailsStateDto';
// @ts-ignore
import { DataSetDealerAdditionalProductDto } from '../model/dataSetDealerAdditionalProductDto';
// @ts-ignore
import { DataSetDealerDto } from '../model/dataSetDealerDto';
// @ts-ignore
import { DataSetOrganizationBranchCftIdDto } from '../model/dataSetOrganizationBranchCftIdDto';
// @ts-ignore
import { DataSetOrganizationBranchStateDto } from '../model/dataSetOrganizationBranchStateDto';
// @ts-ignore
import { DataSetShowroomDto } from '../model/dataSetShowroomDto';
// @ts-ignore
import { DataSetUserDto } from '../model/dataSetUserDto';
// @ts-ignore
import { DataShowroomDto } from '../model/dataShowroomDto';
// @ts-ignore
import { DataSubstringSearchDto } from '../model/dataSubstringSearchDto';
// @ts-ignore
import { DataUserInfoDto } from '../model/dataUserInfoDto';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { AutoLookupConfiguration }                                     from '../configuration';



@Injectable()
export class DataApiService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new AutoLookupConfiguration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: AutoLookupConfiguration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
            if (firstBasePath != undefined) {
                basePath = firstBasePath;
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (key != null) {
                httpParams = httpParams.append(key, JSON.stringify(value));
            } else {
                throw Error("key may not be null if value is a QueryParamObject");
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Создание банковских реквизитов
     * &lt;p&gt;Required permission: &lt;ul&gt;&lt;li&gt;&lt;b&gt;CabinetAdminAuto &#x3D; CABINET.ADMIN.AUTO&lt;/b&gt;&lt;/li&gt;&lt;/ul&gt;&lt;/p&gt;
     * @param xVersion 
     * @param dataCreateBankDetailsDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dataBankDetailsPost(xVersion: string, dataCreateBankDetailsDto?: DataCreateBankDetailsDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<DataBankDetailsDto>;
    public dataBankDetailsPost(xVersion: string, dataCreateBankDetailsDto?: DataCreateBankDetailsDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<DataBankDetailsDto>>;
    public dataBankDetailsPost(xVersion: string, dataCreateBankDetailsDto?: DataCreateBankDetailsDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<DataBankDetailsDto>>;
    public dataBankDetailsPost(xVersion: string, dataCreateBankDetailsDto?: DataCreateBankDetailsDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (xVersion === null || xVersion === undefined) {
            throw new Error('Required parameter xVersion was null or undefined when calling dataBankDetailsPost.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xVersion !== undefined && xVersion !== null) {
            localVarHeaders = localVarHeaders.set('x-Version', String(xVersion));
        }

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/Data/BankDetails`;
        return this.httpClient.request<DataBankDetailsDto>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: dataCreateBankDetailsDto,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Редактирование состояния банковских реквизитов
     * &lt;p&gt;Required permission: &lt;ul&gt;&lt;li&gt;&lt;b&gt;CabinetAdminAuto &#x3D; CABINET.ADMIN.AUTO&lt;/b&gt;&lt;/li&gt;&lt;/ul&gt;&lt;/p&gt;
     * @param xVersion 
     * @param dataSetBankDetailsStateDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dataBankDetailsSetStatePost(xVersion: string, dataSetBankDetailsStateDto?: DataSetBankDetailsStateDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<DataBankDetailsDto>;
    public dataBankDetailsSetStatePost(xVersion: string, dataSetBankDetailsStateDto?: DataSetBankDetailsStateDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<DataBankDetailsDto>>;
    public dataBankDetailsSetStatePost(xVersion: string, dataSetBankDetailsStateDto?: DataSetBankDetailsStateDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<DataBankDetailsDto>>;
    public dataBankDetailsSetStatePost(xVersion: string, dataSetBankDetailsStateDto?: DataSetBankDetailsStateDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (xVersion === null || xVersion === undefined) {
            throw new Error('Required parameter xVersion was null or undefined when calling dataBankDetailsSetStatePost.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xVersion !== undefined && xVersion !== null) {
            localVarHeaders = localVarHeaders.set('x-Version', String(xVersion));
        }

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/Data/BankDetails/SetState`;
        return this.httpClient.request<DataBankDetailsDto>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: dataSetBankDetailsStateDto,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Информация о дилере по Id
     * &lt;p&gt;Required permission: &lt;ul&gt;&lt;li&gt;&lt;b&gt;CabinetAdminAuto &#x3D; CABINET.ADMIN.AUTO&lt;/b&gt;&lt;/li&gt;&lt;/ul&gt;&lt;/p&gt;
     * @param id 
     * @param isLegacyId 
     * @param xVersion 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dataDealerIdIsLegacyIdGet(id: number, isLegacyId: boolean, xVersion: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<DataDealerDto>;
    public dataDealerIdIsLegacyIdGet(id: number, isLegacyId: boolean, xVersion: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<DataDealerDto>>;
    public dataDealerIdIsLegacyIdGet(id: number, isLegacyId: boolean, xVersion: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<DataDealerDto>>;
    public dataDealerIdIsLegacyIdGet(id: number, isLegacyId: boolean, xVersion: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling dataDealerIdIsLegacyIdGet.');
        }
        if (isLegacyId === null || isLegacyId === undefined) {
            throw new Error('Required parameter isLegacyId was null or undefined when calling dataDealerIdIsLegacyIdGet.');
        }
        if (xVersion === null || xVersion === undefined) {
            throw new Error('Required parameter xVersion was null or undefined when calling dataDealerIdIsLegacyIdGet.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xVersion !== undefined && xVersion !== null) {
            localVarHeaders = localVarHeaders.set('x-Version', String(xVersion));
        }

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/Data/Dealer/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/${this.configuration.encodeParam({name: "isLegacyId", value: isLegacyId, in: "path", style: "simple", explode: false, dataType: "boolean", dataFormat: undefined})}`;
        return this.httpClient.request<DataDealerDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Создание/редактирование дилера
     * &lt;p&gt;Required permission: &lt;ul&gt;&lt;li&gt;&lt;b&gt;CabinetAdminAuto &#x3D; CABINET.ADMIN.AUTO&lt;/b&gt;&lt;/li&gt;&lt;/ul&gt;&lt;/p&gt;
     * @param xVersion 
     * @param dataSetDealerDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dataDealerPost(xVersion: string, dataSetDealerDto?: DataSetDealerDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<DataDealerDto>;
    public dataDealerPost(xVersion: string, dataSetDealerDto?: DataSetDealerDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<DataDealerDto>>;
    public dataDealerPost(xVersion: string, dataSetDealerDto?: DataSetDealerDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<DataDealerDto>>;
    public dataDealerPost(xVersion: string, dataSetDealerDto?: DataSetDealerDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (xVersion === null || xVersion === undefined) {
            throw new Error('Required parameter xVersion was null or undefined when calling dataDealerPost.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xVersion !== undefined && xVersion !== null) {
            localVarHeaders = localVarHeaders.set('x-Version', String(xVersion));
        }

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/Data/Dealer`;
        return this.httpClient.request<DataDealerDto>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: dataSetDealerDto,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Редактирование/добавление доп. продукта для дилера
     * &lt;p&gt;Required permission: &lt;ul&gt;&lt;li&gt;&lt;b&gt;CabinetAdminAuto &#x3D; CABINET.ADMIN.AUTO&lt;/b&gt;&lt;/li&gt;&lt;/ul&gt;&lt;/p&gt;
     * @param xVersion 
     * @param dataSetDealerAdditionalProductDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dataDealerSaveAdditionalProductPost(xVersion: string, dataSetDealerAdditionalProductDto?: DataSetDealerAdditionalProductDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public dataDealerSaveAdditionalProductPost(xVersion: string, dataSetDealerAdditionalProductDto?: DataSetDealerAdditionalProductDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public dataDealerSaveAdditionalProductPost(xVersion: string, dataSetDealerAdditionalProductDto?: DataSetDealerAdditionalProductDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public dataDealerSaveAdditionalProductPost(xVersion: string, dataSetDealerAdditionalProductDto?: DataSetDealerAdditionalProductDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (xVersion === null || xVersion === undefined) {
            throw new Error('Required parameter xVersion was null or undefined when calling dataDealerSaveAdditionalProductPost.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xVersion !== undefined && xVersion !== null) {
            localVarHeaders = localVarHeaders.set('x-Version', String(xVersion));
        }

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/Data/Dealer/SaveAdditionalProduct`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: dataSetDealerAdditionalProductDto,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Информация о филиале по Id
     * &lt;p&gt;Required permission: &lt;ul&gt;&lt;li&gt;&lt;b&gt;CabinetAdminAuto &#x3D; CABINET.ADMIN.AUTO&lt;/b&gt;&lt;/li&gt;&lt;/ul&gt;&lt;/p&gt;
     * @param id 
     * @param isLegacyId 
     * @param xVersion 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dataOrganizationBranchIdIsLegacyIdGet(id: number, isLegacyId: boolean, xVersion: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<DataOrganizationBranchDto>;
    public dataOrganizationBranchIdIsLegacyIdGet(id: number, isLegacyId: boolean, xVersion: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<DataOrganizationBranchDto>>;
    public dataOrganizationBranchIdIsLegacyIdGet(id: number, isLegacyId: boolean, xVersion: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<DataOrganizationBranchDto>>;
    public dataOrganizationBranchIdIsLegacyIdGet(id: number, isLegacyId: boolean, xVersion: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling dataOrganizationBranchIdIsLegacyIdGet.');
        }
        if (isLegacyId === null || isLegacyId === undefined) {
            throw new Error('Required parameter isLegacyId was null or undefined when calling dataOrganizationBranchIdIsLegacyIdGet.');
        }
        if (xVersion === null || xVersion === undefined) {
            throw new Error('Required parameter xVersion was null or undefined when calling dataOrganizationBranchIdIsLegacyIdGet.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xVersion !== undefined && xVersion !== null) {
            localVarHeaders = localVarHeaders.set('x-Version', String(xVersion));
        }

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/Data/OrganizationBranch/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/${this.configuration.encodeParam({name: "isLegacyId", value: isLegacyId, in: "path", style: "simple", explode: false, dataType: "boolean", dataFormat: undefined})}`;
        return this.httpClient.request<DataOrganizationBranchDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Автоматическое обновление адреса филиала по Id (из Дадаты)
     * &lt;p&gt;Required permission: &lt;ul&gt;&lt;li&gt;&lt;b&gt;CabinetAdminAuto &#x3D; CABINET.ADMIN.AUTO&lt;/b&gt;&lt;/li&gt;&lt;/ul&gt;&lt;/p&gt;
     * @param id 
     * @param xVersion 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dataOrganizationBranchIdUpdateAddressPost(id: number, xVersion: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<DataOrganizationBranchDto>;
    public dataOrganizationBranchIdUpdateAddressPost(id: number, xVersion: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<DataOrganizationBranchDto>>;
    public dataOrganizationBranchIdUpdateAddressPost(id: number, xVersion: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<DataOrganizationBranchDto>>;
    public dataOrganizationBranchIdUpdateAddressPost(id: number, xVersion: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling dataOrganizationBranchIdUpdateAddressPost.');
        }
        if (xVersion === null || xVersion === undefined) {
            throw new Error('Required parameter xVersion was null or undefined when calling dataOrganizationBranchIdUpdateAddressPost.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xVersion !== undefined && xVersion !== null) {
            localVarHeaders = localVarHeaders.set('x-Version', String(xVersion));
        }

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/Data/OrganizationBranch/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/UpdateAddress`;
        return this.httpClient.request<DataOrganizationBranchDto>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Поиск филиалов по подстроке
     * &lt;p&gt;Required permission: &lt;ul&gt;&lt;li&gt;&lt;b&gt;CabinetAdminAuto &#x3D; CABINET.ADMIN.AUTO&lt;/b&gt;&lt;/li&gt;&lt;/ul&gt;&lt;/p&gt;
     * @param xVersion 
     * @param dataSubstringSearchDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dataOrganizationBranchSearchPost(xVersion: string, dataSubstringSearchDto?: DataSubstringSearchDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<Array<DataOrganizationBranchDto>>;
    public dataOrganizationBranchSearchPost(xVersion: string, dataSubstringSearchDto?: DataSubstringSearchDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Array<DataOrganizationBranchDto>>>;
    public dataOrganizationBranchSearchPost(xVersion: string, dataSubstringSearchDto?: DataSubstringSearchDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Array<DataOrganizationBranchDto>>>;
    public dataOrganizationBranchSearchPost(xVersion: string, dataSubstringSearchDto?: DataSubstringSearchDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (xVersion === null || xVersion === undefined) {
            throw new Error('Required parameter xVersion was null or undefined when calling dataOrganizationBranchSearchPost.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xVersion !== undefined && xVersion !== null) {
            localVarHeaders = localVarHeaders.set('x-Version', String(xVersion));
        }

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/Data/OrganizationBranch/Search`;
        return this.httpClient.request<Array<DataOrganizationBranchDto>>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: dataSubstringSearchDto,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Установка CFT Id филиала организации
     * &lt;p&gt;Required permission: &lt;ul&gt;&lt;li&gt;&lt;b&gt;CabinetAdminAuto &#x3D; CABINET.ADMIN.AUTO&lt;/b&gt;&lt;/li&gt;&lt;/ul&gt;&lt;/p&gt;
     * @param xVersion 
     * @param dataSetOrganizationBranchCftIdDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dataOrganizationBranchSetCftIdPost(xVersion: string, dataSetOrganizationBranchCftIdDto?: DataSetOrganizationBranchCftIdDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<DataOrganizationBranchDto>;
    public dataOrganizationBranchSetCftIdPost(xVersion: string, dataSetOrganizationBranchCftIdDto?: DataSetOrganizationBranchCftIdDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<DataOrganizationBranchDto>>;
    public dataOrganizationBranchSetCftIdPost(xVersion: string, dataSetOrganizationBranchCftIdDto?: DataSetOrganizationBranchCftIdDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<DataOrganizationBranchDto>>;
    public dataOrganizationBranchSetCftIdPost(xVersion: string, dataSetOrganizationBranchCftIdDto?: DataSetOrganizationBranchCftIdDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (xVersion === null || xVersion === undefined) {
            throw new Error('Required parameter xVersion was null or undefined when calling dataOrganizationBranchSetCftIdPost.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xVersion !== undefined && xVersion !== null) {
            localVarHeaders = localVarHeaders.set('x-Version', String(xVersion));
        }

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/Data/OrganizationBranch/SetCftId`;
        return this.httpClient.request<DataOrganizationBranchDto>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: dataSetOrganizationBranchCftIdDto,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Редактирование состояния филиала организации
     * &lt;p&gt;Required permission: &lt;ul&gt;&lt;li&gt;&lt;b&gt;CabinetAdminAuto &#x3D; CABINET.ADMIN.AUTO&lt;/b&gt;&lt;/li&gt;&lt;/ul&gt;&lt;/p&gt;
     * @param xVersion 
     * @param dataSetOrganizationBranchStateDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dataOrganizationBranchSetStatePost(xVersion: string, dataSetOrganizationBranchStateDto?: DataSetOrganizationBranchStateDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<DataOrganizationBranchDto>;
    public dataOrganizationBranchSetStatePost(xVersion: string, dataSetOrganizationBranchStateDto?: DataSetOrganizationBranchStateDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<DataOrganizationBranchDto>>;
    public dataOrganizationBranchSetStatePost(xVersion: string, dataSetOrganizationBranchStateDto?: DataSetOrganizationBranchStateDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<DataOrganizationBranchDto>>;
    public dataOrganizationBranchSetStatePost(xVersion: string, dataSetOrganizationBranchStateDto?: DataSetOrganizationBranchStateDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (xVersion === null || xVersion === undefined) {
            throw new Error('Required parameter xVersion was null or undefined when calling dataOrganizationBranchSetStatePost.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xVersion !== undefined && xVersion !== null) {
            localVarHeaders = localVarHeaders.set('x-Version', String(xVersion));
        }

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/Data/OrganizationBranch/SetState`;
        return this.httpClient.request<DataOrganizationBranchDto>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: dataSetOrganizationBranchStateDto,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Информация об организации по ИНН
     * &lt;p&gt;Required permission: &lt;ul&gt;&lt;li&gt;&lt;b&gt;CabinetAdminAuto &#x3D; CABINET.ADMIN.AUTO&lt;/b&gt;&lt;/li&gt;&lt;/ul&gt;&lt;/p&gt;
     * @param inn 
     * @param xVersion 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dataOrganizationInnGet(inn: string, xVersion: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<DataOrganizationDto>;
    public dataOrganizationInnGet(inn: string, xVersion: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<DataOrganizationDto>>;
    public dataOrganizationInnGet(inn: string, xVersion: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<DataOrganizationDto>>;
    public dataOrganizationInnGet(inn: string, xVersion: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (inn === null || inn === undefined) {
            throw new Error('Required parameter inn was null or undefined when calling dataOrganizationInnGet.');
        }
        if (xVersion === null || xVersion === undefined) {
            throw new Error('Required parameter xVersion was null or undefined when calling dataOrganizationInnGet.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xVersion !== undefined && xVersion !== null) {
            localVarHeaders = localVarHeaders.set('x-Version', String(xVersion));
        }

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/Data/Organization/${this.configuration.encodeParam({name: "inn", value: inn, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<DataOrganizationDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Информация об автосалоне по Id
     * &lt;p&gt;Required permission: &lt;ul&gt;&lt;li&gt;&lt;b&gt;CabinetAdminAuto &#x3D; CABINET.ADMIN.AUTO&lt;/b&gt;&lt;/li&gt;&lt;/ul&gt;&lt;/p&gt;
     * @param id 
     * @param isLegacyId 
     * @param xVersion 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dataShowroomIdIsLegacyIdGet(id: number, isLegacyId: boolean, xVersion: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<DataShowroomDto>;
    public dataShowroomIdIsLegacyIdGet(id: number, isLegacyId: boolean, xVersion: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<DataShowroomDto>>;
    public dataShowroomIdIsLegacyIdGet(id: number, isLegacyId: boolean, xVersion: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<DataShowroomDto>>;
    public dataShowroomIdIsLegacyIdGet(id: number, isLegacyId: boolean, xVersion: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling dataShowroomIdIsLegacyIdGet.');
        }
        if (isLegacyId === null || isLegacyId === undefined) {
            throw new Error('Required parameter isLegacyId was null or undefined when calling dataShowroomIdIsLegacyIdGet.');
        }
        if (xVersion === null || xVersion === undefined) {
            throw new Error('Required parameter xVersion was null or undefined when calling dataShowroomIdIsLegacyIdGet.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xVersion !== undefined && xVersion !== null) {
            localVarHeaders = localVarHeaders.set('x-Version', String(xVersion));
        }

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/Data/Showroom/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/${this.configuration.encodeParam({name: "isLegacyId", value: isLegacyId, in: "path", style: "simple", explode: false, dataType: "boolean", dataFormat: undefined})}`;
        return this.httpClient.request<DataShowroomDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Линковка автосалонов и банковских реквизитов филиала
     * &lt;p&gt;Required permission: &lt;ul&gt;&lt;li&gt;&lt;b&gt;CabinetAdminAuto &#x3D; CABINET.ADMIN.AUTO&lt;/b&gt;&lt;/li&gt;&lt;/ul&gt;&lt;/p&gt;
     * @param xVersion 
     * @param dataLinkShowroomsBranchDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dataShowroomLinkBranchWithShowroomsPost(xVersion: string, dataLinkShowroomsBranchDto?: DataLinkShowroomsBranchDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public dataShowroomLinkBranchWithShowroomsPost(xVersion: string, dataLinkShowroomsBranchDto?: DataLinkShowroomsBranchDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public dataShowroomLinkBranchWithShowroomsPost(xVersion: string, dataLinkShowroomsBranchDto?: DataLinkShowroomsBranchDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public dataShowroomLinkBranchWithShowroomsPost(xVersion: string, dataLinkShowroomsBranchDto?: DataLinkShowroomsBranchDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (xVersion === null || xVersion === undefined) {
            throw new Error('Required parameter xVersion was null or undefined when calling dataShowroomLinkBranchWithShowroomsPost.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xVersion !== undefined && xVersion !== null) {
            localVarHeaders = localVarHeaders.set('x-Version', String(xVersion));
        }

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/Data/Showroom/LinkBranchWithShowrooms`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: dataLinkShowroomsBranchDto,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Создание/редактирование автосалона
     * &lt;p&gt;Required permission: &lt;ul&gt;&lt;li&gt;&lt;b&gt;CabinetAdminAuto &#x3D; CABINET.ADMIN.AUTO&lt;/b&gt;&lt;/li&gt;&lt;/ul&gt;&lt;/p&gt;
     * @param xVersion 
     * @param dataSetShowroomDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dataShowroomPost(xVersion: string, dataSetShowroomDto?: DataSetShowroomDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<DataShowroomDto>;
    public dataShowroomPost(xVersion: string, dataSetShowroomDto?: DataSetShowroomDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<DataShowroomDto>>;
    public dataShowroomPost(xVersion: string, dataSetShowroomDto?: DataSetShowroomDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<DataShowroomDto>>;
    public dataShowroomPost(xVersion: string, dataSetShowroomDto?: DataSetShowroomDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (xVersion === null || xVersion === undefined) {
            throw new Error('Required parameter xVersion was null or undefined when calling dataShowroomPost.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xVersion !== undefined && xVersion !== null) {
            localVarHeaders = localVarHeaders.set('x-Version', String(xVersion));
        }

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/Data/Showroom`;
        return this.httpClient.request<DataShowroomDto>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: dataSetShowroomDto,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Линковка банковских реквизитов к автосалону
     * &lt;p&gt;Required permission: &lt;ul&gt;&lt;li&gt;&lt;b&gt;CabinetAdminAuto &#x3D; CABINET.ADMIN.AUTO&lt;/b&gt;&lt;/li&gt;&lt;/ul&gt;&lt;/p&gt;
     * @param showroomId 
     * @param bankDetailsId 
     * @param xVersion 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dataShowroomShowroomIdLinkBankDetailsBankDetailsIdPost(showroomId: number, bankDetailsId: number, xVersion: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public dataShowroomShowroomIdLinkBankDetailsBankDetailsIdPost(showroomId: number, bankDetailsId: number, xVersion: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public dataShowroomShowroomIdLinkBankDetailsBankDetailsIdPost(showroomId: number, bankDetailsId: number, xVersion: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public dataShowroomShowroomIdLinkBankDetailsBankDetailsIdPost(showroomId: number, bankDetailsId: number, xVersion: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (showroomId === null || showroomId === undefined) {
            throw new Error('Required parameter showroomId was null or undefined when calling dataShowroomShowroomIdLinkBankDetailsBankDetailsIdPost.');
        }
        if (bankDetailsId === null || bankDetailsId === undefined) {
            throw new Error('Required parameter bankDetailsId was null or undefined when calling dataShowroomShowroomIdLinkBankDetailsBankDetailsIdPost.');
        }
        if (xVersion === null || xVersion === undefined) {
            throw new Error('Required parameter xVersion was null or undefined when calling dataShowroomShowroomIdLinkBankDetailsBankDetailsIdPost.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xVersion !== undefined && xVersion !== null) {
            localVarHeaders = localVarHeaders.set('x-Version', String(xVersion));
        }

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/Data/Showroom/${this.configuration.encodeParam({name: "showroomId", value: showroomId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/LinkBankDetails/${this.configuration.encodeParam({name: "bankDetailsId", value: bankDetailsId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Линковка юзера к автосалону
     * &lt;p&gt;Required permission: &lt;ul&gt;&lt;li&gt;&lt;b&gt;CabinetAdminAuto &#x3D; CABINET.ADMIN.AUTO&lt;/b&gt;&lt;/li&gt;&lt;/ul&gt;&lt;/p&gt;
     * @param showroomId 
     * @param userId 
     * @param xVersion 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dataShowroomShowroomIdLinkUserUserIdPost(showroomId: number, userId: number, xVersion: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public dataShowroomShowroomIdLinkUserUserIdPost(showroomId: number, userId: number, xVersion: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public dataShowroomShowroomIdLinkUserUserIdPost(showroomId: number, userId: number, xVersion: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public dataShowroomShowroomIdLinkUserUserIdPost(showroomId: number, userId: number, xVersion: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (showroomId === null || showroomId === undefined) {
            throw new Error('Required parameter showroomId was null or undefined when calling dataShowroomShowroomIdLinkUserUserIdPost.');
        }
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling dataShowroomShowroomIdLinkUserUserIdPost.');
        }
        if (xVersion === null || xVersion === undefined) {
            throw new Error('Required parameter xVersion was null or undefined when calling dataShowroomShowroomIdLinkUserUserIdPost.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xVersion !== undefined && xVersion !== null) {
            localVarHeaders = localVarHeaders.set('x-Version', String(xVersion));
        }

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/Data/Showroom/${this.configuration.encodeParam({name: "showroomId", value: showroomId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/LinkUser/${this.configuration.encodeParam({name: "userId", value: userId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Отлинковка банковских реквизитов от автосалона
     * &lt;p&gt;Required permission: &lt;ul&gt;&lt;li&gt;&lt;b&gt;CabinetAdminAuto &#x3D; CABINET.ADMIN.AUTO&lt;/b&gt;&lt;/li&gt;&lt;/ul&gt;&lt;/p&gt;
     * @param showroomId 
     * @param bankDetailsId 
     * @param xVersion 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dataShowroomShowroomIdUnlinkBankDetailsBankDetailsIdPost(showroomId: number, bankDetailsId: number, xVersion: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public dataShowroomShowroomIdUnlinkBankDetailsBankDetailsIdPost(showroomId: number, bankDetailsId: number, xVersion: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public dataShowroomShowroomIdUnlinkBankDetailsBankDetailsIdPost(showroomId: number, bankDetailsId: number, xVersion: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public dataShowroomShowroomIdUnlinkBankDetailsBankDetailsIdPost(showroomId: number, bankDetailsId: number, xVersion: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (showroomId === null || showroomId === undefined) {
            throw new Error('Required parameter showroomId was null or undefined when calling dataShowroomShowroomIdUnlinkBankDetailsBankDetailsIdPost.');
        }
        if (bankDetailsId === null || bankDetailsId === undefined) {
            throw new Error('Required parameter bankDetailsId was null or undefined when calling dataShowroomShowroomIdUnlinkBankDetailsBankDetailsIdPost.');
        }
        if (xVersion === null || xVersion === undefined) {
            throw new Error('Required parameter xVersion was null or undefined when calling dataShowroomShowroomIdUnlinkBankDetailsBankDetailsIdPost.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xVersion !== undefined && xVersion !== null) {
            localVarHeaders = localVarHeaders.set('x-Version', String(xVersion));
        }

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/Data/Showroom/${this.configuration.encodeParam({name: "showroomId", value: showroomId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/UnlinkBankDetails/${this.configuration.encodeParam({name: "bankDetailsId", value: bankDetailsId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Отлинковка юзера от автосалона
     * &lt;p&gt;Required permission: &lt;ul&gt;&lt;li&gt;&lt;b&gt;CabinetAdminAuto &#x3D; CABINET.ADMIN.AUTO&lt;/b&gt;&lt;/li&gt;&lt;/ul&gt;&lt;/p&gt;
     * @param showroomId 
     * @param userId 
     * @param xVersion 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dataShowroomShowroomIdUnlinkUserUserIdPost(showroomId: number, userId: number, xVersion: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public dataShowroomShowroomIdUnlinkUserUserIdPost(showroomId: number, userId: number, xVersion: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public dataShowroomShowroomIdUnlinkUserUserIdPost(showroomId: number, userId: number, xVersion: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public dataShowroomShowroomIdUnlinkUserUserIdPost(showroomId: number, userId: number, xVersion: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (showroomId === null || showroomId === undefined) {
            throw new Error('Required parameter showroomId was null or undefined when calling dataShowroomShowroomIdUnlinkUserUserIdPost.');
        }
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling dataShowroomShowroomIdUnlinkUserUserIdPost.');
        }
        if (xVersion === null || xVersion === undefined) {
            throw new Error('Required parameter xVersion was null or undefined when calling dataShowroomShowroomIdUnlinkUserUserIdPost.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xVersion !== undefined && xVersion !== null) {
            localVarHeaders = localVarHeaders.set('x-Version', String(xVersion));
        }

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/Data/Showroom/${this.configuration.encodeParam({name: "showroomId", value: showroomId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/UnlinkUser/${this.configuration.encodeParam({name: "userId", value: userId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Создание/редактирование пользователя по email
     * &lt;p&gt;Required permission: &lt;ul&gt;&lt;li&gt;&lt;b&gt;CabinetAdminAuto &#x3D; CABINET.ADMIN.AUTO&lt;/b&gt;&lt;/li&gt;&lt;/ul&gt;&lt;/p&gt;
     * @param xVersion 
     * @param dataSetUserDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dataUserPost(xVersion: string, dataSetUserDto?: DataSetUserDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<DataUserInfoDto>;
    public dataUserPost(xVersion: string, dataSetUserDto?: DataSetUserDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<DataUserInfoDto>>;
    public dataUserPost(xVersion: string, dataSetUserDto?: DataSetUserDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<DataUserInfoDto>>;
    public dataUserPost(xVersion: string, dataSetUserDto?: DataSetUserDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (xVersion === null || xVersion === undefined) {
            throw new Error('Required parameter xVersion was null or undefined when calling dataUserPost.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xVersion !== undefined && xVersion !== null) {
            localVarHeaders = localVarHeaders.set('x-Version', String(xVersion));
        }

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/Data/User`;
        return this.httpClient.request<DataUserInfoDto>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: dataSetUserDto,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

}
