import { CreditProductType } from '@lib-auto/api';
import {
  AdminStatus,
  AutoAgentChangesDto,
  AutoAgentChangesListDto,
  AutoAgentDto,
  AutoAgentRole,
  AutoDealerDto,
  AutoDealerOrganizationDto,
  AutoDealerRequisitesDto,
  AutoDealerShowroomDto,
  RewardRecepientDto,
} from '../models';

export const autoAgentChangesListMock: AutoAgentChangesListDto[] = [
  {
    id: 1234,
    dealerName: 'МСК ГК Иванов Петр Иванович',
    dealerId: 123,
    agentId: 12,
    author: 'Иванов Иван Иванович',
    requestedDate: '2022-12-27T07:20:26.637855Z',
  },
  {
    id: 1235,
    dealerName: 'МСК ГК Иванов Петр Иванович',
    dealerId: 123,
    agentId: 12,
    author: 'Иванов Иван Иванович',
    requestedDate: '2022-12-27T07:20:26.637855Z',
  },
  {
    id: 1236,
    dealerName: 'МСК ГК Иванов Петр Иванович',
    dealerId: 123,
    agentId: 12,
    author: 'Иванов Иван Иванович',
    requestedDate: '2022-12-27T07:20:26.637855Z',
  },
];

export const autoAgentChangesMock: AutoAgentChangesDto = {
  id: 1234,
  dealerName: 'МСК ГК Иванов Петр Иванович',
  dealerId: 123,
  agentId: 12,
  current: {
    id: 12,
    fullName: 'Иванов Иван Иванович',
    status: AdminStatus.Active,
    role: AutoAgentRole.RewardForCredit,
    updated: '2022-12-27T07:00:26.637855Z',
    showrooms: [
      {
        id: 8785,
        name: 'ООО "ПАРИТЕТ"',
        address: 'г.Москва, Новоясеневский пр-кт, д.6 к3',
      },
    ],
  },
  requested: {
    id: 12,
    fullName: 'Иванов Петр Иванович',
    status: AdminStatus.Active,
    role: AutoAgentRole.RewardForCredit,
    showrooms: [
      {
        id: 8785,
        name: 'ООО "ПАРИТЕТ"',
        address: 'г.Москва, Новоясеневский пр-кт, д.6 к3',
      },
      {
        id: 8786,
        name: 'ООО "ПАРИТЕТ"',
        address: 'г.Москва, Новоясеневский пр-кт, д.6 к3',
      },
    ],
  },
  author: 'Иванов Иван Иванович',
  requestedDate: '2022-12-27T07:20:26.637855Z',
};

export const autoDealersMock: AutoDealerDto[] = [
  {
    id: 1234,
    contactPerson: {
      fullName: 'Иванов Петр Иванович',
    },
    dealerName: 'OOO "ПАРИТЕТ"',
    status: AdminStatus.Active,
    cluster: 'Кластер 1',
  },
  {
    id: 1235,
    contactPerson: {
      fullName: 'Иванов Петр Иванович',
    },
    dealerName: 'OOO "ПАРИТЕТ"',
    status: AdminStatus.Active,
    cluster: 'Кластер 2',
  },
  {
    id: 1236,
    contactPerson: {
      fullName: 'Иванов Петр Иванович',
    },
    dealerName: 'OOO "ПАРИТЕТ"',
    status: AdminStatus.Inactive,
    cluster: 'Кластер 1',
  },
  {
    id: 1237,
    contactPerson: {
      fullName: 'Иванов Петр Иванович',
    },
    dealerName: 'OOO "ПАРИТЕТ"',
    status: AdminStatus.Inactive,
    cluster: 'Кластер 2',
  },
  {
    id: 1238,
    contactPerson: {
      fullName: 'Иванов Петр Иванович',
    },
    dealerName: 'OOO "ПАРИТЕТ"',
    status: AdminStatus.Blocked,
    cluster: 'Кластер 1',
  },
  {
    id: 1239,
    contactPerson: {
      fullName: 'Иванов Петр Иванович',
    },
    dealerName: 'OOO "ПАРИТЕТ"',
    status: AdminStatus.Inactive,
    cluster: 'Кластер 2',
  },
  {
    id: 1240,
    contactPerson: {
      fullName: 'Иванов Петр Иванович',
    },
    dealerName: 'OOO "ПАРИТЕТ"',
    status: AdminStatus.Active,
    issue: 'Проблема',
    cluster: 'Кластер 1',
  },
  {
    id: 1241,
    contactPerson: {
      fullName: 'Иванов Петр Иванович',
    },
    dealerName: 'OOO "ПАРИТЕТ"',
    status: AdminStatus.Hidden,
    issue: 'Проблема',
    cluster: 'Кластер 1',
  },
  {
    id: 1242,
    contactPerson: {
      fullName: 'Иванов Петр Иванович',
    },
    dealerName: 'OOO "ПАРИТЕТ"',
    status: AdminStatus.Blocked,
    issue: 'Проблема',
    cluster: 'Кластер 1',
  },
  {
    id: 1243,
    contactPerson: {
      fullName: 'Иванов Петр Иванович',
    },
    dealerName: 'OOO "ПАРИТЕТ"',
    status: AdminStatus.Active,
    cluster: 'Кластер 2',
  },
];

export const autoRewardRecepientMock: RewardRecepientDto = {
  id: 1234,
  name: 'Иванов Петр Иванович',
  bank: 'ПАО Сбербанк',
  inn: '525181245',
  bic: '044030790',
  accountNumber: '4080120320394731284509',
};

export const autoOrganizationsMock: AutoDealerOrganizationDto[] = [
  {
    id: 1,
    status: AdminStatus.Active,
    name: "ООО 'Dealer Org. 1'",
    address: 'г. Москва, Новоясеневский пр-кт, д.6 к3, комн 42',
    taxNumber: '525181245',
    requisites: [{ id: 22 }],
    agentsKB: [{ id: 11111 }],
  },
  {
    id: 2,
    status: AdminStatus.Inactive,
    name: "ООО 'Dealer Org. 2'",
    address: 'г. Советск, Новоясеневский пр-кт, д.6 к3, комн 42',
    taxNumber: '485752147',
    requisites: [{ id: 11 }],
  },
  {
    id: 3,
    status: AdminStatus.Blocked,
    name: "ООО 'Dealer Org. 3'",
    address: 'г. Казань, Новоясеневский пр-кт, д.6 к3, комн 42',
    taxNumber: '365874142',
    requisites: [{ id: 33 }],
  },
  {
    id: 4,
    status: AdminStatus.Active,
    name: "ООО 'Dealer Org. 4'",
    address: 'г. Сергиев Посад, ул. Шлякова, д.3 к1, комн 42',
    taxNumber: '147586527',
    requisites: [
      {
        id: 44,
      },
    ],
  },
  {
    id: 5,
    status: AdminStatus.Active,
    name: "ООО 'Dealer Org. 5'",
    address: 'г. Сергиев Посад, ул. Шлякова, д.3 к1, комн 42',
    taxNumber: '147586527',
    requisites: [{ id: 66 }, { id: 77 }],
  },
  {
    id: 6,
    status: AdminStatus.Active,
    name: "ООО 'Dealer Org. 6'",
    address: 'г. Сергиев Посад, ул. Шлякова, д.3 к1, комн 42',
    taxNumber: '147586527',
    requisites: [{ id: 55 }],
  },
];

export const autoDealerRequisitesMock: AutoDealerRequisitesDto[] = [
  {
    id: 11,
    status: AdminStatus.Blocked,
    payeeName: 'Requisites 1',
    accountNumber: '044030790',
    bik: '5080120320394731286509',
    showrooms: [{ id: 333 }],
    organizations: [{ id: 2 }],
  },
  {
    id: 22,
    status: AdminStatus.Inactive,
    payeeName: 'Requisites 2',
    accountNumber: '453778378',
    bik: '6027330320394731286509',
    showrooms: [{ id: 222 }],
    organizations: [{ id: 1 }],
  },
  {
    id: 33,
    status: AdminStatus.Blocked,
    payeeName: 'Requisites 3',
    accountNumber: '273838383',
    bik: '7894560320394731286509',
    showrooms: [{ id: 444 }],
    organizations: [{ id: 3 }],
  },
  {
    id: 44,
    status: AdminStatus.Active,
    issue: 'Надо проверить реквизиты',
    payeeName: 'Requisites 4',
    accountNumber: '045875534',
    bik: '4534520320394731286509',
    showrooms: [{ id: 111 }],
    organizations: [{ id: 4 }],
  },
  {
    id: 55,
    status: AdminStatus.Active,
    issue: 'Надо проверить реквизиты',
    payeeName: 'Requisites 5',
    accountNumber: '045875534',
    bik: '4534520320394731286509',
    showrooms: [{ id: 555 }, { id: 777 }],
    organizations: [{ id: 6 }],
  },
  {
    id: 66,
    status: AdminStatus.Active,
    issue: 'Надо проверить реквизиты',
    payeeName: 'Requisites 6',
    accountNumber: '045875534',
    bik: '4534520320394731286509',
    showrooms: [{ id: 666 }],
    organizations: [{ id: 5 }],
  },
  {
    id: 77,
    status: AdminStatus.Blocked,
    issue: 'Надо проверить реквизиты',
    payeeName: 'Requisites 7',
    accountNumber: '045875534',
    bik: '4534520320394731286509',
    showrooms: [{ id: 888 }],
    organizations: [{ id: 5 }],
  },
];

export const autoShowroomsMock: AutoDealerShowroomDto[] = [
  {
    id: 111,
    status: AdminStatus.Active,
    name: 'Selling Point 1',
    address: 'г. Москва, Новоясеневский пр-кт, д.6 к3, комн 42',
    requisites: [{ id: 44 }],
    agents: [{ id: 5555 }],
  },
  {
    id: 222,
    status: AdminStatus.Inactive,
    name: 'Selling Point 2',
    address: 'г. Москва, Новоясеневский пр-кт, д.6 к3, комн 42',
    requisites: [{ id: 22 }],
    agents: [{ id: 1111 }, { id: 4444 }],
  },
  {
    id: 333,
    status: AdminStatus.Blocked,
    name: 'Selling Point 3',
    address: 'г. Москва, Новоясеневский пр-кт, д.6 к3, комн 42',
    requisites: [{ id: 11 }],
    agents: [{ id: 3333 }],
    agentsKB: [{ id: 33333 }],
  },
  {
    id: 444,
    status: AdminStatus.Active,
    name: 'Selling Point 4',
    address: 'г. Москва, Новоясеневский пр-кт, д.6 к3, комн 42',
    requisites: [{ id: 33 }],
    agents: [{ id: 2222 }],
  },
  {
    id: 555,
    status: AdminStatus.Active,
    name: 'Selling Point 5',
    address: 'г. Москва, Новоясеневский пр-кт, д.6 к3, комн 42',
    requisites: [{ id: 55 }],
    agents: [{ id: 6666 }],
  },
  {
    id: 666,
    status: AdminStatus.Active,
    name: 'Selling Point 6',
    address: 'г. Москва, Новоясеневский пр-кт, д.6 к3, комн 42',
    requisites: [{ id: 66 }],
    agents: [{ id: 7777 }],
  },
  {
    id: 777,
    status: AdminStatus.Active,
    name: 'Selling Point 7',
    address: 'г. Москва, Новоясеневский пр-кт, д.6 к3, комн 42',
    requisites: [{ id: 55 }],
    agents: [{ id: 8888 }],
  },
  {
    id: 888,
    status: AdminStatus.Active,
    name: 'Selling Point 8',
    address: 'г. Москва, Новоясеневский пр-кт, д.6 к3, комн 42',
    requisites: [{ id: 77 }],
    agents: [{ id: 9999 }],
  },
];

export const autoAgentsMock: AutoAgentDto[] = [
  {
    id: 1111,
    dealerId: 1234,
    dealerName: "ООО 'Dealer'",
    curatorId: 1100,
    status: AdminStatus.Blocked,
    role: AutoAgentRole.RewardForCredit,
    fullName: 'Кипелов Валерий Александрович',
    passport: '1111 222222',
    birthday: '1990-01-01',
    placeOfBrith: 'г. Москва',
    registrationAddress: 'г. Москва, Новоясеневский пр-кт, д.6 к3, комн 42',
    phone: '+7 900 500 42 42',
    email: 'agent@email.com',
    workBeginDate: '2010-09-05',
    agreementNumber: '111222333444555666777888999000',
    agreementDate: '2004-04-10',
    updated: new Date(2005, 2, 15).toISOString(),
    issue: 'Проверить данного агента',
    showrooms: [
      {
        id: 222,
        status: AdminStatus.Active,
        name: 'Selling Point 1',
        address: 'г. Москва, Новоясеневский пр-кт, д.6 к3, комн 42',
      },
    ],
  },
  {
    id: 2222,
    status: AdminStatus.Active,
    fullName: 'Бутусов Вячеслав Геннадьевич',
    dealerId: 1234,
    dealerName: "ООО 'Dealer'",
    role: AutoAgentRole.RewardForCredit,
    passport: '1111 222222',
    birthday: '1990-01-01',
    placeOfBrith: 'г. Москва',
    registrationAddress: 'г. Москва, Новоясеневский пр-кт, д.6 к3, комн 42',
    phone: '+7 900 500 42 42',
    email: 'agent@email.com',
    workBeginDate: '2010-09-05',
    agreementNumber: '111222333444555666777888999000',
    agreementDate: '2004-04-10',
    updated: new Date(2005, 2, 15).toISOString(),
    showrooms: [
      {
        id: 444,
        status: AdminStatus.Active,
        name: 'Selling Point 2',
        address: 'г. Москва, Новоясеневский пр-кт, д.6 к3, комн 42',
      },
    ],
  },
  {
    id: 3333,
    status: AdminStatus.Inactive,
    fullName: 'Кинчев Константин Евгеньевич',
    dealerId: 1234,
    dealerName: "ООО 'Dealer'",
    curatorId: 1122,
    curatorName: 'Пугачёва Алла Борисовна',
    role: AutoAgentRole.RewardForCredit,
    passport: '1111 222222',
    birthday: '1990-01-01',
    placeOfBrith: 'г. Москва',
    registrationAddress: 'г. Москва, Новоясеневский пр-кт, д.6 к3, комн 42',
    phone: '+7 900 500 42 42',
    email: 'agent@email.com',
    workBeginDate: '2010-09-05',
    agreementNumber: '111222333444555666777888999000',
    agreementDate: '2004-04-10',
    updated: new Date(2005, 2, 15).toISOString(),
    showrooms: [
      {
        id: 333,
        status: AdminStatus.Active,
        name: 'Selling Point 1',
        address: 'г. Москва, Новоясеневский пр-кт, д.6 к3, комн 42',
      },
    ],
  },
  {
    id: 4444,
    status: AdminStatus.Active,
    fullName: 'Цой Виктор Робертович',
    dealerId: 1234,
    dealerName: "ООО 'Dealer'",
    role: AutoAgentRole.RewardForCredit,
    passport: '1111 222222',
    birthday: '1990-01-01',
    placeOfBrith: 'г. Москва',
    registrationAddress: 'г. Москва, Новоясеневский пр-кт, д.6 к3, комн 42',
    phone: '+7 900 500 42 42',
    email: 'agent@email.com',
    workBeginDate: '2010-09-05',
    agreementNumber: '111222333444555666777888999000',
    agreementDate: '2004-04-10',
    updated: new Date(2005, 2, 15).toISOString(),
    showrooms: [
      {
        id: 222,
        status: AdminStatus.Inactive,
        name: 'Selling Point 2',
        address: 'г. Москва, Новоясеневский пр-кт, д.6 к3, комн 42',
      },
    ],
  },
  {
    id: 5555,
    status: AdminStatus.Active,
    fullName: 'Шевчук Юрий Юлианович',
    dealerId: 1234,
    dealerName: "ООО 'Dealer'",
    role: AutoAgentRole.RewardForCredit,
    passport: '1111 222222',
    birthday: '1990-01-01',
    placeOfBrith: 'г. Москва',
    registrationAddress: 'г. Москва, Новоясеневский пр-кт, д.6 к3, комн 42',
    phone: '+7 900 500 42 42',
    email: 'agent@email.com',
    workBeginDate: '2010-09-05',
    agreementNumber: '111222333444555666777888999000',
    agreementDate: '2004-04-10',
    updated: new Date(2005, 2, 15).toISOString(),
    showrooms: [
      {
        id: 111,
        status: AdminStatus.Inactive,
        name: 'Selling Point 2',
        address: 'г. Москва, Новоясеневский пр-кт, д.6 к3, комн 42',
      },
    ],
  },
  {
    id: 6666,
    status: AdminStatus.Active,
    fullName: 'Бортник Егор Михайлович',
    dealerId: 1234,
    dealerName: "ООО 'Dealer'",
    role: AutoAgentRole.RewardForCredit,
    passport: '1111 222222',
    birthday: '1990-01-01',
    placeOfBrith: 'г. Москва',
    registrationAddress: 'г. Москва, Новоясеневский пр-кт, д.6 к3, комн 42',
    phone: '+7 900 500 42 42',
    email: 'agent@email.com',
    workBeginDate: '2010-09-05',
    agreementNumber: '111222333444555666777888999000',
    agreementDate: '2004-04-10',
    updated: new Date(2005, 2, 15).toISOString(),
    showrooms: [
      {
        id: 555,
        status: AdminStatus.Inactive,
        name: 'Selling Point 2',
        address: 'г. Москва, Новоясеневский пр-кт, д.6 к3, комн 42',
      },
    ],
  },
  {
    id: 7777,
    status: AdminStatus.Active,
    fullName: 'Котляров Владимир Андреевич',
    dealerId: 1234,
    dealerName: "ООО 'Dealer'",
    role: AutoAgentRole.RewardForCredit,
    passport: '1111 222222',
    birthday: '1990-01-01',
    placeOfBrith: 'г. Москва',
    registrationAddress: 'г. Москва, Новоясеневский пр-кт, д.6 к3, комн 42',
    phone: '+7 900 500 42 42',
    email: 'agent@email.com',
    workBeginDate: '2010-09-05',
    agreementNumber: '111222333444555666777888999000',
    agreementDate: '2004-04-10',
    updated: new Date(2005, 2, 15).toISOString(),
    showrooms: [
      {
        id: 666,
        status: AdminStatus.Inactive,
        name: 'Selling Point 2',
        address: 'г. Москва, Новоясеневский пр-кт, д.6 к3, комн 42',
      },
    ],
  },
  {
    id: 8888,
    status: AdminStatus.Active,
    fullName: 'Летов Игорь Фёдорович',
    dealerId: 1234,
    dealerName: "ООО 'Dealer'",
    role: AutoAgentRole.RewardForCredit,
    passport: '1111 222222',
    birthday: '1990-01-01',
    placeOfBrith: 'г. Москва',
    registrationAddress: 'г. Москва, Новоясеневский пр-кт, д.6 к3, комн 42',
    phone: '+7 900 500 42 42',
    email: 'agent@email.com',
    workBeginDate: '2010-09-05',
    agreementNumber: '111222333444555666777888999000',
    agreementDate: '2004-04-10',
    updated: new Date(2005, 2, 15).toISOString(),
    showrooms: [
      {
        id: 777,
        status: AdminStatus.Inactive,
        name: 'Selling Point 2',
        address: 'г. Москва, Новоясеневский пр-кт, д.6 к3, комн 42',
      },
    ],
  },
  {
    id: 9999,
    status: AdminStatus.Active,
    fullName: 'Васильев Александр Георгиевич',
    dealerId: 1234,
    dealerName: "ООО 'Dealer'",
    role: AutoAgentRole.RewardForCredit,
    passport: '1111 222222',
    birthday: '1990-01-01',
    placeOfBrith: 'г. Москва',
    registrationAddress: 'г. Москва, Новоясеневский пр-кт, д.6 к3, комн 42',
    phone: '+7 900 500 42 42',
    email: 'agent@email.com',
    workBeginDate: '2010-09-05',
    agreementNumber: '111222333444555666777888999000',
    agreementDate: '2004-04-10',
    updated: new Date(2005, 2, 15).toISOString(),
    showrooms: [
      {
        id: 888,
        status: AdminStatus.Inactive,
        name: 'Selling Point 2',
        address: 'г. Москва, Новоясеневский пр-кт, д.6 к3, комн 42',
      },
    ],
  },
];

export const autoAgentsKbMock: AutoAgentDto[] = [
  {
    id: 11111,
    dealerId: 1234,
    dealerName: "ООО 'Dealer'",
    curatorId: 1100,
    curatorName: 'Пугачёва Алла Борисовна',
    status: AdminStatus.Blocked,
    role: AutoAgentRole.RewardForCredit,
    fullName: 'Шатунов Юрий Васильевич',
    passport: '1111 222222',
    birthday: '1990-01-01',
    placeOfBrith: 'г. Москва',
    registrationAddress: 'г. Москва, Новоясеневский пр-кт, д.6 к3, комн 42',
    phone: '+7 900 500 42 42',
    email: 'agent@email.com',
    workBeginDate: '2010-09-05',
    agreementNumber: '111222333444555666777888999000',
    agreementDate: '2004-04-10',
    updated: new Date(2005, 2, 15).toISOString(),
    issue: 'Проверить данного агента',
    showrooms: [
      {
        id: 222,
        status: AdminStatus.Active,
        name: 'Selling Point 1',
        address: 'г. Москва, Новоясеневский пр-кт, д.6 к3, комн 42',
      },
    ],
  },
  {
    id: 22222,
    dealerId: 1234,
    dealerName: "ООО 'Dealer'",
    curatorId: 1100,
    curatorName: 'Иванов Иван Иванович',
    status: AdminStatus.Active,
    role: AutoAgentRole.RewardForCredit,
    fullName: 'Суханкина Маргарита Анатольевна',
    passport: '1111 222222',
    birthday: '1990-01-01',
    placeOfBrith: 'г. Москва',
    registrationAddress: 'г. Москва, Новоясеневский пр-кт, д.6 к3, комн 42',
    phone: '+7 900 500 42 42',
    email: 'agent@email.com',
    workBeginDate: '2010-09-05',
    agreementNumber: '111222333444555666777888999000',
    agreementDate: '2004-04-10',
    updated: new Date(2005, 2, 15).toISOString(),
    issue: 'Проверить данного агента',
    showrooms: [
      {
        id: 222,
        status: AdminStatus.Active,
        name: 'Selling Point 1',
        address: 'г. Москва, Новоясеневский пр-кт, д.6 к3, комн 42',
      },
    ],
  },
  {
    id: 33333,
    dealerId: 1234,
    dealerName: "ООО 'Dealer'",
    curatorId: 1100,
    curatorName: 'Пугачёва Алла Борисовна',
    status: AdminStatus.Inactive,
    role: AutoAgentRole.RewardForCredit,
    fullName: 'Ежова Ирина Александровна',
    passport: '1111 222222',
    birthday: '1990-01-01',
    placeOfBrith: 'г. Москва',
    registrationAddress: 'г. Москва, Новоясеневский пр-кт, д.6 к3, комн 42',
    phone: '+7 900 500 42 42',
    email: 'agent@email.com',
    workBeginDate: '2010-09-05',
    agreementNumber: '111222333444555666777888999000',
    agreementDate: '2004-04-10',
    updated: new Date(2005, 2, 15).toISOString(),
    issue: 'Проверить данного агента',
    showrooms: [
      {
        id: 222,
        status: AdminStatus.Active,
        name: 'Selling Point 1',
        address: 'г. Москва, Новоясеневский пр-кт, д.6 к3, комн 42',
      },
    ],
  },
  {
    id: 44444,
    dealerId: 1234,
    dealerName: "ООО 'Dealer'",
    curatorId: 1100,
    curatorName: 'Пельш Валдис Эйженович',
    status: AdminStatus.Active,
    role: AutoAgentRole.RewardForCredit,
    fullName: 'Буланова Татьяна Ивановна',
    passport: '1111 222222',
    birthday: '1990-01-01',
    placeOfBrith: 'г. Москва',
    registrationAddress: 'г. Москва, Новоясеневский пр-кт, д.6 к3, комн 42',
    phone: '+7 900 500 42 42',
    email: 'agent@email.com',
    workBeginDate: '2010-09-05',
    agreementNumber: '111222333444555666777888999000',
    agreementDate: '2004-04-10',
    updated: new Date(2005, 2, 15).toISOString(),
    issue: 'Проверить данного агента',
    showrooms: [
      {
        id: 222,
        status: AdminStatus.Active,
        name: 'Selling Point 1',
        address: 'г. Москва, Новоясеневский пр-кт, д.6 к3, комн 42',
      },
    ],
  },
];

export const autoDealerMock: AutoDealerDto = {
  id: 1234,
  dealerName: "ООО 'Dealer'",
  status: AdminStatus.Active,
  creditProductTypes: [CreditProductType.NewVehicle, CreditProductType.UsedVehicle],
  makes: [
    { id: 1, name: 'Audi' },
    { id: 2, name: 'BMW' },
  ],
  organizations: autoOrganizationsMock,
  requisites: autoDealerRequisitesMock,
  showrooms: autoShowroomsMock,
  agents: autoAgentsMock,
  agentsKB: autoAgentsKbMock,
};
