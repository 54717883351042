import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { transformNullableFields } from './nullable-fields.utils';

@Injectable()
export class NullableFieldsInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // FNAUTO-399: Используем только для исходящих запросов апи Авто
    if (request.body && ['POST', 'PUT', 'PATCH'].includes(request.method.toUpperCase())) {
      const data = transformNullableFields(request.body);
      return next.handle(request.clone({ body: data }));
    }
    return next.handle(request);
  }
}
