/**
 * Fx.Spr
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { VehicleType } from './vehicleType';
import { DateOnly } from '@lib-utils';
import { DateTime } from '@lib-utils';


export interface CheckVehicleRegistrationHistoryOutput { 
    wasPreviouslyRegistered?: boolean;
    vehicleType?: VehicleType;
    lastPersonType?: string | null;
    lastRegistrationDate?: DateTime | null;
    lastDeregistrationDate?: DateTime | null;
    countMonthesAfterDeregistrationFromNow?: number;
    previousOperation?: string | null;
    previousOperationId?: string | null;
    cacheId?: number;
    date?: DateOnly;
}

