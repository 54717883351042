import { Injectable } from '@angular/core';
import { ErrorMapperAbstract, TypedHttpErrorResponse } from '@lib-error-interceptor';
import { BeApiEnum, FeHttpError, FeHttpErrorMessage } from '@lib-utils';

export interface AutoErrorResponse {
  title: string;
  status: number;
  detail: string;
  errors?:
    | {
        errorCode: string;
        severity: string;
        message: string;
        numericCode: number;
        propertyName?: string;
        propertyValue?: string;
      }[]
    | Record<string, string[]>;
  error?: {
    message?: string;
    code?: string;
  };
}

@Injectable()
export class AutoErrorMapper extends ErrorMapperAbstract<AutoErrorResponse, AutoErrorResponse> {
  readonly forApiModules: BeApiEnum[] = [BeApiEnum.Auto, BeApiEnum.AutoLookup];

  mapError(
    beApi: BeApiEnum | null,
    errorResp: TypedHttpErrorResponse<AutoErrorResponse>,
  ): FeHttpError<AutoErrorResponse> {
    const messages: FeHttpErrorMessage[] = Array.isArray(errorResp.error?.errors)
      ? errorResp.error?.errors.map((error) => ({
          code: error.errorCode,
          message: error.message,
        }))
      : Object.entries(errorResp.error?.errors ?? {})
          .map(([key, messages]) => messages.map((message) => ({ code: key, message })))
          .flat();

    return new FeHttpError<AutoErrorResponse>({
      beApi,
      url: errorResp.url,
      headers: errorResp.headers,
      originalError: errorResp.error,
      status: errorResp.status,
      exceptionCode: messages[0]?.code ?? FeHttpError.DEFAULT_ERROR_CODE,
      messages: messages.length
        ? messages
        : [
            {
              message: errorResp.error?.error?.message ?? FeHttpError.DEFAULT_ERROR_MESSAGE,
              code: errorResp.error?.error?.code ?? FeHttpError.DEFAULT_ERROR_CODE,
            },
          ],
      technicalMessages: [],
    });
  }
}
