import { AdminStatus, AutoAgentRole, AutoDealerOrganizationDto, AutoDealerShowroomDto } from '../models';

export interface AutoAgentDto {
  id?: number;
  dealerId?: number;
  dealerName?: string;
  curatorId?: number;
  curatorName?: string;
  status?: AdminStatus;
  role?: AutoAgentRole;
  fullName?: string;
  passport?: string;
  birthday?: string;
  placeOfBrith?: string;
  registrationAddress?: string;
  phone?: string;
  email?: string;
  workBeginDate?: string;
  agreementNumber?: string;
  agreementDate?: string;
  updated?: string;
  showrooms?: AutoDealerShowroomDto[];
  organizations?: AutoDealerOrganizationDto[];
  agentsKB?: AutoAgentDto[];
  issue?: string;
}

export const autoAgentKeyMap: Partial<{ [K in keyof AutoAgentDto]: string }> = {
  fullName: 'ФИО',
  status: 'Статус',
  role: 'Роль',
};
