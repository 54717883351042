import {
  AutoDocumentInspectionDocumentsToCheckDto,
  AutoDocumentInspectionDocumentType,
  AutoDocumentInspectionErrorDto,
} from '../models';
import { AutoDocumentInspectionInfoDto } from '../models/auto-document-inspection-info-dto';

export const autoDocumentInspectionMock: AutoDocumentInspectionInfoDto = {
  borrowerInfo: {
    fullName: 'Чигин Олег Анатольевич ',
    birthdayDate: '2000-12-27T07:20:26.637855Z',
    birthplace: 'г. Москва (Центральный федеральный округ)',
    phoneNumber: '+7 (967) 711-62-05',
    email: 'chigin_oleg@gmail.com',
    maritalStatus: 'NotMarried',

    passport: {
      expirationMonth: 365,
      isReplacedByAge: false,
      issueDate: '2007-12-27T07:20:26.637855Z',
      issueDepartmentCode: '56-763',
      issueDepartmentName: 'ТП №45 УФМС РФ по г. Москве',
      number: '4016 543 215',
    },

    driverLicense: {
      issueDate: '2010-12-27T07:20:26.637855Z',
      number: '4016 543 215',
    },
    factualAddress: 'г. Иваново, ул. Правды, д. 45, кв. 16',
    juristicAddress: 'г. Москва, ул. Покровка, д. 18, кв. 101',
    isTownApartmentRegistration: true,
    signsOfFalsification: [], // not found
    checks: [
      { name: 'Проверка паспорта', comment: 'В списке недействительных не значится', isPassed: true },
      { name: 'Проверка ВУ', comment: 'Истек срок действия', isPassed: false },
      { name: 'Проверка банкротства', comment: 'Не значится', isPassed: true },
      { name: 'Проверка по списку террористов', comment: 'Не значится', isPassed: true },
      { name: 'Установленнный ИНН', comment: 'Без нарушений', isPassed: true },
      { name: 'Проверка ФНС', comment: 'Выявлены ограничения', isPassed: false },
    ],
    hasActiveEGRIP: false,
  },
  employment: {
    type: 'Employee',
    companyName: 'ИП Чигин Олег Анатольевич',
    companyInn: '324587263450',
    companyPhone: '+ 7 (123) 123-45-67',
    companyAddress: '141052, Московская область, г. Мытищи, деревня Крюково',
  },
  lendingTerms: {
    period: 84,
    rate: 21.6,
    initialPayment: undefined,
    monthlyPayment: 19_772,
    additionalProductsSum: 5_000,
    loanTotal: 853_000,
    vehicleCreditSum: 848_000,
  },
  vehicleInfo: {
    vin: '314957629034576ABCDEF',
    brandName: 'FIAT',
    model: 'Ducato',
    year: '2010',
  },
};

export const autoDocumentInspectionDocumentErrors: AutoDocumentInspectionErrorDto[] = [
  {
    documentType: AutoDocumentInspectionDocumentType.PersonalDocuments,
    documents: [
      {
        documentName: 'Паспорт',
        errors: [
          {
            message: '№ документа указан неверно',
            authorFullName: 'Петрова Анна Ивановна',
            date: '2010-12-27T07:20:26.637855Z',
          },
          {
            message: 'Сумма указана неверно',
            authorFullName: 'Петрова Анна Ивановна',
            date: '2010-12-27T07:20:26.637855Z',
          },
        ],
      },
      {
        documentName: 'Скан ПТС',
        errors: [
          {
            message: 'Не указан VIN-номер',
            authorFullName: 'Петрова Анна Ивановна',
            date: '2010-12-27T07:20:26.637855Z',
          },
          {
            message: 'Нет информации о представителе',
            authorFullName: 'Петрова Анна Ивановна',
            date: '2010-12-27T07:20:26.637855Z',
          },
        ],
      },
    ],
  },
  {
    documentType: AutoDocumentInspectionDocumentType.DealDocuments,
    documents: [
      {
        documentName: 'График платежей',
        errors: [
          {
            message: 'Не указана дата документа',
            authorFullName: 'Петрова Анна Ивановна',
            date: '2010-12-27T07:20:26.637855Z',
          },
        ],
      },
    ],
  },
];

export const autoDocumentInspectionDocumentsToCheck: AutoDocumentInspectionDocumentsToCheckDto[] = [
  {
    documentType: AutoDocumentInspectionDocumentType.PersonalDocuments,
    documents: [
      {
        name: 'Фотография заемщика',
        pages: 1,
        isChecked: true,
        errors: [],
      },
      {
        name: 'Паспорт',
        pages: 9,
        isChecked: true,
        errors: [
          {
            message: '№ документа указан неверно',
            authorFullName: 'Петрова Анна Ивановна',
            date: '2010-12-27T07:20:26.637855Z',
          },
        ],
      },
      {
        name: 'Согласие на проверку перс. данных',
        pages: 2,
        isChecked: true,
        errors: [
          {
            message: 'Не указана дата документа',
            authorFullName: 'Петрова Анна Ивановна',
            date: '2010-12-27T07:20:26.637855Z',
          },
        ],
      },
      {
        name: 'Скан ПТС',
        pages: 2,
        isChecked: false,
        errors: [],
      },
      {
        name: 'Скан водительского удостоверения',
        pages: 2,
        isChecked: false,
        errors: [],
      },
    ],
  },
  {
    documentType: AutoDocumentInspectionDocumentType.DealDocuments,
    documents: [
      {
        name: 'График платежей',
        pages: 7,
        isChecked: false,
        errors: [],
      },
      {
        name: 'Индивидуальные условия',
        pages: 2,
        isChecked: false,
        errors: [],
      },
      {
        name: 'Заявление на присоединение к ДКО',
        pages: 1,
        isChecked: false,
        errors: [],
      },
      {
        name: 'Анкета заявления и согласия',
        pages: 8,
        isChecked: false,
        errors: [],
      },
      {
        name: 'Заявление на перевод ДС за ТС',
        pages: 9,
        isChecked: false,
        errors: [],
      },
      {
        name: 'Заявление на перевод ДС за доп. продукт',
        pages: 2,
        isChecked: false,
        errors: [],
      },
      {
        name: 'ДКП ТС',
        pages: 10,
        isChecked: false,
        errors: [],
      },
      {
        name: 'Договор комиссии',
        pages: 5,
        isChecked: false,
        errors: [],
      },
      {
        name: 'Квитанция об оплате ПВ',
        pages: 5,
        isChecked: false,
        errors: [],
      },
    ],
  },
  {
    documentType: AutoDocumentInspectionDocumentType.Policies,
    documents: [
      {
        name: 'Первоначальный взнос',
        pages: 1,
        isChecked: false,
        errors: [],
      },
      {
        name: 'Полис ДТП со вторым участником',
        pages: 2,
        isChecked: false,
        errors: [],
      },
    ],
  },
  {
    documentType: AutoDocumentInspectionDocumentType.Invoices,
    documents: [
      {
        name: 'Счет на оплату ТС',
        pages: 1,
        isChecked: false,
        errors: [],
      },
      {
        name: 'Счет на оплату ДТП со вторым участником',
        pages: 2,
        isChecked: false,
        errors: [],
      },
    ],
  },
];
