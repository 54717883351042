/**
 * FxAuto.BFF.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AdditionalProductKeyDto } from './additionalProductKeyDto';
import { DocumentType } from './documentType';
import { DocumentFileType } from './documentFileType';


export interface IDocumentFile { 
    readonly id?: number;
    readonly fileId?: string;
    readonly fileName?: string | null;
    readonly contentType?: string | null;
    fileType?: DocumentFileType;
    documentType?: DocumentType;
    additionalProductKey?: AdditionalProductKeyDto;
}
export namespace IDocumentFile {
}


