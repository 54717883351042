/**
 * Fx.Spr
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Информация о источнике.
 */
export interface ConnectorDataDetail { 
    /**
     * Идентификатор запроса к БД.
     */
    connectorDataId?: number;
    /**
     * Источник.
     */
    source?: string | null;
    /**
     * Код статуса выполнения запроса.
     */
    resultCode?: string | null;
    /**
     * Код коннектора, который фигурировал в запросе к БКИ.
     */
    connectorCode?: string | null;
    /**
     * Дополнительное описание запроса в БКИ.
     */
    subRequestCode?: number | null;
    /**
     * Код ошибки возникшей при запросе.
     */
    error?: string | null;
    /**
     * Расшифровка ошибки.
     */
    errorText?: string | null;
    /**
     * Метка даты/времени непосредственно перед отправкой запроса.
     */
    createdDate?: string | null;
    /**
     * Метка даты/времени сразу после получения ответа.
     */
    completeDate?: string | null;
    /**
     * Статус бюро:  0 - Есть данные;  1 - Нет данных;  2 - Ошибка;  3 - Только запросы;  4 - Есть кредитная история.
     */
    bureauStatus?: number;
}

