/**
 * FxAuto.BFF.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ClientDto } from './clientDto';
import { DealerShowroomDto } from './dealerShowroomDto';


export interface GeneralInfoDto { 
    uid?: string;
    client?: ClientDto;
    showroom?: DealerShowroomDto;
    showrooms?: Array<DealerShowroomDto> | null;
    isReadOnly?: boolean;
}

