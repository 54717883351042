/**
 * FxAuto.BFF.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CalculationResultDto } from './calculationResultDto';
import { DecisionResultDto } from './decisionResultDto';


export interface DecisionResultResponseDto { 
    result?: DecisionResultDto;
    calculationResult?: CalculationResultDto;
    loanAmount?: number | null;
    vehicleDescription?: string | null;
    calculationResults?: Array<CalculationResultDto> | null;
    isReadOnly?: boolean;
}

