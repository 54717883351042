export const RateType = {
  Percent: 'Percent',
  Amount: 'Amount',
} as const;

export type RateType = (typeof RateType)[keyof typeof RateType];

export const RateTypeMap: Record<RateType, string> = {
  [RateType.Amount]: 'руб.',
  [RateType.Percent]: '%',
};
