/**
 * FxAuto.BFF.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ApplicationStatus = 'Draft' | 'DecisionAwaiting' | 'RevisionRequired' | 'VehicleRevision' | 'Approved' | 'ApprovedLoanAndVehicle' | 'ApprovedLoanDeclinedVehicle' | 'DealNegotiation' | 'DealNegotiationProcessing' | 'DealNegotiationRevisionRequired' | 'Deal' | 'DealProcessing' | 'DealRevisionRequired' | 'LoanIssued' | 'LoanIssuedRevisionRequired' | 'LoanPaid' | 'Outdated' | 'Declined';

export const ApplicationStatus = {
    Draft: 'Draft' as ApplicationStatus,
    DecisionAwaiting: 'DecisionAwaiting' as ApplicationStatus,
    RevisionRequired: 'RevisionRequired' as ApplicationStatus,
    VehicleRevision: 'VehicleRevision' as ApplicationStatus,
    Approved: 'Approved' as ApplicationStatus,
    ApprovedLoanAndVehicle: 'ApprovedLoanAndVehicle' as ApplicationStatus,
    ApprovedLoanDeclinedVehicle: 'ApprovedLoanDeclinedVehicle' as ApplicationStatus,
    DealNegotiation: 'DealNegotiation' as ApplicationStatus,
    DealNegotiationProcessing: 'DealNegotiationProcessing' as ApplicationStatus,
    DealNegotiationRevisionRequired: 'DealNegotiationRevisionRequired' as ApplicationStatus,
    Deal: 'Deal' as ApplicationStatus,
    DealProcessing: 'DealProcessing' as ApplicationStatus,
    DealRevisionRequired: 'DealRevisionRequired' as ApplicationStatus,
    LoanIssued: 'LoanIssued' as ApplicationStatus,
    LoanIssuedRevisionRequired: 'LoanIssuedRevisionRequired' as ApplicationStatus,
    LoanPaid: 'LoanPaid' as ApplicationStatus,
    Outdated: 'Outdated' as ApplicationStatus,
    Declined: 'Declined' as ApplicationStatus
};

