/**
 * FxAuto.BFF.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type EngineType = 'Petrol' | 'Diesel' | 'Hybrid' | 'Electric' | 'Gas';

export const EngineType = {
    Petrol: 'Petrol' as EngineType,
    Diesel: 'Diesel' as EngineType,
    Hybrid: 'Hybrid' as EngineType,
    Electric: 'Electric' as EngineType,
    Gas: 'Gas' as EngineType
};

