import { withCabinetTitle } from '@lib-config';
import { AUTO_OPEN_ISSUE_URL, AUTO_VIDEO_INSTRUCTION_URL, ExtendedRoute, RouteBreadcrumbData } from '@lib-utils';
import { NoPageComponent } from '@lib-widgets/core';
import { ApplicationModules, ApplicationModulesMetaMap } from '@lib-auto/utils';
import {
  authorizationGuardFn,
  getAuthorizationRoutes,
  permissionGuardFn,
  RoutePermissionData,
} from '@lib-mortgage/features/authorization';
import { DefaultComponent, DefaultPageData } from '@lib-mortgage/widgets/default';
import { environment } from '../environments/environment';

export const appRoutes: ExtendedRoute<RouteBreadcrumbData & RoutePermissionData & DefaultPageData>[] = [
  {
    path: '',
    title: 'Автокредитование',
    canActivate: [authorizationGuardFn],
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: DefaultComponent,
        data: {
          moduleMeta: ApplicationModulesMetaMap,
          openIssueUrl: AUTO_OPEN_ISSUE_URL,
          videoInstructionUrl: AUTO_VIDEO_INSTRUCTION_URL,
          hasTranslation: false,
        },
      },
      {
        path: ApplicationModulesMetaMap[ApplicationModules.Applications].link,
        canActivate: [authorizationGuardFn, permissionGuardFn],
        canActivateChild: [authorizationGuardFn],
        loadChildren: () => import('./views/applications/').then((m) => m.ApplicationsModule),
        data: {
          ...withCabinetTitle('Кабинет заявок'),
          requiredPermission: ApplicationModulesMetaMap[ApplicationModules.Applications].permissionOperation,
        },
      },
      {
        title: 'Кабинет администратора',
        path: ApplicationModulesMetaMap[ApplicationModules.Admin].link,
        canActivate: [authorizationGuardFn, permissionGuardFn],
        canActivateChild: [authorizationGuardFn],
        loadChildren: () => import('./views/admin/').then((m) => m.AutoAdminModule),
        data: {
          ...withCabinetTitle('Кабинет администратора'),
          requiredPermission: ApplicationModulesMetaMap[ApplicationModules.Admin].permissionOperation,
        },
      },
      {
        title: 'Кабинет инспектора',
        path: ApplicationModulesMetaMap[ApplicationModules.Inspector].link,
        canActivate: [authorizationGuardFn, permissionGuardFn],
        canActivateChild: [authorizationGuardFn],
        loadChildren: () => import('./views/inspector/').then((m) => m.AutoInspectorModule),
        data: {
          ...withCabinetTitle('Кабинет инспектора'),
          requiredPermission: ApplicationModulesMetaMap[ApplicationModules.Inspector].permissionOperation,
        },
      },
      {
        path: ApplicationModulesMetaMap[ApplicationModules.Risks].link,
        canActivate: [authorizationGuardFn, permissionGuardFn],
        canActivateChild: [authorizationGuardFn],
        loadChildren: () => import('./views/risks/').then((m) => m.AutoRisksModule),
        data: {
          requiredPermission: ApplicationModulesMetaMap[ApplicationModules.Risks].permissionOperation,
        },
      },
    ],
  },
  {
    path: 'auth',
    children: getAuthorizationRoutes(environment.frontVersion, 'Авто'),
  },
  {
    path: 'unsupported-browser',
    title: 'Ваш браузер не поддерживается',
    loadComponent: () => import('@lib-widgets/unsupported-browser').then((m) => m.UnsupportedBrowserComponent),
  },
  {
    path: '**',
    title: 'Страница не найдена',
    component: NoPageComponent,
  },
];
