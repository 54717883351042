/**
 * Fx.Spr
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Модель элемента в скоринговом отчете
 */
export interface ScoringReportItem { 
    /**
     * Источник данных
     */
    readonly source?: string | null;
    /**
     * Идентификатор примененной скоринговой модели
     */
    readonly cardId?: string | null;
    /**
     * Скоринговый балл
     */
    readonly value?: number | null;
}

