export const ConditionType = {
  Volume: 'Volume',
  CreditSum: 'CreditSum',
} as const;

export type ConditionType = (typeof ConditionType)[keyof typeof ConditionType];

export const ConditionTypeMap: Record<ConditionType, string> = {
  [ConditionType.CreditSum]: 'Сумма кредита',
  [ConditionType.Volume]: 'Объем',
};

export const ConditionTypeSourceMap: Record<ConditionType, string> = {
  [ConditionType.CreditSum]: 'От суммы кредита',
  [ConditionType.Volume]: 'От объема',
};
