export const MonthsMap = new Map<number, string>([
  [1, 'Январь'],
  [2, 'Февраль'],
  [3, 'Март'],
  [4, 'Апрель'],
  [5, 'Май'],
  [6, 'Июнь'],
  [7, 'Июль'],
  [8, 'Август'],
  [9, 'Сентябрь'],
  [10, 'Октябрь'],
  [11, 'Ноябрь'],
  [12, 'Декабрь'],
]);

export const ExportsNextMonths: Record<number, string> = {
  1: 'Февраля',
  2: 'Марта',
  3: 'Апреля',
  4: 'Мая',
  5: 'Июня',
  6: 'Июля',
  7: 'Августа',
  8: 'Сентября',
  9: 'Октября',
  10: 'Ноября',
  11: 'Декабря',
  12: 'Янаваря',
};
