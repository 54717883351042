/**
 * Fx.Spr
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Скоринговый отчет.
 */
export interface Score { 
    /**
     * Идентификатор примененной скоринговой модели.
     */
    cardId?: string | null;
    /**
     * Описание ошибки.
     */
    error?: string | null;
    /**
     * Скоринговый балл.
     */
    scoreValue?: number | null;
    /**
     * Код ошибки.
     */
    errorCode?: string | null;
}

