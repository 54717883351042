/**
 * FxAuto.BFF.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type EducationType = 'AcademicDegree' | 'Higher' | 'MultipleHigher' | 'IncompleteHigher' | 'Secondary' | 'Vocational' | 'AboveSecondary' | 'RussianMBA' | 'ForeignMBA' | 'Other';

export const EducationType = {
    AcademicDegree: 'AcademicDegree' as EducationType,
    Higher: 'Higher' as EducationType,
    MultipleHigher: 'MultipleHigher' as EducationType,
    IncompleteHigher: 'IncompleteHigher' as EducationType,
    Secondary: 'Secondary' as EducationType,
    Vocational: 'Vocational' as EducationType,
    AboveSecondary: 'AboveSecondary' as EducationType,
    RussianMba: 'RussianMBA' as EducationType,
    ForeignMba: 'ForeignMBA' as EducationType,
    Other: 'Other' as EducationType
};

