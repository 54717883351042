/**
 * FxAuto.BFF.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ApplicationListItemDto } from './applicationListItemDto';


export interface ApplicationListItemDtoPaginatedListWithStats { 
    pageIndex?: number;
    pageSize?: number;
    readonly totalPages?: number;
    totalItems?: number;
    items?: Array<ApplicationListItemDto> | null;
    statusCounts?: { [key: string]: number; } | null;
}

