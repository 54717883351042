import { Nullable } from '@lib-utils';
import { ApplicationListItemDto, ApplicationListStatusType } from '@lib-auto/api';
import { AutoDecisionState } from './auto-decision-state';

export interface AutoRiskGeneralDto extends ApplicationListItemDto {
  rejectionCode?: string;
  carDealerName: Nullable<string>;
  showroomInn: Nullable<string>;
  showroomAddress: Nullable<string>;

  creditProgram: Nullable<string>;
  creditNumber: Nullable<string>;
  creditIssueDate: Nullable<string>;

  decisionDate: Nullable<string>;
  decisionState: Nullable<ApplicationListStatusType>;
  decisionStateName: Nullable<string>;
  decisionHistory: {
    date: Nullable<string>;
    state: Nullable<AutoDecisionState>;
  }[];
}
