import { Injectable } from '@angular/core';
import uniq from 'lodash-es/uniq';
import { BehaviorSubject, Observable } from 'rxjs';
import { Nullable } from '@lib-utils';
import {
  ApplicationOptionsDto,
  ApplicationReadinessDto,
  ApplicationStageType,
  SharedApplicationDataIssueDto,
} from '@lib-auto/api';
import { ApplicationStageStepUrl, DataIssueTypeStepMap, FeApplicationOptionsDto } from '../models';

@Injectable({ providedIn: 'root' })
export class AutoStateService {
  pendingSteps: ApplicationStageType[] = [];
  updateOptionsInGuard = true;

  private _applicationOptions$ = new BehaviorSubject<Nullable<FeApplicationOptionsDto>>(null);

  applicationOptions$: Observable<Nullable<FeApplicationOptionsDto>> = this._applicationOptions$.asObservable();

  get applicationOptions() {
    return this._applicationOptions$.value;
  }

  updateReadinessInfo(readiness: ApplicationReadinessDto) {
    this.pendingSteps = this.mapReadinessToSteps(readiness);
  }

  updateDataIssuesInfo(dataIssues: SharedApplicationDataIssueDto[]) {
    this.pendingSteps = this.mapDataIssuesToSteps(dataIssues);
  }

  clearReadinessInfo() {
    this.pendingSteps = [];
  }

  getPendingStepPath() {
    return this.pendingSteps.length ? ApplicationStageStepUrl[this.pendingSteps[0]] : null;
  }

  setApplicationOptions(id: Nullable<number>, options: ApplicationOptionsDto) {
    this._applicationOptions$.next({ ...options, id });
  }

  clearApplicationOptions() {
    this._applicationOptions$.next(null);
    this.updateOptionsInGuard = true;
  }

  private mapReadinessToSteps(readiness: ApplicationReadinessDto) {
    const pendingSteps: ApplicationStageType[] = [];
    if (readiness.isAdditionalInfoReady === false) pendingSteps.push(ApplicationStageType.AdditionalInfo);
    if (readiness.isIncomeDataReady === false) pendingSteps.push(ApplicationStageType.IncomeData);
    if (readiness.isLoanDataReady === false) pendingSteps.push(ApplicationStageType.LoanData);
    if (readiness.isPassportDataReady === false) pendingSteps.push(ApplicationStageType.PassportData);
    if (readiness.documentsRequired?.length) pendingSteps.push(ApplicationStageType.DocumentsCollection);
    return pendingSteps;
  }

  private mapDataIssuesToSteps(dataIssues: SharedApplicationDataIssueDto[]) {
    return uniq(
      dataIssues
        .map((dataIssue) => dataIssue.type && DataIssueTypeStepMap[dataIssue.type])
        .filter((item): item is ApplicationStageType => !!item),
    );
  }
}
