import { inject } from '@angular/core';
import isNil from 'lodash-es/isNil';
import { map, of, tap } from 'rxjs';
import { Nullable } from '@lib-utils';
import {
  ApplicationApiService,
  ApplicationLoanStateType,
  ApplicationOptionsDto,
  ApplicationStageType,
} from '@lib-auto/api';
import { AutoStateService } from '@lib-auto/datasource';

const NEW_APPLICATION_STATE: ApplicationOptionsDto = {
  navigateableStages: [ApplicationStageType.GeneralInfo],
  currentStage: ApplicationStageType.GeneralInfo,
  loanState: ApplicationLoanStateType.NotIssued,
  clientFullName: '',
};

/**
 * Обновляет опции по заявке, возвращает актуальные опции
 * @param id ID заявки
 * @param autoStateService стейт заявки авто
 * @param applicationApiService АПИ сервис заявки авто
 * @returns `Observable<FeApplicationOptionsDto>`
 */
export const updateApplicationOptions$ = (
  id: Nullable<number>,
  autoStateService = inject(AutoStateService),
  applicationApiService = inject(ApplicationApiService),
) => {
  const getOptions$ = isNil(id)
    ? of<ApplicationOptionsDto>(NEW_APPLICATION_STATE)
    : applicationApiService.applicationApplicationIdOptionsGet(id);
  return getOptions$.pipe(
    tap((options) => autoStateService.setApplicationOptions(id, options)),
    map(() => autoStateService.applicationOptions!),
  );
};

/**
 * Получить опции по заявке для основной страницы, при необходимости запросить новые
 * @param id ID заявки
 * @param autoStateService AutoStateService
 * @param applicationApiService ApplicationApiService
 */
export const getApplicationOptionsForRoot$ = (
  id: Nullable<number>,
  autoStateService = inject(AutoStateService),
  applicationApiService = inject(ApplicationApiService),
) => {
  // Не запрашиваем новые опции, если они уже обновились для данного ID заявки (может быть null)
  // Например, если переходили сразу по дочернему урлу и отработал guard
  if (autoStateService.applicationOptions && autoStateService.applicationOptions.id === id) {
    return of(autoStateService.applicationOptions);
  } else {
    autoStateService.clearApplicationOptions();
    return updateApplicationOptions$(id, autoStateService, applicationApiService).pipe(
      // Следующее обновление опций из guard пропускаем, т.к. уже это сделали
      tap(() => (autoStateService.updateOptionsInGuard = false)),
    );
  }
};
