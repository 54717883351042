/**
 * FxAuto.BFF.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type Gender = 'NotSpecified' | 'Male' | 'Female';

export const Gender = {
    NotSpecified: 'NotSpecified' as Gender,
    Male: 'Male' as Gender,
    Female: 'Female' as Gender
};

