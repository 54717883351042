/**
 * Fx.Spr
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DateOnly } from '@lib-utils';


/**
 * Данные паспорта
 */
export interface Passport { 
    /**
     * Серия
     */
    series?: string | null;
    /**
     * Номер
     */
    number?: string | null;
    /**
     * Дата выдачи
     */
    issueDate?: DateOnly;
    /**
     * Кем выдан
     */
    issuedBy?: string | null;
    /**
     * Код подразделения
     */
    issueDepCode?: string | null;
    /**
     * Признак того, что в паспорте указаны ранее выданные паспорта
     */
    hasPreviousPassports?: boolean;
}

