/**
 * Fx.Spr
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Megafon } from './megafon';
import { Employer } from './employer';
import { GeneralChecks } from './generalChecks';
import { Outcome } from './outcome';
import { Income } from './income';
import { Scoring } from './scoring';
import { Delays } from './delays';
import { Fssp } from './fssp';
import { Cronos } from './cronos';
import { Decision } from './decision';
import { Equifax } from './equifax';


export interface GetResultResponse { 
    generalChecks?: GeneralChecks;
    employer?: Employer;
    income?: Income;
    outcome?: Outcome;
    scoring?: Scoring;
    delays?: Delays;
    cronos?: Cronos;
    equifax?: Equifax;
    fssp?: Fssp;
    megafon?: Megafon;
    decision?: Decision;
}

