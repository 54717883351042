/**
 * Fx.Spr
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * <p>Type: <b>FxAuto.Spr.Domain.Enums.VerificationStrategy</b></p><p>Members:</p> <ul> </ul> 
 */
export type VerificationStrategy = 'A0' | 'A3' | 'A4' | 'A5';

export const VerificationStrategy = {
    A0: 'A0' as VerificationStrategy,
    A3: 'A3' as VerificationStrategy,
    A4: 'A4' as VerificationStrategy,
    A5: 'A5' as VerificationStrategy
};

