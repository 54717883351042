/**
 * FxAuto.BFF.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type DocumentFileType = 'NotSupported' | 'Pdf' | 'Doc' | 'Docx' | 'Xls' | 'Xlsx' | 'Png' | 'Jpeg' | 'Jfif' | 'Tiff' | 'Bmp' | 'Heic';

export const DocumentFileType = {
    NotSupported: 'NotSupported' as DocumentFileType,
    Pdf: 'Pdf' as DocumentFileType,
    Doc: 'Doc' as DocumentFileType,
    Docx: 'Docx' as DocumentFileType,
    Xls: 'Xls' as DocumentFileType,
    Xlsx: 'Xlsx' as DocumentFileType,
    Png: 'Png' as DocumentFileType,
    Jpeg: 'Jpeg' as DocumentFileType,
    Jfif: 'Jfif' as DocumentFileType,
    Tiff: 'Tiff' as DocumentFileType,
    Bmp: 'Bmp' as DocumentFileType,
    Heic: 'Heic' as DocumentFileType
};

