/**
 * FxAuto.BFF.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ApplicationStatusInfoDto } from './applicationStatusInfoDto';
import { ApplicationListStatusInfoDto } from './applicationListStatusInfoDto';
import { BankDepartmentDto } from './bankDepartmentDto';
import { DealerDto } from './dealerDto';
import { UserInfoDto } from './userInfoDto';
import { ApplicationListDecisionStageInfoDto } from './applicationListDecisionStageInfoDto';
import { IdNameDto } from './idNameDto';


export interface ApplicationListOptionsDto { 
    creatorUsers?: Array<UserInfoDto> | null;
    dealers?: Array<DealerDto> | null;
    showrooms?: Array<IdNameDto> | null;
    decisionStages?: Array<ApplicationListDecisionStageInfoDto> | null;
    listStatuses?: Array<ApplicationListStatusInfoDto> | null;
    statuses?: Array<ApplicationStatusInfoDto> | null;
    bankDepartments?: Array<BankDepartmentDto> | null;
}

