import { Injectable } from '@angular/core';
import { Nullable } from '@lib-utils';
import { CalculationResultType, DealerShowroomDto } from '@lib-auto/api';
import { FeCalculateRequestDto } from '../models';

@Injectable({ providedIn: 'root' })
export class AutoCalculationService {
  savedData: Nullable<{
    showroom: DealerShowroomDto;
    calculateRequest: FeCalculateRequestDto;
    calculationResultType: CalculationResultType;
  }>;

  saveCalculation(
    showroom: DealerShowroomDto,
    calculateRequest: FeCalculateRequestDto,
    calculationResultType: CalculationResultType,
  ) {
    this.savedData = { showroom, calculateRequest, calculationResultType };
  }

  clearData() {
    this.savedData = null;
  }
}
