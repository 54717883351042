/**
 * FxAuto.BFF.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { VerificationVehicleCategory } from './verificationVehicleCategory';
import { VerificationEngineType } from './verificationEngineType';


export interface VerificationVehiclePassportDto { 
    vehicleIdentificationNumber?: string | null;
    make?: string | null;
    model?: string | null;
    year?: number | null;
    engineType?: VerificationEngineType;
    engineDisplacement?: number | null;
    engineNumbers?: string | null;
    enginePowers?: Array<number> | null;
    vehicleCategory?: VerificationVehicleCategory;
    number?: string | null;
    isDuplicate?: boolean | null;
}
export namespace VerificationVehiclePassportDto {
}


