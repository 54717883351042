/**
 * Fx.Spr
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * <p>Type: <b>FxAuto.Spr.Domain.Enums.EmploymentType</b></p><p>Members:</p> <ul> <p><li><i>Employee</i> - Работа по найму</li></p> <p><li><i>Military</i> - Военный</li></p> <p><li><i>Entrepreneur</i> - ИП</li></p> </ul> 
 */
export type EmploymentType = 'Employee' | 'Military' | 'Entrepreneur';

export const EmploymentType = {
    Employee: 'Employee' as EmploymentType,
    Military: 'Military' as EmploymentType,
    Entrepreneur: 'Entrepreneur' as EmploymentType
};

