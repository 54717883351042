/**
 * FxAuto.BFF.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type DocumentGroupType = 'None' | 'Main' | 'Application' | 'AdditionalProduct';

export const DocumentGroupType = {
    None: 'None' as DocumentGroupType,
    Main: 'Main' as DocumentGroupType,
    Application: 'Application' as DocumentGroupType,
    AdditionalProduct: 'AdditionalProduct' as DocumentGroupType
};

