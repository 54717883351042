/**
 * FxAuto.Lookup.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CreditProductType } from './creditProductType';


export interface CreditProductDto { 
    id?: number;
    name?: string | null;
    type?: CreditProductType;
    downPaymentPercentMin?: number;
    downPaymentPercentMax?: number;
    amountMin?: number;
    amountMax?: number;
    termMax?: number;
    termMin?: number;
    rate?: number;
    vehicleInsuranceRequiredAmount?: number | null;
    additionalProductsPercentMax?: number;
    ltv?: number;
    position?: number;
}
export namespace CreditProductDto {
}


