/**
 * Fx.Spr
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ClientType } from './clientType';
import { LiabilityStatusType } from './liabilityStatusType';
import { CreditReportSourceType } from './creditReportSourceType';
import { DateOnly } from '@lib-utils';
import { LiabilityType } from './liabilityType';


export interface Obligation { 
    /**
     * Уникальный идентификатор кредита
     */
    uuid?: string | null;
    liabilityStatus?: LiabilityStatusType;
    /**
     * Дата подтверждения статуса кредитного обязательства
     */
    confirmationDate?: DateOnly | null;
    liabilityType?: LiabilityType;
    clientType?: ClientType;
    /**
     * Первоначальная сумма/лимит кредитного обязательства
     */
    creditSum?: number;
    /**
     * Валюта кредитного обязательства
     */
    currency?: string | null;
    /**
     * Дата открытия кредитного обязательства
     */
    openDate?: DateOnly | null;
    /**
     * Фактическая дата закрытия кредитного обязательства
     */
    repaymentDate?: DateOnly | null;
    /**
     * Плановая дата закрытия кредитного обязательства
     */
    scheduledRepaymentDate?: DateOnly | null;
    /**
     * Полная стоимость кредита
     */
    costRate?: number | null;
    /**
     * Сумма текущей просрочки
     */
    currentArrearsAmount?: number;
    /**
     * Строка платежной дисциплины из БКИ
     */
    paymentsString84M?: string | null;
    /**
     * Дата конца строки платежной дисциплины из БКИ
     */
    paymentsStringEnd?: DateOnly;
    /**
     * Остаток ссудной задолженности по кредитному обязательству
     */
    loansReceivable?: number;
    /**
     * Вид деятельности источника информации о кредитном обязательстве
     */
    businessCategory?: number;
    /**
     * Типы источника данных
     */
    sources?: Array<CreditReportSourceType> | null;
}

