export const AdminStatus = {
  Active: 'active',
  Inactive: 'inactive',
  Blocked: 'blocked',
  Hidden: 'hidden',
} as const;

export type AdminStatus = (typeof AdminStatus)[keyof typeof AdminStatus];

export const AdminStatusMap: Record<AdminStatus, string> = {
  [AdminStatus.Active]: 'Активный',
  [AdminStatus.Inactive]: 'Неактивный',
  [AdminStatus.Blocked]: 'Заблокирован',
  [AdminStatus.Hidden]: 'Скрыт',
};

export const AdminStatusTagClassMap = {
  [AdminStatus.Active]: 'success-tag',
  [AdminStatus.Inactive]: 'light-gray-tag',
  [AdminStatus.Blocked]: 'error-tag',
  [AdminStatus.Hidden]: 'light-gray-tag',
};
