import { FileInfo, FormGroupOf } from '@lib-utils';
import { DocumentControlInfo } from './document-control-info';

/**
 * Интерфейс для форм подгрузки документов авто
 */
export type DocumentsFormDto = Partial<Record<string, FileInfo<number>[]>>;

/**
 * Интерфейс, возвращаемый утилитой, обрабатывающей входную информацию о документах
 */
export interface DocumentGroupDto {
  form: FormGroupOf<DocumentsFormDto>;
  documentControls: DocumentControlInfo[];
}
