import { ApplicationListItemDto, ApplicationListStatusType, ApplicationStageType } from '@lib-auto/api';

export const AutoListMock: Array<ApplicationListItemDto & { rejectionCode?: string }> = [
  {
    id: 41,
    createdOn: '2022-12-27T07:20:26.637855Z',
    updatedOn: '2022-12-27T07:20:26.637855Z',
    client: { name: 'ТРОПИНА ИРИНА ВЛАДИМИРОВНА' },
    statusName: 'Кредит выдан',
    creator: { fullName: 'Аристархов Иван Павлович' },
  },
  {
    id: 2941,
    createdOn: '2022-12-27T07:20:26.637855Z',
    updatedOn: '2022-12-27T07:20:26.637855Z',
    client: { name: 'АГАПОВА НАДЕЖДА СЕРГЕЕВНА' },
    statusName: 'Отказ',
    rejectionCode: 'C42',
    statusType: ApplicationListStatusType.Issue,
    creator: { fullName: 'Киркоров Филипп Бедросович' },
  },
  {
    id: 43,
    createdOn: '2022-12-27T07:20:26.637855Z',
    updatedOn: '2022-12-27T07:20:26.637855Z',
    client: { name: 'ГОЛУБЕВА АНАСТАСИЯ ВЯЧЕСЛАВОВНА' },
    statusName: 'На одобрении',
    creator: { fullName: 'Киркоров Филипп Бедросович' },
  },
  {
    id: 1114,
    createdOn: '2022-12-27T07:20:26.637855Z',
    updatedOn: '2022-12-27T07:20:26.637855Z',
    client: { name: 'БАБИЧЕВА НАТАЛИЯ ВИКТОРОВНА' },
    statusName: 'Одобрено',
    statusType: ApplicationListStatusType.Ok,
    creator: { fullName: 'Киркоров Филипп Бедросович' },
  },
  {
    id: 1115,
    createdOn: '2022-12-27T07:20:26.637855Z',
    updatedOn: '2022-12-27T07:20:26.637855Z',
    client: { name: 'Иванов Иван Иванович' },
    statusName: 'Кредит выдан',
    creator: { fullName: 'Киркоров Филипп Бедросович' },
  },
  {
    id: 1116,
    createdOn: '2022-12-27T07:20:26.637855Z',
    updatedOn: '2022-12-27T07:20:26.637855Z',
    client: { name: 'ЗЮЗИНА СВЕТЛАНА АЛЕКСАНДРОВНА' },
    statusName: 'Кредит выдан',
    creator: { fullName: 'Киркоров Филипп Бедросович' },
  },
  {
    id: 1117,
    createdOn: '2022-12-27T07:20:26.637855Z',
    updatedOn: '2022-12-27T07:20:26.637855Z',
    client: { name: 'Ивановский Петр Викентиевич' },
    statusName: 'Кредит оплачен',
    creator: { fullName: 'Киркоров Филипп Бедросович' },
  },
  {
    id: 1118,
    createdOn: '2022-12-27T07:20:26.637855Z',
    updatedOn: '2022-12-27T07:20:26.637855Z',
    client: { name: 'ХАЛАБУДИНА ЮЛИЯ АЛЕКСЕЕВНА' },
    statusName: 'Кредит оплачен',
    creator: { fullName: 'Киркоров Филипп Бедросович' },
  },
  {
    id: 1119,
    createdOn: '2022-12-27T07:20:26.637855Z',
    updatedOn: '2022-12-27T07:20:26.637855Z',
    client: { name: 'ДВУРЕЧЕНСКИЙ ПАВЕЛ АЛЕКСАНДРОВИЧ' },
    currentStage: ApplicationStageType.LoanIssued,
    statusName: 'Кредит оплачен',
    creator: { fullName: 'Киркоров Филипп Бедросович' },
  },
  {
    id: 1120,
    createdOn: '2022-12-27T07:20:26.637855Z',
    updatedOn: '2022-12-27T07:20:26.637855Z',
    client: { name: 'ДВУРЕЧЕНСКИЙ ПАВЕЛ АЛЕКСАНДРОВИЧ' },
    statusName: 'Кредит выдан',
    creator: { fullName: 'Киркоров Филипп Бедросович' },
  },
];
