export const AutoDecisionState = {
  Approved: 'approved',
  Edited: 'edited',
  Introduced: 'introduced',
} as const;

export type AutoDecisionState = (typeof AutoDecisionState)[keyof typeof AutoDecisionState];

export const AutoDecisionStateMap: Record<AutoDecisionState, string> = {
  [AutoDecisionState.Approved]: 'Одобрено',
  [AutoDecisionState.Edited]: 'Редактируется',
  [AutoDecisionState.Introduced]: 'Заведено',
};
