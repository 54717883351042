/**
 * Fx.Spr
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CustomAttributeTypedArgument } from './customAttributeTypedArgument';
import { Type } from './type';
import { CustomAttributeNamedArgument } from './customAttributeNamedArgument';
import { ConstructorInfo } from './constructorInfo';


export interface CustomAttributeData { 
    attributeType?: Type;
    constructor?: ConstructorInfo;
    readonly constructorArguments?: Array<CustomAttributeTypedArgument> | null;
    readonly namedArguments?: Array<CustomAttributeNamedArgument> | null;
}

