/**
 * Fx.Spr
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * <p>Type: <b>FxAuto.Spr.Domain.Enums.CallStatus</b></p><p>Members:</p> <ul> </ul> 
 */
export type CallStatus = 'Answer' | 'NoAnswer' | 'NoConnection' | 'ConnectionUnavailable';

export const CallStatus = {
    Answer: 'Answer' as CallStatus,
    NoAnswer: 'NoAnswer' as CallStatus,
    NoConnection: 'NoConnection' as CallStatus,
    ConnectionUnavailable: 'ConnectionUnavailable' as CallStatus
};

