/**
 * Fx.Spr
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Блок с информацией по судебному решению.
 */
export interface Legal { 
    /**
     * Номер претензии, присвоенный судом.
     */
    claimNumber?: string | null;
    /**
     * Название суда.
     */
    courtName?: string | null;
    /**
     * Дата вынесения решения.
     */
    judgmentDate?: string | null;
    /**
     * Истец.
     */
    plaintiff?: string | null;
    /**
     * Результаты и решения по иску.
     */
    resolution?: string | null;
    /**
     * Дата возмещения (полной оплаты кредита).
     */
    satisfiedDate?: string | null;
    resolutionNumber?: string | null;
    resolutionType?: number;
    recoveryAmount?: number;
    recoveryCurrency?: string | null;
    /**
     * Дата и время первой передачи информации.
     */
    firstUpdate?: string | null;
    /**
     * Дата и время последней передачи информации.
     */
    lastUpdate?: string | null;
    /**
     * Учетная дата, на которую передана информация.
     */
    reportDate?: string | null;
    /**
     * Код взыскиваемого долга.
     */
    recoverySubjectCode?: number;
    /**
     * Номер исполнительного документа.
     */
    executiveDocNo?: string | null;
    /**
     * Сумма единовременного взыскания.
     */
    recoverySingleAmount?: number;
    /**
     * Сумма периодического взыскания.
     */
    recoveryPeriodAmount?: number;
    /**
     * Код частоты платежей.
     */
    paymentFrequency?: number;
    /**
     * Признак вступления акта в законную силу.
     */
    isAccepted?: number;
    /**
     * Код дееспособности.
     */
    incapacityCode?: number;
}

