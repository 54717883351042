/**
 * Fx.Spr
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type MethodAttributes = 'ReuseSlot' | 'Private' | 'FamANDAssem' | 'Assembly' | 'Family' | 'FamORAssem' | 'Public' | 'MemberAccessMask' | 'UnmanagedExport' | 'Static' | 'Final' | 'Virtual' | 'HideBySig' | 'NewSlot' | 'CheckAccessOnOverride' | 'Abstract' | 'SpecialName' | 'RTSpecialName' | 'PinvokeImpl' | 'HasSecurity' | 'RequireSecObject' | 'ReservedMask';

export const MethodAttributes = {
    ReuseSlot: 'ReuseSlot' as MethodAttributes,
    Private: 'Private' as MethodAttributes,
    FamAndAssem: 'FamANDAssem' as MethodAttributes,
    Assembly: 'Assembly' as MethodAttributes,
    Family: 'Family' as MethodAttributes,
    FamOrAssem: 'FamORAssem' as MethodAttributes,
    Public: 'Public' as MethodAttributes,
    MemberAccessMask: 'MemberAccessMask' as MethodAttributes,
    UnmanagedExport: 'UnmanagedExport' as MethodAttributes,
    Static: 'Static' as MethodAttributes,
    Final: 'Final' as MethodAttributes,
    Virtual: 'Virtual' as MethodAttributes,
    HideBySig: 'HideBySig' as MethodAttributes,
    NewSlot: 'NewSlot' as MethodAttributes,
    CheckAccessOnOverride: 'CheckAccessOnOverride' as MethodAttributes,
    Abstract: 'Abstract' as MethodAttributes,
    SpecialName: 'SpecialName' as MethodAttributes,
    RtSpecialName: 'RTSpecialName' as MethodAttributes,
    PinvokeImpl: 'PinvokeImpl' as MethodAttributes,
    HasSecurity: 'HasSecurity' as MethodAttributes,
    RequireSecObject: 'RequireSecObject' as MethodAttributes,
    ReservedMask: 'ReservedMask' as MethodAttributes
};

