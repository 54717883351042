/**
 * FxAuto.Lookup.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CreditProductDto } from './creditProductDto';
import { AdditionalProductOptionDto } from './additionalProductOptionDto';
import { CreditProductTypeDto } from './creditProductTypeDto';
import { CreditProductType } from './creditProductType';


export interface CalculatorDealerOptionsDto { 
    creditProducts?: Array<CreditProductDto> | null;
    creditProductTypes?: Array<CreditProductTypeDto> | null;
    additionalProducts?: Array<AdditionalProductOptionDto> | null;
    defaultCreditProductId?: number | null;
    defaultCreditProductType?: CreditProductType;
}
export namespace CalculatorDealerOptionsDto {
}


