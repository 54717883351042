/**
 * FxAuto.BFF.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type DealInfoIssueType = 'Other' | 'Passport' | 'ClientPhoto' | 'VehicleSaleContract' | 'DownPaymentReceipt' | 'VehiclePaymentInvoice' | 'Sopd' | 'PassportFull';

export const DealInfoIssueType = {
    Other: 'Other' as DealInfoIssueType,
    Passport: 'Passport' as DealInfoIssueType,
    ClientPhoto: 'ClientPhoto' as DealInfoIssueType,
    VehicleSaleContract: 'VehicleSaleContract' as DealInfoIssueType,
    DownPaymentReceipt: 'DownPaymentReceipt' as DealInfoIssueType,
    VehiclePaymentInvoice: 'VehiclePaymentInvoice' as DealInfoIssueType,
    Sopd: 'Sopd' as DealInfoIssueType,
    PassportFull: 'PassportFull' as DealInfoIssueType
};

