/**
 * Fx.Spr
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * <p>Type: <b>FxAuto.Spr.Domain.Enums.Obligations.CreditObligationLiabilityType</b></p><p>Members:</p> <ul> <p><li><i>CarCredit</i> - Автокредит</li></p> <p><li><i>Mortgage</i> - Ипотека</li></p> <p><li><i>ConsumerCredit</i> - Потребительский кредит</li></p> <p><li><i>CreditCard</i> - Кредитная карта</li></p> <p><li><i>BusinessCredit</i> - Бизнес-кредит</li></p> <p><li><i>OtherNonCredit</i> - Иное некредитное обязательство</li></p> <p><li><i>MicroloanForCostRate</i> - Микрозаем для расчета ПСК</li></p> </ul> 
 */
export type CreditObligationLiabilityType = 'CarCredit' | 'Mortgage' | 'ConsumerCredit' | 'CreditCard' | 'BusinessCredit' | 'OtherNonCredit' | 'MicroloanForCostRate';

export const CreditObligationLiabilityType = {
    CarCredit: 'CarCredit' as CreditObligationLiabilityType,
    Mortgage: 'Mortgage' as CreditObligationLiabilityType,
    ConsumerCredit: 'ConsumerCredit' as CreditObligationLiabilityType,
    CreditCard: 'CreditCard' as CreditObligationLiabilityType,
    BusinessCredit: 'BusinessCredit' as CreditObligationLiabilityType,
    OtherNonCredit: 'OtherNonCredit' as CreditObligationLiabilityType,
    MicroloanForCostRate: 'MicroloanForCostRate' as CreditObligationLiabilityType
};

