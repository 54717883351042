/**
 * FxAuto.BFF.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ClientRefusedReasonType = 'FoundBetterConditionsAtAnotherBank' | 'ChangedMyMindAboutTakingOutLoan' | 'AvoidedAnswering' | 'AnotherReason' | 'DuplicateApplication';

export const ClientRefusedReasonType = {
    FoundBetterConditionsAtAnotherBank: 'FoundBetterConditionsAtAnotherBank' as ClientRefusedReasonType,
    ChangedMyMindAboutTakingOutLoan: 'ChangedMyMindAboutTakingOutLoan' as ClientRefusedReasonType,
    AvoidedAnswering: 'AvoidedAnswering' as ClientRefusedReasonType,
    AnotherReason: 'AnotherReason' as ClientRefusedReasonType,
    DuplicateApplication: 'DuplicateApplication' as ClientRefusedReasonType
};

