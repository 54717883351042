import { Nullable } from '@lib-utils';
import { ConditionType } from './auto-condition-type';
import { RateType } from './auto-rate-type';

export interface BaseRateDto {
  rate?: Nullable<number>;
  rateType?: Nullable<RateType>;
  conditionType?: Nullable<ConditionType>;
  includeAdditionalProducts?: Nullable<boolean>;
}
