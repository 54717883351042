/**
 * FxAuto.BFF.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type VerificationEngineType = 'Petrol' | 'Diesel' | 'Hybrid' | 'Electric' | 'Gas';

export const VerificationEngineType = {
    Petrol: 'Petrol' as VerificationEngineType,
    Diesel: 'Diesel' as VerificationEngineType,
    Hybrid: 'Hybrid' as VerificationEngineType,
    Electric: 'Electric' as VerificationEngineType,
    Gas: 'Gas' as VerificationEngineType
};

