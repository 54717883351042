/**
 * Fx.Spr
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CustomAttributeData } from './customAttributeData';
import { Type } from './type';
import { SecurityRuleSet } from './securityRuleSet';
import { MethodInfo } from './methodInfo';
import { Module } from './module';
import { TypeInfo } from './typeInfo';


export interface Assembly { 
    readonly definedTypes?: Array<TypeInfo> | null;
    readonly exportedTypes?: Array<Type> | null;
    readonly codeBase?: string | null;
    entryPoint?: MethodInfo;
    readonly fullName?: string | null;
    readonly imageRuntimeVersion?: string | null;
    readonly isDynamic?: boolean;
    readonly location?: string | null;
    readonly reflectionOnly?: boolean;
    readonly isCollectible?: boolean;
    readonly isFullyTrusted?: boolean;
    readonly customAttributes?: Array<CustomAttributeData> | null;
    readonly escapedCodeBase?: string | null;
    manifestModule?: Module;
    readonly modules?: Array<Module> | null;
    readonly globalAssemblyCache?: boolean;
    readonly hostContext?: number;
    securityRuleSet?: SecurityRuleSet;
}

