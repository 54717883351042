/**
 * FxAuto.Lookup.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Официальный статус дилера
 */
export type DealerOfficialStatus = 'Other' | 'Official' | 'Unofficial' | 'ChinaOfficial';

export const DealerOfficialStatus = {
    Other: 'Other' as DealerOfficialStatus,
    Official: 'Official' as DealerOfficialStatus,
    Unofficial: 'Unofficial' as DealerOfficialStatus,
    ChinaOfficial: 'ChinaOfficial' as DealerOfficialStatus
};

