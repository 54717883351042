/**
 * FxAuto.Lookup.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Состояние
 */
export type State = 'Active' | 'Blocked' | 'Hidden' | 'Inactive';

export const State = {
    Active: 'Active' as State,
    Blocked: 'Blocked' as State,
    Hidden: 'Hidden' as State,
    Inactive: 'Inactive' as State
};

