import { FormControl, FormGroup } from '@angular/forms';
import { FormControlsOf, Nullable } from '@lib-utils';
import { AdditionalProductOptionDto } from '@lib-auto/api';

export const createAdditionalProductFg = (product?: Nullable<AdditionalProductOptionDto>) =>
  new FormGroup<FormControlsOf<AdditionalProductOptionDto>>({
    type: new FormControl(product?.type),
    name: new FormControl(product?.name),
    price: new FormControl(product?.price),
    isAddedByDefault: new FormControl(product?.isAddedByDefault),
    providerId: new FormControl(product?.providerId),
    providerName: new FormControl(product?.providerName),
    disallowCombineWithType: new FormControl(product?.disallowCombineWithType),
  });
