/**
 * FxAuto.Lookup.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type AdditionalProductType = 'LifeInsurance' | 'VoluntaryVehicleInsurance' | 'RenaissanceLifeInsurance' | 'RenaissanceLifeAndHealthInsurance' | 'RenaissanceAccidentWithSecondMemberInsurance' | 'RenaissanceAccidentHelpInsurance' | 'RenaissanceAccidentHelpExtendedInsurance' | 'RoadMap' | 'GAPInsurance' | 'ExtendedWarranty' | 'CompulsoryVehicleDriverInsurance' | 'ExtendedVehicleDriverInsurance' | 'PhoneMedicine' | 'LegalAndInsuranceSupport' | 'LoanPaymentsWarranty' | 'AdditionalEquipment';

export const AdditionalProductType = {
    LifeInsurance: 'LifeInsurance' as AdditionalProductType,
    VoluntaryVehicleInsurance: 'VoluntaryVehicleInsurance' as AdditionalProductType,
    RenaissanceLifeInsurance: 'RenaissanceLifeInsurance' as AdditionalProductType,
    RenaissanceLifeAndHealthInsurance: 'RenaissanceLifeAndHealthInsurance' as AdditionalProductType,
    RenaissanceAccidentWithSecondMemberInsurance: 'RenaissanceAccidentWithSecondMemberInsurance' as AdditionalProductType,
    RenaissanceAccidentHelpInsurance: 'RenaissanceAccidentHelpInsurance' as AdditionalProductType,
    RenaissanceAccidentHelpExtendedInsurance: 'RenaissanceAccidentHelpExtendedInsurance' as AdditionalProductType,
    RoadMap: 'RoadMap' as AdditionalProductType,
    GapInsurance: 'GAPInsurance' as AdditionalProductType,
    ExtendedWarranty: 'ExtendedWarranty' as AdditionalProductType,
    CompulsoryVehicleDriverInsurance: 'CompulsoryVehicleDriverInsurance' as AdditionalProductType,
    ExtendedVehicleDriverInsurance: 'ExtendedVehicleDriverInsurance' as AdditionalProductType,
    PhoneMedicine: 'PhoneMedicine' as AdditionalProductType,
    LegalAndInsuranceSupport: 'LegalAndInsuranceSupport' as AdditionalProductType,
    LoanPaymentsWarranty: 'LoanPaymentsWarranty' as AdditionalProductType,
    AdditionalEquipment: 'AdditionalEquipment' as AdditionalProductType
};

