/**
 * Fx.Spr
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { QuestionInfo } from './questionInfo';
import { VerificationTaskStatus } from './verificationTaskStatus';
import { DateTime } from '@lib-utils';
import { VerificationTaskType } from './verificationTaskType';


export interface PhoneVerificationTaskModel { 
    /**
     * Идентификатор сущности
     */
    id?: number;
    /**
     * Признак активности
     */
    isActive?: boolean;
    /**
     * Аудит. поле (создано)
     */
    created?: DateTime;
    /**
     * Аудит. поле (обновлено)
     */
    updated?: DateTime;
    externalId?: string;
    applicationId?: number;
    clientId?: number;
    type?: VerificationTaskType;
    phone?: string | null;
    questions?: Array<QuestionInfo> | null;
    status?: VerificationTaskStatus;
}

