/**
 * FxAuto.BFF.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ApplicationDataIssueType = 'GeneralInfo' | 'Passport' | 'Income' | 'Education' | 'DriverLicense' | 'Family' | 'LoanData' | 'Documents';

export const ApplicationDataIssueType = {
    GeneralInfo: 'GeneralInfo' as ApplicationDataIssueType,
    Passport: 'Passport' as ApplicationDataIssueType,
    Income: 'Income' as ApplicationDataIssueType,
    Education: 'Education' as ApplicationDataIssueType,
    DriverLicense: 'DriverLicense' as ApplicationDataIssueType,
    Family: 'Family' as ApplicationDataIssueType,
    LoanData: 'LoanData' as ApplicationDataIssueType,
    Documents: 'Documents' as ApplicationDataIssueType
};

