/**
 * FxAuto.BFF.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SaveDealInvoiceDto } from './saveDealInvoiceDto';
import { SaveDealAdditionalProductPaymentDto } from './saveDealAdditionalProductPaymentDto';


export interface SaveDealNegotiationDataDto { 
    residualValueInvoice?: SaveDealInvoiceDto;
    additionalProductPayments?: Array<SaveDealAdditionalProductPaymentDto> | null;
}

