/**
 * Fx.Spr
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PdnResult { 
    pdnBkiIncome?: number;
    pdnBkiRegional?: number;
    pdnAvMonthlyPaymentIncome?: number;
    pdnAvMonthlyPaymentIncomeRegional?: number;
    pdBki?: number;
    pdAvMonthlyPayment?: number;
    odBki?: number;
    odAvMonthlyPayment?: number;
    hasRegionalIncomeSign?: boolean;
    pdnRegisteredIncome?: number;
}

