import { Nullable } from '@lib-utils';
import { EmploymentType } from '@lib-auto/api';

export interface AutoRiskCompanyDto {
  name: Nullable<string>;
  inn: Nullable<string>;
  formBySPARK: Nullable<string>;
  foundingDate: Nullable<string>;
  address: Nullable<string>;
  phoneForm: Nullable<string>;
  phoneVerification?: Nullable<string>;
  companyStatus: Nullable<string>;
  mainOKVED: Nullable<string>;
  additionalOKVEDList: string[];
  headcountBySPARK: Nullable<number>;
  supervisorFullName: Nullable<string>;
  beneficiaryFullNames: Nullable<string[]>;
}
export interface AutoRiskBorrowerWorkDto {
  company: AutoRiskCompanyDto;

  employmentType: Nullable<EmploymentType>;
  positionFront: Nullable<string>;
  positionPhoneVerification: Nullable<string>;
  experience: Nullable<number>;
  workPhoneNumber: Nullable<string>;
}
