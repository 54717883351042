/**
 * FxAuto.BFF.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type DecisionResultType = 'Declined' | 'RevisionRequired' | 'Approved' | 'LoanLimitExceeded' | 'ApprovedLoanDeclinedVehicle' | 'VehicleNotSpecified' | 'ApprovedLoanAndVehicle';

export const DecisionResultType = {
    Declined: 'Declined' as DecisionResultType,
    RevisionRequired: 'RevisionRequired' as DecisionResultType,
    Approved: 'Approved' as DecisionResultType,
    LoanLimitExceeded: 'LoanLimitExceeded' as DecisionResultType,
    ApprovedLoanDeclinedVehicle: 'ApprovedLoanDeclinedVehicle' as DecisionResultType,
    VehicleNotSpecified: 'VehicleNotSpecified' as DecisionResultType,
    ApprovedLoanAndVehicle: 'ApprovedLoanAndVehicle' as DecisionResultType
};

