import { FromGeneratedEnum } from '@lib-utils';
import { EducationType } from '@lib-auto/api';

export const EducationTypeMap: FromGeneratedEnum<EducationType> = {
  [EducationType.AcademicDegree]: 'Ученая степень',
  [EducationType.Higher]: 'Высшее',
  [EducationType.MultipleHigher]: 'Два высших и более',
  [EducationType.IncompleteHigher]: 'Неоконченное высшее',
  [EducationType.Secondary]: 'Среднее',
  [EducationType.Vocational]: 'Среднее специальное',
  [EducationType.AboveSecondary]: 'Ниже среднего',
  [EducationType.RussianMba]: 'Российское МВА',
  [EducationType.ForeignMba]: 'Иностранное МВА',
  [EducationType.Other]: 'Иное',
};
