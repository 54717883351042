/**
 * Fx.Spr
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Cводные данные о кредитных обязательствах, полученные из БКИ
 */
export interface CreditBureauObligationOverview { 
    /**
     * Общее число просрочек до 30 дней за последние 12 месяцев.
     */
    ttlDelq30L12M?: number;
    /**
     * Общее число просрочек от 30 до 59 дней за последние 12 месяцев.
     */
    ttlDelq3059L12M?: number;
    /**
     * Общее число просрочек от 60 до 89 дней за последние 12 месяцев.
     */
    ttlDelq6089L12M?: number;
    /**
     * Общее число просрочек 90+ дней за последние 12 месяцев.
     */
    ttlDelq90PlusL12M?: number;
    /**
     * Всего кредитных договоров, в которых клиент является основным заемщиком.
     */
    loansMainBorrower?: number;
    /**
     * Всего активных кредитных договоров.
     */
    loansActive?: number;
    /**
     * Дата открытия последнего счета.
     */
    lastLoanDate?: string | null;
    /**
     * Дата открытия первого счета.
     */
    firstLoanDate?: string | null;
    /**
     * Наивысший текущий просроченный статус (справочник статусов платежей TUTDF).
     */
    maxOverdueStatus?: string | null;
    /**
     * Общее число официальной информации в кредитной истории.
     */
    ttlOfficials?: number;
    /**
     * Общее число судебных элементов в кредитной истории.
     */
    ttlLegals?: number;
    /**
     * Общее число несостоятельностей.
     */
    ttlBankruptcies?: number;
    /**
     * Последняя дата обновления официальной информации.
     */
    recentOfficialUpdateDate?: string | null;
    /**
     * Последняя дата обновления судебной информации.
     */
    recentLegalUpdateDate?: string | null;
    /**
     * Период, прошедший с момента последнего запроса.
     */
    inquiryRecentPeriod?: number;
    /**
     * Общее число спорных элементов Кредитное дело.
     */
    ttlDisputedAccounts?: number;
    /**
     * Общее число спорных судебных элементов.
     */
    ttlDisputedLegals?: number;
    /**
     * Общее число спорной официальной информации.
     */
    ttlDisputedOfficials?: number;
    /**
     * Общее число запросов кредитной истории за последние 3 месяца.
     */
    inquiry3Month?: number;
    /**
     * Общее число запросов кредитной истории за последние 6 месяцев.
     */
    inquiry6Month?: number;
    /**
     * Общее число запросов кредитной истории за последние 9 месяцев.
     */
    inquiry9Month?: number;
    /**
     * Общее число запросов кредитной истории за последние 12 месяцев.
     */
    inquiry12Month?: number;
    /**
     * Общее число запросов кредитной истории.
     */
    ttlInquiries?: number;
    /**
     * Auto Loan - количество.
     */
    ttlAutoLoan?: number;
    /**
     * Mortgage - количество.
     */
    ttlMortgage?: number;
    /**
     * Credit Card - количество.
     */
    ttlCreditCard?: number;
    /**
     * Consumer Credit - количество.
     */
    ttlConsumer?: number;
    /**
     * Количество договоров в кредитной истории.
     */
    ttlAccounts?: number;
    /**
     * Исторически худший платежный статус.
     */
    worstStatusEver?: string | null;
    /**
     * Общее число просрочек до 5 дней.
     */
    ttlDelq5?: number;
    /**
     * Общее число просрочек от 5 до 29 дней.
     */
    ttlDelq529?: number;
    /**
     * Общее число просрочек от 30 до 59 дней.
     */
    ttlDelq3059?: number;
    /**
     * Общее число просрочек от 60 до 89 дней.
     */
    ttlDelq6089?: number;
    /**
     * Общее число просрочек 90+ дней.
     */
    ttlDelq90Plus?: number;
    /**
     * Всего кредитных договоров, в которых клиент является со-заемщиком или поручителем.
     */
    loansCoborrower?: number;
    /**
     * Всего активных кредитных договоров, в которых клиент является созаемщиком или поручителем.
     */
    loansActiveCoborrower?: number;
    /**
     * Текущая нагрузка клиента. Сумма в рублях по курсу ЦБ РФ.
     */
    payLoad?: number;
    /**
     * ПРИЗНАК 1. ЕСТЬ кредитные договора с негативными статусами.
     */
    criteria1?: string | null;
    /**
     * ПРИЗНАК 2. ЕСТЬ кредитные договора с просрочками более 59 дней за последний год активности договора.
     */
    criteria2?: string | null;
    /**
     * ПРИЗНАК 3. ЕСТЬ кредитные договора с просрочками 30-59 дней более 2-х раз за последний год активности договора.
     */
    criteria3?: string | null;
    /**
     * ПРИЗНАК 4. ЕСТЬ кредитные договора с просрочками 30-59 дней  более 1-го раза за последний год активности договора.
     */
    criteria4?: string | null;
    /**
     * ПРИЗНАК 5. ЕСТЬ кредитные договора с просрочками 5-29 дней более 3-х раз за последние 12 месяцев.
     */
    criteria5?: string | null;
    /**
     * ПРИЗНАК 6. ЕСТЬ активные кредиты, выданные 6 и более мес. назад, по которым клиент является заемщиком или со-заемщиком.
     */
    criteria6?: string | null;
    /**
     * Общее число запросов кредитной истории за последнюю неделю.
     */
    inquiry1Week?: number;
    /**
     * Общее число запросов кредитной истории за последний месяц.
     */
    inquiry1Month?: number;
    /**
     * Всего активных кредитных договоров, в которых клиент является основным заемщиком.
     */
    loansActiveMainBorrower?: number;
    /**
     * Количество кредитов на развитие бизнеса.
     */
    ttlBusinessDevelopment?: number;
    /**
     * Частота запросов за последний день.
     */
    inquiry1Day?: number;
    /**
     * Общее число просрочек от 1 до 7 дней.
     */
    ttlDelq7?: number;
    /**
     * Общее число просрочек от 8 до 29 дней.
     */
    ttlDelq829?: number;
}

