import { Injectable, isDevMode } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import uniqBy from 'lodash-es/uniqBy';
import { combineLatest, concatMap, from, Observable, of, takeLast, tap } from 'rxjs';
import { FeHttpError, FeHttpErrorMessage, Nullable } from '@lib-utils';
import { FormContainer, FormField } from './form-field';

interface BeErrorMessage {
  errorCode: string;
  propertyName?: string;
  message: string;
}

@Injectable({
  providedIn: 'root',
})
export class FormFieldService {
  #fields: Partial<Record<string, FormField>> = {};

  #containers: Partial<Record<string, FormContainer>> = {};

  getField(id: Nullable<string>) {
    return id ? this.#fields[id] : null;
  }

  getFieldByControl(control: AbstractControl) {
    return Object.values(this.#fields).find((field) => field?.control === control);
  }

  getContainer(id: Nullable<string>) {
    return id ? this.#containers[id] : null;
  }

  /**
   * Регистрация поля формы в сервисе
   * @param field поле формы
   */
  addField(field: FormField) {
    if (isDevMode() && this.#fields[field.id]) {
      // eslint-disable-next-line no-console
      console.warn(`Field with id ${field.id} already exists`);
    }

    this.#fields[field.id] = field;
  }

  /**
   * Удаление поля из сервиса
   * Пропускаем, если поле уже удалено
   * Если удаление вызвано сворачиванием родительского контейнера, то не убираем поле из зарегистрированных
   * Удаление поля при этом будет обработано в методе `removeContainer`
   * @param id ID поля
   */
  removeField(id: string) {
    const field = this.getField(id);
    if (!field) return;
    if (this.parentContainerCollapsed(field.parentId)) return;
    delete this.#fields[id];
  }

  /**
   * Регистрация контейнера в сервисе
   * @param container контейнер
   */
  addContainer(container: FormContainer) {
    this.#containers[container.id] = container;
  }

  /**
   * Удаление контейнера из сервиса
   * Пропускаем, если контейнер уже удален
   * Если удаление вызвано сворачиванием родительского контейнера, то не убираем контейнер и поля из сервиса
   * При удалении удаляем все дочерние контейнеры и связанные с ними поля
   * @param id ID контейнера
   */
  removeContainer(id: string) {
    const container = this.getContainer(id);
    if (!container) return;
    if (this.parentContainerCollapsed(container.parentId)) return;
    this.removeRecursively(container.id);
  }

  /**
   * Преобразование ошибок BE в текст ошибки нотификации
   * Обычный маппинг на дто фронта в случае, если связанное поле не зарегистрировано
   * @param errors валидационные BE ошибки
   */
  getLabeledMessages(errors: BeErrorMessage[]): FeHttpErrorMessage[] {
    const otherErrors: FeHttpErrorMessage[] = [];
    const sectionErrors: FeHttpErrorMessage[] = [];
    const standaloneErrors: FeHttpErrorMessage[] = [];
    const sectionFields: Partial<Record<string, FeHttpErrorMessage[]>> = {};

    errors.forEach((error) => {
      const field = this.getField(error.propertyName);
      if (field) {
        const errorMessage = {
          code: error.errorCode,
          message: `${field.label ? field.label + ': ' : ''}${error.message}`,
        };
        const parentContainer = this.getContainer(field.parentId);
        if (parentContainer) {
          if (!sectionFields[parentContainer.id]) {
            sectionFields[parentContainer.id] = [errorMessage];
          } else {
            sectionFields[parentContainer.id]?.push(errorMessage);
          }
        } else {
          standaloneErrors.push(errorMessage);
        }
      } else {
        otherErrors.push({
          code: error.errorCode,
          message: error.message,
        });
      }
    });
    Object.entries(sectionFields).forEach(([section, errors]) => {
      sectionErrors.push({
        code: FeHttpError.DEFAULT_ERROR_CODE,
        message: `Ошибки в секции "${this.getContainer(section)?.name ?? 'Неизвестная секция'}":`,
      });
      sectionErrors.push(...(errors || []));
    });
    return [...sectionErrors, ...standaloneErrors, ...otherErrors];
  }

  /**
   * Подсвечивание ошибок на UI, навигация к первой ошибке из списка (необязательно самой верхней в форме)
   * @param errors валидационные BE ошибки
   */
  displayErrors$(errors: BeErrorMessage[]): Observable<unknown> {
    // Определяем поля с ошибками
    const errorFields = errors
      .filter((error) => this.getField(error.propertyName))
      .map(({ propertyName }) => propertyName!);
    // Прежде чем скроллить и помечать поля, раскроем секции, содержащие поля
    return this.expandParentContainers$(errorFields).pipe(
      // Дожидаемся раскрытия секций, проставляем ошибки видимым полям
      tap(() => {
        errors.forEach((error) => {
          const field = this.getField(error.propertyName);
          if (field && !this.parentContainerCollapsed(field.parentId)) {
            field.control?.setErrors({ customMessage: error.message });
            field.control?.markAsTouched();
            field.cdr?.markForCheck();
          }
        });
      }),
      tap(() => this.scrollToField(errorFields[0])),
    );
  }

  scrollToField(fieldId: string) {
    const field = this.getField(fieldId);
    field?.elementRef.nativeElement.scrollIntoView({ block: 'center', behavior: 'smooth' });
    field?.cdr?.markForCheck();
  }

  /**
   * Раскрывает в порядке вложенности контейнеры, содержащие поля
   * @param fieldIds ID полей
   */
  expandParentContainers$(fieldIds: string[]) {
    const hierarchy = this.getContainerHierarchy(fieldIds);
    return hierarchy.length
      ? from(hierarchy).pipe(
          concatMap((ids) =>
            ids.length
              ? combineLatest(
                  ids.map((id) => {
                    const container = this.getContainer(id);
                    if (container && !container.isExpanded()) return container.expand$();
                    return of(null);
                  }),
                )
              : of(null),
          ),
          takeLast(1),
        )
      : of(null);
  }

  /**
   * Возвращает true, если есть свернутый родительский контейнер
   * @param parentId
   * @returns
   */
  private parentContainerCollapsed(parentId: Nullable<string>) {
    let result = false;
    let parentContainer = this.getContainer(parentId);
    while (parentContainer && !result) {
      result ||= !parentContainer.isExpanded();
      parentContainer = this.getContainer(parentContainer.parentId);
    }
    return result;
  }

  /**
   * Рекурсивно удаляет контейнер, его дочерние контейнеры и поля
   * @param id ID контейнера
   */
  private removeRecursively(id: string) {
    delete this.#containers[id];
    Object.entries(this.#fields).forEach(([key, field]) => {
      if (field?.parentId === id) delete this.#fields[key];
    });
    Object.entries(this.#containers).forEach(([key, container]) => {
      if (container?.parentId === id) this.removeRecursively(key);
    });
  }

  /**
   * Выстраивает последовательность контейнеров, содержащих список ID полей
   * Возвращает массив массивов ID контейнеров, соответствующих очередности их раскрытия
   */
  private getContainerHierarchy(fieldIds: string[]): string[][] {
    const containerHierarchy = [];
    let order = 0;
    let containerIds = fieldIds.map((fieldId) => this.getField(fieldId)?.parentId).filter(this.hasContainer);
    while (containerIds.length) {
      containerHierarchy.unshift(...containerIds.map((id) => ({ id, order })));
      order += 1;
      containerIds = containerIds
        .map((containerId) => this.getContainer(containerId)?.parentId)
        .filter(this.hasContainer);
    }
    // Оставляем только первые попадания ID контейнера в массив, группируем по order, обращаем, чтобы контейнеры верхнего уровня оказались первыми
    return uniqBy(containerHierarchy, 'id')
      .reduce(
        (res, current) => {
          res[current.order].push(current.id);
          return res;
        },
        new Array(order).fill(null).map(() => <string[]>[]),
      )
      .reverse();
  }

  private hasContainer = (containerId: Nullable<string>): containerId is string => {
    return !!this.getContainer(containerId);
  };
}
