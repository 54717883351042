/**
 * Fx.Spr
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type GenericParameterAttributes = 'None' | 'Covariant' | 'Contravariant' | 'VarianceMask' | 'ReferenceTypeConstraint' | 'NotNullableValueTypeConstraint' | 'DefaultConstructorConstraint' | 'SpecialConstraintMask';

export const GenericParameterAttributes = {
    None: 'None' as GenericParameterAttributes,
    Covariant: 'Covariant' as GenericParameterAttributes,
    Contravariant: 'Contravariant' as GenericParameterAttributes,
    VarianceMask: 'VarianceMask' as GenericParameterAttributes,
    ReferenceTypeConstraint: 'ReferenceTypeConstraint' as GenericParameterAttributes,
    NotNullableValueTypeConstraint: 'NotNullableValueTypeConstraint' as GenericParameterAttributes,
    DefaultConstructorConstraint: 'DefaultConstructorConstraint' as GenericParameterAttributes,
    SpecialConstraintMask: 'SpecialConstraintMask' as GenericParameterAttributes
};

