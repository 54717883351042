/**
 * Fx.Spr
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DateOnly } from '@lib-utils';


/**
 * Данные кредитного обязательства, полученные из БКИ
 */
export interface CreditBureauObligation { 
    /**
     * Уникальный идентификатор кредита
     */
    uuid?: string | null;
    /**
     * Статус кредитного обязательства
     */
    liabilityStatus?: string | null;
    /**
     * Дата подтверждения статуса кредитного обязательства
     */
    confirmationDate?: DateOnly | null;
    /**
     * Тип кредитного обязательства
     */
    liabilityType?: string | null;
    /**
     * Тип участника сделки по кредитному обязательству
     */
    clientType?: string | null;
    /**
     * Первоначальная сумма/лимит кредитного обязательства
     */
    creditSum?: number;
    /**
     * Валюта кредитного обязательства
     */
    currency?: string | null;
    /**
     * Дата открытия кредитного обязательства
     */
    openDate?: DateOnly | null;
    /**
     * Фактическая дата закрытия кредитного обязательства
     */
    repaymentDate?: DateOnly | null;
    /**
     * Плановая дата закрытия кредитного обязательства
     */
    scheduledRepaymentDate?: DateOnly | null;
    /**
     * Полная стоимость кредита
     */
    costRate?: number | null;
    /**
     * Сумма текущей просрочки
     */
    currentArrearsAmount?: number;
    /**
     * Строка платежной дисциплины из БКИ
     */
    paymentsString84M?: string | null;
    /**
     * Дата начала строки платежной дисциплины из БКИ
     */
    paymentsStringStart?: DateOnly;
    /**
     * Остаток ссудной задолженности по кредитному обязательству
     */
    loansReceivable?: number;
    /**
     * Вид деятельности источника информации о кредитном обязательстве
     */
    businessCategory?: number;
    /**
     * Источники информации о кредитном обязательстве
     */
    infoSources?: Array<string> | null;
}

