/**
 * Fx.Spr
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AnswerInfo } from './answerInfo';
import { AnswerType } from './answerType';


export interface QuestionInfo { 
    id?: number;
    text?: string | null;
    type?: AnswerType;
    priority?: number;
    formula?: string | null;
    answers?: Array<AnswerInfo> | null;
}

