import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { AutoReportsConfiguration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { ClientIncomeReportReportsApiService } from './api/clientIncomeReport.service';
import { CreReportReportsApiService } from './api/creReport.service';
import { CreditHistoryReportReportsApiService } from './api/creditHistoryReport.service';
import { FsspReportsApiService } from './api/fssp.service';
import { PhoneVerificationEfficiencyReportsApiService } from './api/phoneVerificationEfficiency.service';
import { PhoneVerificationReportReportsApiService } from './api/phoneVerificationReport.service';
import { RiskReportsReportsApiService } from './api/riskReports.service';
import { ScoringReportsApiService } from './api/scoring.service';
import { StatusChangesReportReportsApiService } from './api/statusChangesReport.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    ClientIncomeReportReportsApiService,
    CreReportReportsApiService,
    CreditHistoryReportReportsApiService,
    FsspReportsApiService,
    PhoneVerificationEfficiencyReportsApiService,
    PhoneVerificationReportReportsApiService,
    RiskReportsReportsApiService,
    ScoringReportsApiService,
    StatusChangesReportReportsApiService ]
})
export class FxAutoReportsApiModule {
    public static forRoot(configurationFactory: () => AutoReportsConfiguration): ModuleWithProviders<FxAutoReportsApiModule> {
        return {
            ngModule: FxAutoReportsApiModule,
            providers: [ { provide: AutoReportsConfiguration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: FxAutoReportsApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('FxAutoReportsApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
