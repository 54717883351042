import { Injectable } from '@angular/core';
import { delay, Observable, of } from 'rxjs';
import { PaginatedResult } from '@lib-widgets/grid';
import { ApplicationListItemDto } from '@lib-auto/api';
import {
  autoDocumentInspectionDocumentErrors,
  autoDocumentInspectionDocumentsToCheck,
  autoDocumentInspectionMock,
  AutoListMock,
} from '../mocks';

@Injectable({ providedIn: 'root' })
export class AutoOrderService {
  getOrderList(page: number, perPage: number): Observable<PaginatedResult<ApplicationListItemDto>> {
    return of({
      data: AutoListMock,
      page: {
        pages: 10,
        items: 1000,
        page,
        perPage,
      },
    }).pipe(delay(800));
  }

  getDocumentInspectionInfo = () => {
    return of(autoDocumentInspectionMock).pipe(delay(800));
  };

  getDocumentInspectionDocumentErrors = () => {
    return of(autoDocumentInspectionDocumentErrors).pipe(delay(800));
  };

  getDocumentInspectionDocumentChecks = () => {
    return of(autoDocumentInspectionDocumentsToCheck).pipe(delay(800));
  };
}
