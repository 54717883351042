import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import ru from '@angular/common/locales/ru';
import { APP_INITIALIZER, inject, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule } from '@angular/router';
import { FormFieldService, ShowFormErrorsInterceptorModule } from '@lib-features/form-field';
import { TranslateModule } from '@ngx-translate/core';
import { TuiMobileCalendarDialogModule } from '@taiga-ui/addon-mobile';
import { TuiPreviewModule } from '@taiga-ui/addon-preview';
import { TuiAlertModule, TuiDialogModule, TuiRootModule } from '@taiga-ui/core';
import { TuiPdfViewerModule } from '@taiga-ui/kit';
import { ConfigService, InitConfigModule, TaigaUiProvidersModule } from '@lib-config';
import { ErrorInterceptorConfig, ErrorInterceptorModule, MortgageLikeErrorMapper } from '@lib-error-interceptor';
import { NullableFieldsInterceptorModule } from '@lib-interceptors';
import { checkBrowserSupport, defaultTransformErrorMessage, FeHttpError } from '@lib-utils';
import { ApplicationRootComponent, ApplicationRootModule } from '@lib-widgets/application-root';
import { FnipDialogModule } from '@lib-widgets/dialog';
import { SidebarModule } from '@lib-widgets/sidebar';
import { AutoConfiguration, FxAutoApiModule } from '@lib-auto/api';
import { AutoLookupConfiguration, FxAutoLookupApiModule } from '@lib-auto/api-lookup';
import { AutoReportsConfiguration, FxAutoReportsApiModule } from '@lib-auto/api-reports';
import { AutoSprConfiguration, FxAutoSprApiModule } from '@lib-auto/api-spr';
import { AUTO_ERROR_MESSAGES, AutoErrorMapper, AutoErrorResponse } from '@lib-auto/utils';
import { MortgageConfiguration, NotificationApiService } from '@lib-mortgage/api';
import {
  AuthorizationInterceptorModule,
  AuthorizationModule,
  createBearerCredentials,
} from '@lib-mortgage/features/authorization';
import { environment } from '../environments/environment';
import { appRoutes } from './app.routes';

registerLocaleData(ru);

const MortgageApiServices = [NotificationApiService];

const TUI_MODULES = [
  TuiRootModule,
  TuiDialogModule,
  TuiMobileCalendarDialogModule,
  TuiAlertModule,
  TuiPreviewModule, // Нет метода .forRoot(), необходим импорт модуля в руте. Следить за PreviewDialogService.open()
  TuiPdfViewerModule, // Нет метода .forRoot(), необходим импорт модуля в руте. Следить за TuiPdfViewerService.open()
];

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SidebarModule.forRoot(),
    TranslateModule.forRoot(), // Необходим для корректной работы `DefaultComponent`
    AuthorizationModule,
    RouterModule.forRoot(appRoutes, { initialNavigation: 'enabledBlocking' }),
    TUI_MODULES,
    InitConfigModule.forRoot('Автокредитование', environment.frontVersion),
    TaigaUiProvidersModule,
    FnipDialogModule,
    ApplicationRootModule,
    HttpClientModule,
    AuthorizationInterceptorModule,
    ErrorInterceptorModule.forRoot({
      messages: [AUTO_ERROR_MESSAGES],
      mappers: [AutoErrorMapper, MortgageLikeErrorMapper],
      config: {
        useFactory: (formFieldService: FormFieldService): ErrorInterceptorConfig<AutoErrorResponse> => ({
          showErrors: true,
          transformError: (error: FeHttpError<AutoErrorResponse>) =>
            defaultTransformErrorMessage(
              Array.isArray(error.error?.errors)
                ? formFieldService.getLabeledMessages(error.error.errors)
                : error.messages,
            ),
        }),
        deps: [FormFieldService],
      },
    }),
    ShowFormErrorsInterceptorModule,
    NullableFieldsInterceptorModule,
    FxAutoApiModule,
    FxAutoLookupApiModule,
    FxAutoSprApiModule,
    FxAutoReportsApiModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: checkBrowserSupport,
      deps: [Router],
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'ru' },
    {
      provide: AutoConfiguration,
      useFactory: () =>
        new AutoConfiguration({
          basePath: inject(ConfigService).autoApiUrl,
          credentials: createBearerCredentials(),
        }),
    },
    ...MortgageApiServices,
    {
      provide: MortgageConfiguration,
      useFactory: () =>
        new MortgageConfiguration({
          basePath: inject(ConfigService).apiUrl,
          credentials: createBearerCredentials(),
        }),
    },
    {
      provide: AutoSprConfiguration,
      useFactory: () =>
        new AutoSprConfiguration({
          basePath: inject(ConfigService).autoSprApiUrl,
          credentials: createBearerCredentials(),
        }),
    },
    {
      provide: AutoReportsConfiguration,
      useFactory: () =>
        new AutoReportsConfiguration({
          basePath: inject(ConfigService).autoReportsApiUrl,
          credentials: {
            ApiKey:
              'ApiKey vkLh01khXXt8HcSWDRLyvmN8Wj52LhzyT4JMnh16lPfVex54M1AjfalriqEPoYkXN0zs33f6HEZBHMKAyBB7J3DPLOtogzFzUmIxl8ap5bdRy9jcPkKhj8rcNY2NoOvc',
          },
        }),
    },
    {
      provide: AutoLookupConfiguration,
      useFactory: () =>
        new AutoLookupConfiguration({
          basePath: inject(ConfigService).autoLookupApiUrl,
          credentials: createBearerCredentials(),
        }),
    },
  ],
  bootstrap: [ApplicationRootComponent],
})
export class AppModule {}
