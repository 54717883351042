/**
 * FxAuto.BFF.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RequiredDocumentsResponseDto } from './requiredDocumentsResponseDto';
import { DealNegotiationIssueDto } from './dealNegotiationIssueDto';
import { CalculationResultDto } from './calculationResultDto';
import { CreateClientDto } from './createClientDto';
import { AdditionalProductInvoiceDto } from './additionalProductInvoiceDto';
import { PassportDto } from './passportDto';
import { DealNegotiationVehicleDto } from './dealNegotiationVehicleDto';
import { InvoiceWithOptionsDto } from './invoiceWithOptionsDto';


export interface DealNegotiationDto { 
    client?: CreateClientDto;
    passport?: PassportDto;
    vehicle?: DealNegotiationVehicleDto;
    calculationResult?: CalculationResultDto;
    residualValueInvoice?: InvoiceWithOptionsDto;
    additionalProductInvoices?: Array<AdditionalProductInvoiceDto> | null;
    documents?: RequiredDocumentsResponseDto;
    issues?: Array<DealNegotiationIssueDto> | null;
    isReadOnly?: boolean;
}

