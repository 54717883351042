import { FormControl } from '@angular/forms';
import { FileInfo, Nullable } from '@lib-utils';
import { RequiredDocumentDto } from '@lib-auto/api';

export interface DocumentControlInfo extends RequiredDocumentDto {
  control: FormControl<Nullable<FileInfo<number>[]>> | undefined;
}

export interface DocumentControlGroupDto {
  groupName: string;
  documents: DocumentControlInfo[];
}
