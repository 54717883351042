/**
 * Fx.Spr
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PositiveDecision } from './positiveDecision';
import { NegativeDecision } from './negativeDecision';
import { DecisionType } from './decisionType';


export interface Decision { 
    type?: DecisionType;
    positive?: PositiveDecision;
    negative?: NegativeDecision;
}

