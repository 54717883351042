/**
 * FxAuto.Lookup.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type CalculationResultInactiveReason = 'LoanTermIsAlreadyMax' | 'DownPaymentIsAlreadyMin' | 'AdditionalProductsValueAboveLimit' | 'AdditionalProductsValueAboveVehicleLimit' | 'CreditAmountBelowLimit' | 'CreditAmountAboveLimit' | 'CreditAmountAboveVehicleLimit' | 'LoanTermBelowLimit' | 'LoanTermAboveLimit' | 'LoanTermAboveVehicleLimit' | 'DownPaymentBelowLimit' | 'DownPaymentAboveLimit' | 'VoluntaryVehicleInsuranceRequired' | 'ValueAboveVehicleLimit' | 'LTVAboveMax' | 'CreditAmountAboveApprovedLimit' | 'ContainsNotAvailableAdditionalProducts' | 'ContainsAdditionalProductsCollisions' | 'MonthlyPaymentAboveApprovedLimit' | 'LoanTermAboveApprovedLimit';

export const CalculationResultInactiveReason = {
    LoanTermIsAlreadyMax: 'LoanTermIsAlreadyMax' as CalculationResultInactiveReason,
    DownPaymentIsAlreadyMin: 'DownPaymentIsAlreadyMin' as CalculationResultInactiveReason,
    AdditionalProductsValueAboveLimit: 'AdditionalProductsValueAboveLimit' as CalculationResultInactiveReason,
    AdditionalProductsValueAboveVehicleLimit: 'AdditionalProductsValueAboveVehicleLimit' as CalculationResultInactiveReason,
    CreditAmountBelowLimit: 'CreditAmountBelowLimit' as CalculationResultInactiveReason,
    CreditAmountAboveLimit: 'CreditAmountAboveLimit' as CalculationResultInactiveReason,
    CreditAmountAboveVehicleLimit: 'CreditAmountAboveVehicleLimit' as CalculationResultInactiveReason,
    LoanTermBelowLimit: 'LoanTermBelowLimit' as CalculationResultInactiveReason,
    LoanTermAboveLimit: 'LoanTermAboveLimit' as CalculationResultInactiveReason,
    LoanTermAboveVehicleLimit: 'LoanTermAboveVehicleLimit' as CalculationResultInactiveReason,
    DownPaymentBelowLimit: 'DownPaymentBelowLimit' as CalculationResultInactiveReason,
    DownPaymentAboveLimit: 'DownPaymentAboveLimit' as CalculationResultInactiveReason,
    VoluntaryVehicleInsuranceRequired: 'VoluntaryVehicleInsuranceRequired' as CalculationResultInactiveReason,
    ValueAboveVehicleLimit: 'ValueAboveVehicleLimit' as CalculationResultInactiveReason,
    LtvAboveMax: 'LTVAboveMax' as CalculationResultInactiveReason,
    CreditAmountAboveApprovedLimit: 'CreditAmountAboveApprovedLimit' as CalculationResultInactiveReason,
    ContainsNotAvailableAdditionalProducts: 'ContainsNotAvailableAdditionalProducts' as CalculationResultInactiveReason,
    ContainsAdditionalProductsCollisions: 'ContainsAdditionalProductsCollisions' as CalculationResultInactiveReason,
    MonthlyPaymentAboveApprovedLimit: 'MonthlyPaymentAboveApprovedLimit' as CalculationResultInactiveReason,
    LoanTermAboveApprovedLimit: 'LoanTermAboveApprovedLimit' as CalculationResultInactiveReason
};

