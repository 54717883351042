/**
 * Fx.Spr
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Информация об адресе.
 */
export interface Address { 
    /**
     * Тип адреса  1 – Адрес регистрации (только для физ.лиц);  2 – Адрес проживания (только для физ.лиц);  3 – Юридический адрес (только для юр.лиц);  4 – Фактический адрес (только для юр.лиц).
     */
    type?: string | null;
    /**
     * Населенный пункт.
     */
    location?: string | null;
    /**
     * Область.
     */
    area?: string | null;
    /**
     * Номер дома.
     */
    house?: string | null;
    /**
     * Название улицы.
     */
    street?: string | null;
    /**
     * Тип улицы.
     */
    streetType?: string | null;
    /**
     * Корпус.
     */
    block?: string | null;
    /**
     * Строение.
     */
    building?: string | null;
    /**
     * Квартира.
     */
    apartment?: string | null;
    /**
     * Город.
     */
    town?: string | null;
    /**
     * Регион.
     */
    region?: string | null;
    /**
     * Почтовый индекс.
     */
    postal?: string | null;
    /**
     * Дата начала проживания.
     */
    residenceDate?: string | null;
    /**
     * Код страны ОКСМ.
     */
    country?: string | null;
    /**
     * Наименование иной страны.
     */
    otherCountry?: string | null;
    /**
     * Номер адреса в ФИАС.
     */
    fiasNum?: string | null;
    /**
     * Код населенного пункта по ОКАТО.
     */
    locationCode?: string | null;
    /**
     * Иной населенный пункт.
     */
    otherLocation?: string | null;
    /**
     * Владение.
     */
    domain?: string | null;
    /**
     * Дата регистрации.
     */
    regDate?: string | null;
    /**
     * Наименование регистрирующего органа.
     */
    regAuthority?: string | null;
    /**
     * Код подразделения, осуществившего регистрацию.
     */
    regAuthorityCode?: string | null;
    /**
     * Код адреса регистрации.
     */
    regCode?: number;
}

