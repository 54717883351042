/**
 * Fx.Spr
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * <p>Type: <b>FxAuto.Spr.Domain.Enums.Obligations.ClientType</b></p><p>Members:</p> <ul> <p><li><i>PrimaryBorrower</i> - Основной заемщик</li></p> <p><li><i>Coborrower</i> - Совместный</li></p> <p><li><i>Guarantor</i> - Поручитель</li></p> <p><li><i>LegalEntity</i> - Юридическое лицо</li></p> </ul> 
 */
export type ClientType = 'PrimaryBorrower' | 'Coborrower' | 'Guarantor' | 'LegalEntity';

export const ClientType = {
    PrimaryBorrower: 'PrimaryBorrower' as ClientType,
    Coborrower: 'Coborrower' as ClientType,
    Guarantor: 'Guarantor' as ClientType,
    LegalEntity: 'LegalEntity' as ClientType
};

