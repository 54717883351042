import { FromGeneratedEnum, Nullable } from '@lib-utils';
import { ApplicationListStatus, ApplicationListStatusInfoDto } from '@lib-auto/api';

export function getApplicationListStatusMap(
  listStatuses: Nullable<ApplicationListStatusInfoDto[]>,
): FromGeneratedEnum<ApplicationListStatus> {
  const result: FromGeneratedEnum<ApplicationListStatus> = {};
  listStatuses?.forEach((statusInfo) => (result[statusInfo.type!] = statusInfo.name!));
  return result;
}
