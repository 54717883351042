/**
 * FxAuto.BFF.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { VerificationDocumentType } from './verificationDocumentType';
import { VerificationPassportDto } from './verificationPassportDto';
import { VerificationDriverLicenseDto } from './verificationDriverLicenseDto';
import { VerificationSaleContractVehicleDto } from './verificationSaleContractVehicleDto';
import { VerificationVehiclePassportDto } from './verificationVehiclePassportDto';
import { VerificationPaymentReceiptDto } from './verificationPaymentReceiptDto';
import { VerificationSopdDto } from './verificationSopdDto';
import { VerificationVehicleInformationLetterDto } from './verificationVehicleInformationLetterDto';
import { VerificationPaymentInvoiceDto } from './verificationPaymentInvoiceDto';


export interface VerificationItemDto { 
    documentType?: VerificationDocumentType;
    documentKey?: string | null;
    clientId?: string | null;
    passport?: VerificationPassportDto;
    driverLicense?: VerificationDriverLicenseDto;
    vehiclePassport?: VerificationVehiclePassportDto;
    vehicleInformationLetter?: VerificationVehicleInformationLetterDto;
    sopd?: VerificationSopdDto;
    paymentInvoice?: VerificationPaymentInvoiceDto;
    paymentReceipt?: VerificationPaymentReceiptDto;
    saleContractVehicle?: VerificationSaleContractVehicleDto;
}
export namespace VerificationItemDto {
}


