/**
 * FxAuto.BFF.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DateOnly } from '@lib-utils';


export interface VerificationPaymentInvoiceDto { 
    recipientBic?: string | null;
    recipientName?: string | null;
    taxNumber?: string | null;
    amount?: number;
    isVat?: boolean | null;
    vatAmount?: number | null;
    recipientAccount?: string | null;
    invoiceNumber?: string | null;
    invoiceDate?: DateOnly | null;
}

