/**
 * Fx.Spr
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ClientData } from './clientData';
import { VehicleType } from './vehicleType';
import { DateTime } from '@lib-utils';


export interface CreateApplicationRequest { 
    externalApplicationId?: string | null;
    rate?: number;
    originInitialPayment?: number;
    initialPayment?: number;
    vehiclePrice?: number;
    vehicleType?: VehicleType;
    requestedAmount?: number;
    maxSum?: number;
    minSum?: number;
    maxTerm?: number;
    minRate?: number;
    subProduct?: string | null;
    date?: DateTime;
    client?: ClientData;
}

