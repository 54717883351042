import { AutoAgentDto } from './auto-agent';

export interface AutoAgentChangesDto {
  id: number;
  dealerId: number;
  dealerName: string;
  agentId: number;
  current: AutoAgentDto;
  requested: AutoAgentDto;
  author: string;
  requestedDate: string;
  comment?: string;
}

export interface AutoAgentChangesListDto {
  id: number;
  dealerName: string;
  dealerId: number;
  agentId: number;
  author: string;
  requestedDate: string;
}
