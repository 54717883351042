/**
 * FxAuto.BFF.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AdditionalProductType } from './additionalProductType';


export interface AdditionalProductOptionDto { 
    type?: AdditionalProductType;
    name?: string | null;
    nameContract?: string | null;
    price?: number | null;
    isAddedByDefault?: boolean;
    providerId?: number;
    providerName?: string | null;
    disallowCombineWithType?: AdditionalProductType;
}
export namespace AdditionalProductOptionDto {
}


