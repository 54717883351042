import { AdminStatus, AutoAgentDto, AutoDealerOrganizationManagerDto, AutoDealerRequisitesDto } from '../models';

export interface AutoDealerOrganizationDto {
  id?: number;
  status?: AdminStatus;
  type?: AutoOrganisationType;
  opf?: AutoOpfType;
  name?: string;
  kpp?: string;
  accountNumber?: string;
  address?: string;
  phone?: string;
  email?: string;
  accreditationDecision?: AutoAccreditationDecisionType;
  agreementDate?: string;
  agreementNumber?: string;
  agreementCheckDate?: string;
  agreementCheckResult?: string;
  manager?: AutoDealerOrganizationManagerDto;
  contactPersonFullName?: string;
  contactPersonPhone?: string;
  contactPersonEmail?: string;
  dealerName?: string;
  curatorName?: string;
  issue?: string;
  taxNumber?: string;
  requisites?: AutoDealerRequisitesDto[];
  agents?: AutoAgentDto[];
  agentsKB?: AutoAgentDto[];
}

export const autoDealerOrganisationKeyMap: Partial<{ [K in keyof AutoDealerOrganizationDto]: string }> = {
  address: 'Адрес',
  taxNumber: 'ИНН',
};

//#region AutoOrganisationType

export const AutoOrganisationType = {
  Organization: 'Organization',
  SoleProprietor: 'SoleProprietor',
} as const;

export type AutoOrganisationType = (typeof AutoOrganisationType)[keyof typeof AutoOrganisationType];

export const AutoOrganisationTypeMap: Record<AutoOrganisationType, string> = {
  [AutoOrganisationType.Organization]: 'Юр. лицо',
  [AutoOrganisationType.SoleProprietor]: 'ИП',
};

//#endregion AutoOrganisationType

//#region AutoOpfType

export const AutoOpfType = {
  Active: 'Active',
  Inactive: 'Inactive',
} as const;

export type AutoOpfType = (typeof AutoOpfType)[keyof typeof AutoOpfType];

export const AutoOpfTypeMap: Record<AutoOpfType, string> = {
  [AutoOpfType.Active]: 'Активный',
  [AutoOpfType.Inactive]: 'Неактивный',
};

//#endregion AutoOpfType

//#region AutoAccreditationDecisionType

export const AutoAccreditationDecisionType = {
  Accredited: 'Accredited',
  AccreditedWithCondition: 'AccreditedWithCondition',
  NotAccredited: 'NotAccredited',
  Other: 'Other',
} as const;

export type AutoAccreditationDecisionType =
  (typeof AutoAccreditationDecisionType)[keyof typeof AutoAccreditationDecisionType];

export const AutoAccreditationDecisionTypeMap: Record<AutoAccreditationDecisionType, string> = {
  [AutoAccreditationDecisionType.Accredited]: 'Аккредитована',
  [AutoAccreditationDecisionType.AccreditedWithCondition]: 'Аккредитована с условиями',
  [AutoAccreditationDecisionType.NotAccredited]: 'Не аккредитована',
  [AutoAccreditationDecisionType.Other]: 'Другое',
};

//#endregion AutoAccreditationDecisionType
