/**
 * FxAuto.BFF.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ApplicationDecisionStage } from './applicationDecisionStage';
import { ApplicationListStatus } from './applicationListStatus';
import { DateOnlyRangeDto } from './dateOnlyRangeDto';
import { ApplicationStatus } from './applicationStatus';


export interface ApplicationListFiltersDto { 
    query?: string | null;
    createdBetween?: DateOnlyRangeDto;
    updatedBetween?: DateOnlyRangeDto;
    creatorUsers?: Array<number> | null;
    dealers?: Array<number> | null;
    showrooms?: Array<number> | null;
    statuses?: Array<ApplicationStatus> | null;
    status?: ApplicationListStatus;
    decisionStage?: ApplicationDecisionStage;
    bankDepartmentId?: number | null;
}
export namespace ApplicationListFiltersDto {
}


