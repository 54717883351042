import { ApplicationModulesMeta } from '@lib-mortgage/widgets/default';

export const ApplicationModules = {
  Applications: 'applications',
  Admin: 'admin',
  Risks: 'risks',
  Inspector: 'inspector',
} as const;

export type ApplicationModules = (typeof ApplicationModules)[keyof typeof ApplicationModules];

export const ApplicationModulesMetaMap: Record<ApplicationModules, ApplicationModulesMeta> = {
  [ApplicationModules.Applications]: {
    name: 'Кабинет заявок',
    link: 'applications',
    description: 'Кабинет заявок',
    permissionOperation: 'AutoModuleAvailable',
  },
  [ApplicationModules.Risks]: {
    name: 'Кабинет менеджера рисков',
    link: 'risks',
    description: 'Кабинет менеджера рисков',
    permissionOperation: 'AutoRiskModuleAvailable',
  },
  [ApplicationModules.Inspector]: {
    name: 'Кабинет инспектора',
    link: 'inspector',
    description: 'Кабинет инспектора',
    permissionOperation: 'AutoInspectorModuleAvailable',
  },
  [ApplicationModules.Admin]: {
    name: 'Кабинет администратора',
    link: 'admin',
    description: 'Кабинет администратора',
    permissionOperation: 'AutoAdminModuleAvailable',
  },
};
