import { FromGeneratedEnum } from '@lib-utils';
import { EmployeeRelationType, EmploymentType } from '@lib-auto/api';

export const EmploymentTypeMap: FromGeneratedEnum<EmploymentType> = {
  [EmploymentType.Employee]: 'Работа по найму',
  [EmploymentType.Military]: 'Военный',
  [EmploymentType.Entrepreneur]: 'ИП',
};

export const EmployeeRelationTypeMap: FromGeneratedEnum<EmployeeRelationType> = {
  [EmployeeRelationType.Colleague]: 'Коллега',
  [EmployeeRelationType.Head]: 'Руководитель',
  [EmployeeRelationType.Subordinate]: 'Подчиненный',
};
