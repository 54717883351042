import { AdminStatus, AutoDealerOrganizationDto, AutoDealerShowroomDto } from '../models';

export interface AutoDealerRequisitesDto {
  id?: number;
  status?: AdminStatus;
  type?: AutoRequisitesType;
  payeeName?: string;
  bankName?: string;
  issue?: string;
  accountNumber?: string;
  bik?: string;
  bankDepartmentAddress?: string;
  organizations?: AutoDealerOrganizationDto[];
  showrooms?: AutoDealerShowroomDto[];
}

export const autoDealerRequisitesKeyMap: Partial<{ [K in keyof AutoDealerRequisitesDto]: string }> = {
  accountNumber: 'Р/С',
  bik: 'БИК',
};

//#region AutoRequisitesType

export const AutoRequisitesType = {
  Organization: 'Organization',
  SoleProprietor: 'SoleProprietor',
} as const;

export type AutoRequisitesType = (typeof AutoRequisitesType)[keyof typeof AutoRequisitesType];

export const AutoRequisitesTypeMap: Record<AutoRequisitesType, string> = {
  [AutoRequisitesType.Organization]: 'Юр. лицо',
  [AutoRequisitesType.SoleProprietor]: 'ИП',
};

//#endregion AutoRequisitesType
