/**
 * Fx.Spr
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * <p>Type: <b>FxAuto.Spr.Domain.Enums.CreditReportSourceType</b></p><p>Members:</p> <ul> </ul> 
 */
export type CreditReportSourceType = 'Okb' | 'Nbki' | 'Equifax';

export const CreditReportSourceType = {
    Okb: 'Okb' as CreditReportSourceType,
    Nbki: 'Nbki' as CreditReportSourceType,
    Equifax: 'Equifax' as CreditReportSourceType
};

