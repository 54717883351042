/**
 * Fx.Spr
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Extension } from './extension';


/**
 * Сведения о работодателе.
 */
export interface Employment { 
    /**
     * Название организации.
     */
    name?: string | null;
    /**
     * Код сферы деятельности.
     */
    occupationTrade?: string | null;
    /**
     * Код типа занятости.
     */
    occupationStatus?: string | null;
    /**
     * Вид деятельности.
     */
    occupationCode?: string | null;
    /**
     * Дата начала работы.
     */
    startDate?: string | null;
    /**
     * Дата окончания работы.
     */
    endDate?: string | null;
    /**
     * Флаг текущее/бывшее место работы.
     */
    current?: number | null;
    extension?: Extension;
}

