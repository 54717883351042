import { Nullable } from '@lib-utils';
import { AdditionalProductType } from '@lib-auto/api';
import { ConditionType } from './auto-condition-type';
import { RateType } from './auto-rate-type';

export interface AdditionalProductConditionDto {
  additionalProduct?: Nullable<AdditionalProductType>;
  result?: Nullable<number>;
  resultType?: Nullable<RateType>;
  conditionType?: Nullable<ConditionType>;
}
