/**
 * Fx.Spr
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * <p>Type: <b>FxAuto.Spr.Domain.Enums.GenderType</b></p><p>Members:</p> <ul> </ul> 
 */
export type GenderType = 'Male' | 'Female';

export const GenderType = {
    Male: 'Male' as GenderType,
    Female: 'Female' as GenderType
};

