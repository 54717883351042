import { maskitoTransform } from '@maskito/core';
import { ACCOUNT_NUMBER, Nullable } from '@lib-utils';

/**
 * Форматирует номер счета по маске
 * @param accountNumber номер счёта
 * @returns
 */
export function formatAccountNumber(accountNumber: Nullable<string>) {
  return accountNumber && maskitoTransform(accountNumber, ACCOUNT_NUMBER);
}
