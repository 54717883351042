/**
 * FxAuto.BFF.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface InvoiceExtendedDto { 
    payeeName?: string | null;
    payeeInn?: string | null;
    payeeKpp?: string | null;
    bik?: string | null;
    bankName?: string | null;
    accountNumber?: string | null;
    correspondentAccountNumber?: string | null;
    amount?: number;
}

