/**
 * FxAuto.Lookup.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CalculateRequestLimitsDto { 
    loanLimit?: number | null;
    monthlyPaymentLimit?: number | null;
    termLimit?: number | null;
    vehicleLoanLimit?: number | null;
    vehicleValueLimit?: number | null;
    vehicleTermLimit?: number | null;
    vehicleAdditionalProductsValueLimit?: number | null;
    rateCorrection?: number | null;
}

