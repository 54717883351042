/**
 * FxAuto.Lookup.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type CalculationResultType = 'Requested' | 'ReducedDownPayment' | 'IncreasedLoanTerm';

export const CalculationResultType = {
    Requested: 'Requested' as CalculationResultType,
    ReducedDownPayment: 'ReducedDownPayment' as CalculationResultType,
    IncreasedLoanTerm: 'IncreasedLoanTerm' as CalculationResultType
};

