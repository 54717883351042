/**
 * Fx.Spr
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Данные о телфонном номере.
 */
export interface Phone { 
    /**
     * Тип номера телефона.
     */
    phoneTypeProperty?: number | null;
    /**
     * Номер телефона.
     */
    num?: string | null;
    /**
     * Комментарий к тлф.
     */
    comment?: string | null;
    /**
     * Электронная почта.
     */
    email?: string | null;
}

