/**
 * Fx.Spr
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Extension } from './extension';
import { DateTime } from '@lib-utils';


/**
 * Среднемесячный платеж.
 */
export interface MedianPayment { 
    /**
     * Уникальный идентификатор кредитного договора.
     */
    uuid?: string | null;
    /**
     * Номер ОГРН БКИ, вернувшего информацию о среднемесячных платежах.
     */
    bchOgrn?: string | null;
    /**
     * Валюта среднемесячного платежа.
     */
    currency?: string | null;
    /**
     * Дата расчета среднемесячного платежа.
     */
    calculationDate?: DateTime | null;
    /**
     * Значение среднемесячного платежа.
     */
    amount?: number | null;
    /**
     * Номер ОГРН квалифицированного БКИ, в которое делался запрос.
     */
    qbchOgrn?: string | null;
    /**
     * Описание ошибки.
     */
    error?: string | null;
    /**
     * Код ошибки.
     */
    errorCode?: string | null;
    /**
     * Источники данных.
     */
    infoSources?: Array<string> | null;
    extension?: Extension;
    /**
     * Признак наличия ошибки.
     */
    readonly hasError?: boolean;
}

