import { FromGeneratedEnum } from '@lib-utils';
import { DocumentType } from '@lib-auto/api';

export const DocumentTypeName: FromGeneratedEnum<DocumentType> = {
  [DocumentType.ClientPassport]: 'Паспорт',
  [DocumentType.DriverLicense]: 'Водительское удостоверение',
  [DocumentType.VehiclePassport]: 'ПТС',
  [DocumentType.Sopd]: 'Согл. на обр. перс. данных',
  [DocumentType.ClientPhoto]: 'Фотография заёмщика',
  [DocumentType.VehicleSaleContract]: 'Договор купли-продажи ТС',
  [DocumentType.DownPaymentReceipt]: 'Квитанция об оплате ПВ',
  [DocumentType.VehiclePaymentInvoice]: 'Счёт на оплату ТС',
  [DocumentType.ComprehensiveServiceAgreement]: 'Договор комплексного обслуживания (ДКО)',
  [DocumentType.IndividualConditions]: 'Индивидуальные условия',
  [DocumentType.LoanApplication]: 'Анкета-заявка и согласия',
  [DocumentType.PaymentSchedule]: 'График платежей',
};
