/**
 * FxAuto.BFF.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EmploymentTypeInfoDto } from './employmentTypeInfoDto';
import { IdNameDto } from './idNameDto';
import { EmployeeRelationTypeInfoDto } from './employeeRelationTypeInfoDto';


export interface EmploymentOptionsDto { 
    qualifications?: Array<IdNameDto> | null;
    professions?: Array<IdNameDto> | null;
    employmentTypes?: Array<EmploymentTypeInfoDto> | null;
    employeeRelationTypes?: Array<EmployeeRelationTypeInfoDto> | null;
}

