/**
 * Fx.Spr
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type CallingConventions = 'Standard' | 'VarArgs' | 'Any' | 'HasThis' | 'ExplicitThis';

export const CallingConventions = {
    Standard: 'Standard' as CallingConventions,
    VarArgs: 'VarArgs' as CallingConventions,
    Any: 'Any' as CallingConventions,
    HasThis: 'HasThis' as CallingConventions,
    ExplicitThis: 'ExplicitThis' as CallingConventions
};

