import { AutoDocumentInspectionDocumentType } from './auto-document-inspection-document-type';

export interface AutoDocumentInspectionErrorDetailsDto {
  message: string;
  authorFullName: string;
  date: string;
}

export interface AutoDocumentInspectionErrorDto {
  documentType: AutoDocumentInspectionDocumentType;
  documents: {
    documentName: string;
    errors: AutoDocumentInspectionErrorDetailsDto[];
  }[];
}
