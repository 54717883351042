export * from './clientIncomeReport.service';
import { ClientIncomeReportReportsApiService } from './clientIncomeReport.service';
export * from './creReport.service';
import { CreReportReportsApiService } from './creReport.service';
export * from './creditHistoryReport.service';
import { CreditHistoryReportReportsApiService } from './creditHistoryReport.service';
export * from './fssp.service';
import { FsspReportsApiService } from './fssp.service';
export * from './phoneVerificationEfficiency.service';
import { PhoneVerificationEfficiencyReportsApiService } from './phoneVerificationEfficiency.service';
export * from './phoneVerificationReport.service';
import { PhoneVerificationReportReportsApiService } from './phoneVerificationReport.service';
export * from './riskReports.service';
import { RiskReportsReportsApiService } from './riskReports.service';
export * from './scoring.service';
import { ScoringReportsApiService } from './scoring.service';
export * from './statusChangesReport.service';
import { StatusChangesReportReportsApiService } from './statusChangesReport.service';
export const APIS = [ClientIncomeReportReportsApiService, CreReportReportsApiService, CreditHistoryReportReportsApiService, FsspReportsApiService, PhoneVerificationEfficiencyReportsApiService, PhoneVerificationReportReportsApiService, RiskReportsReportsApiService, ScoringReportsApiService, StatusChangesReportReportsApiService];
