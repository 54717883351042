/**
 * FxAuto.BFF.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ApplicationListStatus = 'Draft' | 'DecisionAwaiting' | 'RevisionRequired' | 'Approved' | 'Deal' | 'LoanIssued' | 'LoanRepaid' | 'Declined' | 'Outdated';

export const ApplicationListStatus = {
    Draft: 'Draft' as ApplicationListStatus,
    DecisionAwaiting: 'DecisionAwaiting' as ApplicationListStatus,
    RevisionRequired: 'RevisionRequired' as ApplicationListStatus,
    Approved: 'Approved' as ApplicationListStatus,
    Deal: 'Deal' as ApplicationListStatus,
    LoanIssued: 'LoanIssued' as ApplicationListStatus,
    LoanRepaid: 'LoanRepaid' as ApplicationListStatus,
    Declined: 'Declined' as ApplicationListStatus,
    Outdated: 'Outdated' as ApplicationListStatus
};

