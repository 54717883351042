import { FromGeneratedEnum } from '@lib-utils';
import { ClientRefusedReasonType } from '@lib-auto/api';

export const ClientRefusedReasonTypeMap: FromGeneratedEnum<ClientRefusedReasonType> = {
  [ClientRefusedReasonType.FoundBetterConditionsAtAnotherBank]: 'Нашел в другом банке лучшие условия',
  [ClientRefusedReasonType.ChangedMyMindAboutTakingOutLoan]: 'Передумал брать кредит вообще',
  [ClientRefusedReasonType.AvoidedAnswering]: 'Уклонился от ответа',
  [ClientRefusedReasonType.AnotherReason]: 'Другая причина',
  [ClientRefusedReasonType.DuplicateApplication]: 'Дублирующая заявка',
};
