/**
 * FxAuto.BFF.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DocumentGroupType } from './documentGroupType';
import { IDocumentFile } from './iDocumentFile';
import { DocumentKeyDto } from './documentKeyDto';


export interface RequiredDocumentDto { 
    from?: number;
    to?: number;
    name?: string | null;
    groupName?: string | null;
    documentKey?: DocumentKeyDto;
    groupType?: DocumentGroupType;
    existing?: Array<IDocumentFile> | null;
    isReadOnly?: boolean;
}
export namespace RequiredDocumentDto {
}


