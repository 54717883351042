export * from './adminApplication.service';
import { AdminApplicationApiService } from './adminApplication.service';
export * from './adminImport.service';
import { AdminImportApiService } from './adminImport.service';
export * from './application.service';
import { ApplicationApiService } from './application.service';
export * from './applicationCreation.service';
import { ApplicationCreationApiService } from './applicationCreation.service';
export * from './applicationDecision.service';
import { ApplicationDecisionApiService } from './applicationDecision.service';
export * from './applicationDocument.service';
import { ApplicationDocumentApiService } from './applicationDocument.service';
export * from './applicationFile.service';
import { ApplicationFileApiService } from './applicationFile.service';
export * from './applicationHistory.service';
import { ApplicationHistoryApiService } from './applicationHistory.service';
export * from './applicationList.service';
import { ApplicationListApiService } from './applicationList.service';
export * from './callback.service';
import { CallbackApiService } from './callback.service';
export * from './deal.service';
import { DealApiService } from './deal.service';
export * from './dealNegotiation.service';
import { DealNegotiationApiService } from './dealNegotiation.service';
export * from './loanIssued.service';
import { LoanIssuedApiService } from './loanIssued.service';
export * from './lookupAddress.service';
import { LookupAddressApiService } from './lookupAddress.service';
export * from './lookupCalculator.service';
import { LookupCalculatorApiService } from './lookupCalculator.service';
export * from './lookupOrganization.service';
import { LookupOrganizationApiService } from './lookupOrganization.service';
export * from './lookupVehicle.service';
import { LookupVehicleApiService } from './lookupVehicle.service';
export * from './template.service';
import { TemplateApiService } from './template.service';
export const APIS = [AdminApplicationApiService, AdminImportApiService, ApplicationApiService, ApplicationCreationApiService, ApplicationDecisionApiService, ApplicationDocumentApiService, ApplicationFileApiService, ApplicationHistoryApiService, ApplicationListApiService, CallbackApiService, DealApiService, DealNegotiationApiService, LoanIssuedApiService, LookupAddressApiService, LookupCalculatorApiService, LookupOrganizationApiService, LookupVehicleApiService, TemplateApiService];
