import { AdditionalProductConditionDto } from './auto-additional-product-condition';
import { BaseRateDto } from './auto-base-rate';
import { CftInfoDto } from './auto-cft-info';
import { AutoParameterConditionDto } from './auto-parameter-condition';
import { RenderedServicesActDto } from './auto-rendered-services-act';
import { RewardRecepientDto } from './auto-reward-recepient';
import { AutoSellCreditConditionsDto } from './auto-sell-credit-condition';

export interface AutoRewardDto {
  rewardPeriod?: number;
  reward?: number;
  baseRate?: BaseRateDto;
  sellCreditConditions?: AutoSellCreditConditionsDto;
  autoParameterConditions?: AutoParameterConditionDto[];
  additionalProductConditions?: AdditionalProductConditionDto[];
  rewardRecepient?: RewardRecepientDto;
  compensateKv?: boolean;
  renderedServicesActInfo?: RenderedServicesActDto;
  cftInfo?: CftInfoDto;
}
