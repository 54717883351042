import isNil from 'lodash-es/isNil';
import mapValues from 'lodash-es/mapValues';

/**
 * Преобразует null | '' | undefined поля в null
 * @param body
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function transformNullableFields(body: any): any {
  if (isNil(body) || body === '') return null;
  // FormData при преобразовании как object становится пустым объектом
  if (body instanceof FormData) return body;
  if (body instanceof Array) {
    return body.map((e) => transformNullableFields(e));
  }
  if (typeof body === 'object') {
    return mapValues(body, (property) => transformNullableFields(property));
  }
  return body;
}
