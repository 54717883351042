export const DealerType = {
  Official: 'official',
  Unofficial: 'unofficial',
  ChinaOfficial: 'china_official',
} as const;

export type DealerType = (typeof DealerType)[keyof typeof DealerType];

export const DealerTypeMap: Record<DealerType, string> = {
  [DealerType.Official]: 'Официальный',
  [DealerType.Unofficial]: 'Неофициальный',
  [DealerType.ChinaOfficial]: 'Официальный дилер китайского авто',
};
