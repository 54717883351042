import {
  ChangeDetectorRef,
  Directive,
  effect,
  ElementRef,
  inject,
  input,
  OnDestroy,
  ViewContainerRef,
} from '@angular/core';
import { AbstractControl, FormControlDirective } from '@angular/forms';
import { Nullable } from '@lib-utils';
import { FormContainerDirective } from './form-container.directive';
import { FormFieldService } from './form-field.service';

@Directive({
  selector: '[fnipFormField]',
  standalone: true,
})
export class FormFieldDirective implements OnDestroy {
  fnipFormField = input.required<string>();
  formFieldLabel = input<Nullable<string>>(undefined, { alias: 'label' });
  formFieldControl = input<Nullable<AbstractControl>>(undefined, { alias: 'control' });

  #formFieldService = inject(FormFieldService);
  #elementRef = inject(ElementRef);
  #cdr = inject(ChangeDetectorRef);
  #viewContainerRef = inject(ViewContainerRef);
  #parentContainer = inject(FormContainerDirective, { optional: true });

  constructor() {
    effect(() => {
      const id = this.fnipFormField();
      this.#formFieldService.addField({
        id,
        parentId: this.#parentContainer?.fnipFormContainer(),
        label: this.formFieldLabel(),
        control: this.formFieldControl() ?? this.#viewContainerRef.injector.get(FormControlDirective, null)?.control,
        elementRef: this.#elementRef,
        cdr: this.#cdr,
      });
    });
  }

  ngOnDestroy(): void {
    const field = this.fnipFormField();
    if (field) this.#formFieldService.removeField(field);
  }
}
