import { PaginatedResult } from '@lib-widgets/grid';
import { ApplicationListItemDto, ApplicationListItemDtoPaginatedList } from '@lib-auto/api';

export function toPaginatedResult(data: ApplicationListItemDtoPaginatedList): PaginatedResult<ApplicationListItemDto> {
  return {
    data: data.items,
    page: {
      page: data.pageIndex,
      perPage: data.pageSize,
      pages: data.totalPages,
      items: data.totalItems,
    },
  };
}
