import { AutoDocumentInspectionDocumentType } from './auto-document-inspection-document-type';
import { AutoDocumentInspectionErrorDetailsDto } from './auto-document-inspection-error';

export interface AutoDocumentInspectionDocumentsToCheckDto {
  documentType: AutoDocumentInspectionDocumentType;
  documents: {
    name: string;
    pages: number;

    isChecked: boolean;

    errors: AutoDocumentInspectionErrorDetailsDto[];
  }[];
}
