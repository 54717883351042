export * from './auto.service';
import { AutoSprApiService } from './auto.service';
export * from './check.service';
import { CheckSprApiService } from './check.service';
export * from './checkVehicle.service';
import { CheckVehicleSprApiService } from './checkVehicle.service';
export * from './creditReport.service';
import { CreditReportSprApiService } from './creditReport.service';
export * from './import.service';
import { ImportSprApiService } from './import.service';
export * from './pdn.service';
import { PdnSprApiService } from './pdn.service';
export * from './stages.service';
import { StagesSprApiService } from './stages.service';
export * from './verification.service';
import { VerificationSprApiService } from './verification.service';
export const APIS = [AutoSprApiService, CheckSprApiService, CheckVehicleSprApiService, CreditReportSprApiService, ImportSprApiService, PdnSprApiService, StagesSprApiService, VerificationSprApiService];
