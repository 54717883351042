/**
 * FxAuto.BFF.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EngineType } from './engineType';
import { VehicleCategory } from './vehicleCategory';


export interface VehicleInfoDetailsDto { 
    makeId?: number;
    modelId?: number;
    vin?: string | null;
    passportNumber?: string | null;
    isDuplicate?: boolean | null;
    year?: number | null;
    engineType?: EngineType;
    engineDisplacement?: number | null;
    engineNumbers?: string | null;
    enginePowers?: Array<number> | null;
    vehicleCategory?: VehicleCategory;
}
export namespace VehicleInfoDetailsDto {
}


