import { FromGeneratedEnum } from '@lib-utils';
import { ApplicationStageType } from '@lib-auto/api';

export const AutoAdditionalSteps = {
  DocumentInspection: 'DocumentInspection',
} as const;

export const ApplicationStageSteps = {
  ...ApplicationStageType,
  ...AutoAdditionalSteps,
} as const;

export type ApplicationStageSteps = (typeof ApplicationStageSteps)[keyof typeof ApplicationStageSteps];
ApplicationStageType;

export const ApplicationStageStepsOrder: ApplicationStageSteps[] = [
  ApplicationStageType.GeneralInfo,
  ApplicationStageType.PassportData,
  ApplicationStageType.IncomeData,
  ApplicationStageType.AdditionalInfo,
  ApplicationStageType.LoanData,
  ApplicationStageType.DocumentsCollection,
  ApplicationStageType.Decision,
  ApplicationStageType.DealNegotiation,
  ApplicationStageType.Deal,
  AutoAdditionalSteps.DocumentInspection,
];

export const ApplicationStageStepUrl: FromGeneratedEnum<ApplicationStageSteps> = {
  [ApplicationStageType.GeneralInfo]: 'generalInfo',
  [ApplicationStageType.PassportData]: 'passport',
  [ApplicationStageType.IncomeData]: 'income',
  [ApplicationStageType.AdditionalInfo]: 'additionalInfo',
  [ApplicationStageType.LoanData]: 'creditParams',
  [ApplicationStageType.DocumentsCollection]: 'docsCollection',
  [ApplicationStageType.Decision]: 'decision',
  [ApplicationStageType.DealNegotiation]: 'dealApproval',
  [ApplicationStageType.Deal]: 'deal',
  [AutoAdditionalSteps.DocumentInspection]: 'document-inspection',
  [ApplicationStageType.LoanIssued]: 'creditIssued',
};

export const ApplicationStageStepsMap: FromGeneratedEnum<ApplicationStageSteps> = {
  [ApplicationStageType.GeneralInfo]: 'Общие сведения',
  [ApplicationStageType.PassportData]: 'Паспортные данные',
  [ApplicationStageType.IncomeData]: 'Работа',
  [ApplicationStageType.AdditionalInfo]: 'Доп. информация',
  [ApplicationStageType.LoanData]: 'Параметры кредита',
  [ApplicationStageType.DocumentsCollection]: 'Сбор документов',
  [ApplicationStageType.Decision]: 'Решение',
  [ApplicationStageType.DealNegotiation]: 'Согласование сделки',
  [ApplicationStageType.Deal]: 'Сделка',
  [AutoAdditionalSteps.DocumentInspection]: 'Испр. документов',
  [ApplicationStageType.LoanIssued]: 'Кредит',
};
