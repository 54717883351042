import { ApplicationDataIssueType, ApplicationStageType } from '@lib-auto/api';

export const DataIssueTypeStepMap = {
  [ApplicationDataIssueType.GeneralInfo]: ApplicationStageType.GeneralInfo,
  [ApplicationDataIssueType.Passport]: ApplicationStageType.PassportData,
  [ApplicationDataIssueType.Education]: ApplicationStageType.AdditionalInfo,
  [ApplicationDataIssueType.DriverLicense]: ApplicationStageType.AdditionalInfo,
  [ApplicationDataIssueType.Family]: ApplicationStageType.AdditionalInfo,
  [ApplicationDataIssueType.Income]: ApplicationStageType.IncomeData,
  [ApplicationDataIssueType.LoanData]: ApplicationStageType.LoanData,
  [ApplicationDataIssueType.Documents]: ApplicationStageType.DocumentsCollection,
} as Record<ApplicationDataIssueType, ApplicationStageType>;
