/**
 * FxAuto.BFF.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PhoneNumberDto } from './phoneNumberDto';
import { EmployeeRelationType } from './employeeRelationType';
import { PhoneNumberExtendedDto } from './phoneNumberExtendedDto';
import { EmploymentType } from './employmentType';


export interface IncomeDto { 
    monthIncome?: number;
    employmentType?: EmploymentType;
    organizationId?: number;
    qualificationId?: number | null;
    professionId?: number | null;
    employmentYear?: number;
    employmentMonth?: number | null;
    employeeRelationType?: EmployeeRelationType;
    employeeFullName?: string | null;
    organizationPhoneNumbers?: Array<PhoneNumberDto> | null;
    employeePhoneNumbers?: Array<PhoneNumberExtendedDto> | null;
}
export namespace IncomeDto {
}


