export const AutoInsurance = {
  Support: 'Support',
  Telemedicine: 'Telemedicine',
} as const;

export type AutoInsurance = (typeof AutoInsurance)[keyof typeof AutoInsurance];

export const AutoInsuranceMap: Record<AutoInsurance, string> = {
  [AutoInsurance.Support]: 'Юридическое и страховое сопровождение',
  [AutoInsurance.Telemedicine]: 'Телемедицина',
};
