import { Nullable } from '@lib-utils';
import { OrganizationBranchPaymentInfoDto } from '@lib-auto/api';
import { FeBankAccountDto } from '@lib-auto/datasource';

export function getBankAccountOptions(options: Nullable<OrganizationBranchPaymentInfoDto[]>): FeBankAccountDto[] {
  return (
    options?.flatMap((option) =>
      option.bankAccounts?.length
        ? option.bankAccounts.map((bank) => ({
            payeeInn: option.inn,
            payeeName: option.name,
            payeeKpp: option.kpp,
            ...bank,
          }))
        : [
            {
              payeeInn: option.inn,
              payeeName: option.name,
              payeeKpp: option.kpp,
            },
          ],
    ) || []
  );
}
