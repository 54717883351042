/**
 * FxAuto.BFF.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type DocumentType = 'ClientPassport' | 'DriverLicense' | 'SOPD' | 'ClientPhoto' | 'VehiclePassport' | 'VehicleInformationLetter' | 'VehicleSaleContract' | 'DownPaymentReceipt' | 'VehiclePaymentInvoice' | 'IndividualConditions' | 'PaymentSchedule' | 'LoanApplication' | 'ComprehensiveServiceAgreement' | 'TransferForVehicle' | 'AdditionalProductTransferApplication' | 'AdditionalProductContract' | 'RefusalNotice' | 'SOPDThirdParties' | 'AdditionalProductPaymentInvoice' | 'ClientPassportFull';

export const DocumentType = {
    ClientPassport: 'ClientPassport' as DocumentType,
    DriverLicense: 'DriverLicense' as DocumentType,
    Sopd: 'SOPD' as DocumentType,
    ClientPhoto: 'ClientPhoto' as DocumentType,
    VehiclePassport: 'VehiclePassport' as DocumentType,
    VehicleInformationLetter: 'VehicleInformationLetter' as DocumentType,
    VehicleSaleContract: 'VehicleSaleContract' as DocumentType,
    DownPaymentReceipt: 'DownPaymentReceipt' as DocumentType,
    VehiclePaymentInvoice: 'VehiclePaymentInvoice' as DocumentType,
    IndividualConditions: 'IndividualConditions' as DocumentType,
    PaymentSchedule: 'PaymentSchedule' as DocumentType,
    LoanApplication: 'LoanApplication' as DocumentType,
    ComprehensiveServiceAgreement: 'ComprehensiveServiceAgreement' as DocumentType,
    TransferForVehicle: 'TransferForVehicle' as DocumentType,
    AdditionalProductTransferApplication: 'AdditionalProductTransferApplication' as DocumentType,
    AdditionalProductContract: 'AdditionalProductContract' as DocumentType,
    RefusalNotice: 'RefusalNotice' as DocumentType,
    SopdThirdParties: 'SOPDThirdParties' as DocumentType,
    AdditionalProductPaymentInvoice: 'AdditionalProductPaymentInvoice' as DocumentType,
    ClientPassportFull: 'ClientPassportFull' as DocumentType
};

