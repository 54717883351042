/**
 * Fx.Spr
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DateOnly } from '@lib-utils';


export interface CheckVehicleHistoryOutput { 
    isInPledge?: boolean;
    vehicleMortgatorInn?: string | null;
    gibddCacheId?: number;
    gibddDate?: DateOnly;
    sparkCacheId?: number;
    sparkDate?: DateOnly;
    isVehicleMortgatorInnEqualSellerInn?: boolean;
    vehicleMortgatorInns?: string | null;
    foundPledgedRecords?: boolean;
    pledgedRecordCount?: number;
    properties?: { [key: string]: Array<string>; } | null;
    pledgedHistory?: { [key: string]: object; } | null;
    pledgers?: { [key: string]: string; } | null;
    pledgees?: { [key: string]: string; } | null;
}

