import { Nullable } from '@lib-utils';
import { AdminStatus } from './auto-admin-status';

export interface AutoDealerFilterDto {
  cluster: Nullable<string>;
  status: Nullable<AdminStatus>;
  dealerName: Nullable<string>;
  agentName: Nullable<string>;
  companyName: Nullable<string>;
}
