/**
 * Fx.Spr
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Ответ из системы по выявлению мошенничества.
 */
export interface FraudVector { 
    /**
     * Статус ответа.
     */
    status?: string | null;
    /**
     * Общие правила, сработавшие для запроса клиента.
     */
    mainRules?: string | null;
    /**
     * Балльная оценка.
     */
    mainScoreValue?: string | null;
    /**
     * Сработавшие правила.
     */
    triggeredRules?: Array<string> | null;
    /**
     * Сработавшие правила со скорингами.
     */
    mainRulesWithScores?: { [key: string]: number; } | null;
}

