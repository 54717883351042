import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { AutoLookupConfiguration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { AdditionalProductApiService } from './api/additionalProduct.service';
import { AddressApiService } from './api/address.service';
import { CalculatorApiService } from './api/calculator.service';
import { DataApiService } from './api/data.service';
import { DealerApiService } from './api/dealer.service';
import { DictionaryApiService } from './api/dictionary.service';
import { EmploymentApiService } from './api/employment.service';
import { ImportApiService } from './api/import.service';
import { OrganizationApiService } from './api/organization.service';
import { UserApiService } from './api/user.service';
import { VehicleApiService } from './api/vehicle.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    AdditionalProductApiService,
    AddressApiService,
    CalculatorApiService,
    DataApiService,
    DealerApiService,
    DictionaryApiService,
    EmploymentApiService,
    ImportApiService,
    OrganizationApiService,
    UserApiService,
    VehicleApiService ]
})
export class FxAutoLookupApiModule {
    public static forRoot(configurationFactory: () => AutoLookupConfiguration): ModuleWithProviders<FxAutoLookupApiModule> {
        return {
            ngModule: FxAutoLookupApiModule,
            providers: [ { provide: AutoLookupConfiguration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: FxAutoLookupApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('FxAutoLookupApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
