import { Directive, effect, inject, input, OnDestroy } from '@angular/core';
import { TuiAccordionItemComponent } from '@taiga-ui/kit';
import { of, timer } from 'rxjs';
import { DEBOUNCE_TIME_DEFAULT, ExpandableDirective } from '@lib-utils';
import { FormFieldService } from './form-field.service';

@Directive({
  selector: '[fnipFormContainer]',
  standalone: true,
})
export class FormContainerDirective implements OnDestroy {
  fnipFormContainer = input.required<string>();
  formContainerName = input<string>();

  #expandableContainer = inject(ExpandableDirective, { optional: true });
  #tuiContainer = inject(TuiAccordionItemComponent, { optional: true });

  #formFieldService = inject(FormFieldService);
  #parentContainer = inject(FormContainerDirective, { optional: true, skipSelf: true });

  expanded = () => {
    return this.#expandableContainer?.openState() ?? this.#tuiContainer?.open ?? false;
  };

  expand$ = () => {
    if (this.#expandableContainer) {
      this.#expandableContainer.setOpen(true);
    } else if (this.#tuiContainer) {
      this.#tuiContainer.open = true;
      this.#tuiContainer['cdr'].detectChanges();
    } else {
      return of(null);
    }
    return timer(DEBOUNCE_TIME_DEFAULT);
  };

  constructor() {
    effect(() => {
      this.#formFieldService.addContainer({
        id: this.fnipFormContainer(),
        parentId: this.#parentContainer?.fnipFormContainer(),
        name: this.formContainerName() ?? this.#expandableContainer?.name(),
        isExpanded: this.expanded,
        expand$: this.expand$,
      });
    });
  }

  ngOnDestroy(): void {
    this.#formFieldService.removeContainer(this.fnipFormContainer());
  }
}
