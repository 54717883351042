/**
 * Fx.Spr
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type SecurityRuleSet = 'None' | 'Level1' | 'Level2';

export const SecurityRuleSet = {
    None: 'None' as SecurityRuleSet,
    Level1: 'Level1' as SecurityRuleSet,
    Level2: 'Level2' as SecurityRuleSet
};

