/**
 * FxAuto.BFF.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type DealIssueType = 'Other' | 'Document' | 'LoanApplication' | 'ComprehensiveServiceAgreement' | 'AdditionalProductTransferApplication' | 'TransferForVehicle' | 'PaymentSchedule' | 'IndividualConditions' | 'SOPD' | 'SOPDThirdParties' | 'RefusalNotice' | 'LifeInsurance' | 'RoadAccidentAssistancePolicy';

export const DealIssueType = {
    Other: 'Other' as DealIssueType,
    Document: 'Document' as DealIssueType,
    LoanApplication: 'LoanApplication' as DealIssueType,
    ComprehensiveServiceAgreement: 'ComprehensiveServiceAgreement' as DealIssueType,
    AdditionalProductTransferApplication: 'AdditionalProductTransferApplication' as DealIssueType,
    TransferForVehicle: 'TransferForVehicle' as DealIssueType,
    PaymentSchedule: 'PaymentSchedule' as DealIssueType,
    IndividualConditions: 'IndividualConditions' as DealIssueType,
    Sopd: 'SOPD' as DealIssueType,
    SopdThirdParties: 'SOPDThirdParties' as DealIssueType,
    RefusalNotice: 'RefusalNotice' as DealIssueType,
    LifeInsurance: 'LifeInsurance' as DealIssueType,
    RoadAccidentAssistancePolicy: 'RoadAccidentAssistancePolicy' as DealIssueType
};

