/**
 * Fx.Spr
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * <p>Type: <b>FxAuto.Spr.Application.Check.Result.DecisionType</b></p><p>Members:</p> <ul> </ul> 
 */
export type DecisionType = 'Approved' | 'Declined';

export const DecisionType = {
    Approved: 'Approved' as DecisionType,
    Declined: 'Declined' as DecisionType
};

