import { CreditProductType, VehicleMakeDto } from '@lib-auto/api';
import { AdminStatus } from './auto-admin-status';
import { AutoAgentDto } from './auto-agent';
import { DealerAccreditation } from './auto-dealer-accreditation';
import { AutoDealerOrganizationDto } from './auto-dealer-organization';
import { AutoDealerRequisitesDto } from './auto-dealer-requisites';
import { AutoDealerShowroomDto } from './auto-dealer-showroom';
import { DealerType } from './auto-dealer-type';
import { AutoInsurance } from './auto-insurance';
import { PersonDto } from './auto-person';
import { SecurityCheckResult } from './auto-security-check-result';
import { FeAdditionalProductDto } from './fe-additional-product';

export interface AutoDealerDto {
  id?: number;
  status?: AdminStatus;
  gkName?: string;
  dealerName?: string;
  curatorName?: string;
  dealerType?: DealerType;
  cluster?: string;
  accreditation?: DealerAccreditation;
  agreementDate?: Date;
  agreementNumber?: string;
  securityCheckDate?: Date;
  securityCheckResult?: SecurityCheckResult;
  securityNoteId?: number;
  contactPerson?: PersonDto;
  creditProductTypes?: CreditProductType[];
  insurance?: AutoInsurance[];
  makes?: VehicleMakeDto[];
  additionalProducts?: FeAdditionalProductDto[];
  smsNotification?: boolean;
  support?: boolean;
  issue?: string;
  organizations?: AutoDealerOrganizationDto[];
  requisites?: AutoDealerRequisitesDto[];
  showrooms?: AutoDealerShowroomDto[];
  agents?: AutoAgentDto[];
  agentsKB?: AutoAgentDto[];
}
