/**
 * FxAuto.BFF.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AdditionalProductDto } from './additionalProductDto';
import { CalculationResultType } from './calculationResultType';
import { CalculationResultInactiveReason } from './calculationResultInactiveReason';
import { CreditProductType } from './creditProductType';


export interface CalculationResultDto { 
    amount?: number;
    value?: number;
    loanValuePart?: number;
    monthlyPayment?: number;
    downPayment?: number;
    term?: number;
    rate?: number;
    creditProductId?: number;
    creditProductType?: CreditProductType;
    type?: CalculationResultType;
    additionalProducts?: Array<AdditionalProductDto> | null;
    additionalProductsMaxValue?: number;
    isActive?: boolean;
    inactiveReasonType?: CalculationResultInactiveReason;
    inactiveReason?: string | null;
}
export namespace CalculationResultDto {
}


