export * from './additionalProduct.service';
import { AdditionalProductApiService } from './additionalProduct.service';
export * from './address.service';
import { AddressApiService } from './address.service';
export * from './calculator.service';
import { CalculatorApiService } from './calculator.service';
export * from './data.service';
import { DataApiService } from './data.service';
export * from './dealer.service';
import { DealerApiService } from './dealer.service';
export * from './dictionary.service';
import { DictionaryApiService } from './dictionary.service';
export * from './employment.service';
import { EmploymentApiService } from './employment.service';
export * from './import.service';
import { ImportApiService } from './import.service';
export * from './organization.service';
import { OrganizationApiService } from './organization.service';
export * from './user.service';
import { UserApiService } from './user.service';
export * from './vehicle.service';
import { VehicleApiService } from './vehicle.service';
export const APIS = [AdditionalProductApiService, AddressApiService, CalculatorApiService, DataApiService, DealerApiService, DictionaryApiService, EmploymentApiService, ImportApiService, OrganizationApiService, UserApiService, VehicleApiService];
