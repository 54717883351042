/**
 * Fx.Spr
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DrivingLicenseData } from './drivingLicenseData';
import { EmploymentData } from './employmentData';
import { PreviousPassportData } from './previousPassportData';
import { PassportData } from './passportData';
import { DateOnly } from '@lib-utils';
import { MaritalType } from './maritalType';
import { GenderType } from './genderType';


export interface ClientData { 
    externalClientId?: string;
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
    readonly fullName?: string | null;
    additionalIncome?: number;
    birthPlace?: string | null;
    birthday?: DateOnly;
    snils?: string | null;
    gender?: GenderType;
    maritalStatus?: MaritalType;
    ogrnip?: string | null;
    passportRegion?: string | null;
    phone?: string | null;
    factFullAddress?: string | null;
    factFiasId?: string | null;
    regFullAddress?: string | null;
    regFiasId?: string | null;
    income?: number | null;
    passport?: PassportData;
    drivingLicense?: DrivingLicenseData;
    previousPassports?: Array<PreviousPassportData> | null;
    employments?: Array<EmploymentData> | null;
    spousePhone?: string | null;
    spouseFullName?: string | null;
    coworkerPhone?: string | null;
    coworkerFullName?: string | null;
}

