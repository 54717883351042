/**
 * Fx.Spr
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PassportValidity } from './passportValidity';
import { Rfm } from './rfm';
import { Blacklist } from './blacklist';
import { TaxNumber } from './taxNumber';
import { Bankruptcy } from './bankruptcy';
import { RegAddress } from './regAddress';


export interface GeneralChecks { 
    regAddress?: RegAddress;
    passportValidity?: PassportValidity;
    taxNumber?: TaxNumber;
    bankruptcy?: Bankruptcy;
    rfm?: Rfm;
    blacklist?: Blacklist;
}

