import { Nullable } from '@lib-utils';
import { AdditionalProductDto, AdditionalProductOptionDto } from '@lib-auto/api';
import { findAdditionalProductOption } from './find-additional-product-option';

export function toAdditionaProductOption(additionalProductOptions: Nullable<AdditionalProductOptionDto[]>) {
  return (additionalProduct: AdditionalProductDto) => {
    const option = additionalProductOptions?.find(findAdditionalProductOption(additionalProduct));
    return {
      ...option,
      ...additionalProduct,
    };
  };
}
