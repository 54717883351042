/**
 * FxAuto.BFF.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ApplicationListSortField = 'Id' | 'CreatedOn' | 'UpdatedOn' | 'Client' | 'Status' | 'Manager';

export const ApplicationListSortField = {
    Id: 'Id' as ApplicationListSortField,
    CreatedOn: 'CreatedOn' as ApplicationListSortField,
    UpdatedOn: 'UpdatedOn' as ApplicationListSortField,
    Client: 'Client' as ApplicationListSortField,
    Status: 'Status' as ApplicationListSortField,
    Manager: 'Manager' as ApplicationListSortField
};

