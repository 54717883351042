/**
 * Fx.Spr
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * <p>Type: <b>FxAuto.Spr.Domain.Enums.VehicleEngineType</b></p><p>Members:</p> <ul> </ul> 
 */
export type VehicleEngineType = 'None' | 'Petrol' | 'Diesel' | 'Hybrid' | 'Electric' | 'Gas';

export const VehicleEngineType = {
    None: 'None' as VehicleEngineType,
    Petrol: 'Petrol' as VehicleEngineType,
    Diesel: 'Diesel' as VehicleEngineType,
    Hybrid: 'Hybrid' as VehicleEngineType,
    Electric: 'Electric' as VehicleEngineType,
    Gas: 'Gas' as VehicleEngineType
};

