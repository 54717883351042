/**
 * Fx.Spr
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Сведения о задолженности.
 */
export interface Arrear { 
    /**
     * Признак расчета по последнему платежу.
     */
    isLastPaymentCalc?: number;
    /**
     * Дата расчета.
     */
    calculationDate?: string | null;
    /**
     * Сумма задолженности на дату передачи финансирования субъекту или возникновения обеспечения исполнения обязательства.
     */
    startAmountOutstanding?: number;
    /**
     * Сумма задолженности.
     */
    outstanding?: number;
    /**
     * Сумма задолженности по основному долгу.
     */
    principalOutstanding?: number;
    /**
     * Сумма задолженности по процентам.
     */
    interestOutstanding?: number;
    /**
     * Сумма задолженности по иным требованиям.
     */
    otherOutstanding?: number;
}

