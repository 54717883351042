/**
 * Fx.Spr
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { VehicleSellerType } from './vehicleSellerType';
import { VehiclePassportType } from './vehiclePassportType';
import { VehicleType } from './vehicleType';
import { VehicleEngineType } from './vehicleEngineType';


export interface CheckVehicleDto { 
    vin?: string | null;
    passportType?: VehiclePassportType;
    type?: VehicleType;
    passportNumber?: string | null;
    sellerType?: VehicleSellerType;
    sellerInn?: string | null;
    brand?: string | null;
    model?: string | null;
    year?: number;
    engineType?: VehicleEngineType;
    engineSize?: number | null;
    price?: number;
}

