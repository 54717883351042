import { EmploymentType } from '@lib-auto/api';
import { AutoRiskBorrowerInfoDto } from './auto-risk-borrower-info';

export interface AutoDocumentInspectionInfoDto {
  borrowerInfo: AutoRiskBorrowerInfoDto;
  employment: {
    type: EmploymentType;
    companyName: string;
    companyInn: string;
    companyPhone: string;
    companyAddress: string;
  };

  lendingTerms: {
    loanTotal: number;
    period: number;
    rate: number;
    initialPayment?: number;
    monthlyPayment: number;
    vehicleCreditSum: number;
    additionalProductsSum: number;
  };

  vehicleInfo: {
    brandName: string;
    model: string;
    year: string;
    vin: string;
  };
}
