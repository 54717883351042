/**
 * FxAuto.BFF.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AdditionalProductRequestDto } from './additionalProductRequestDto';
import { CreditProductType } from './creditProductType';
import { CalculateRequestLimitsDto } from './calculateRequestLimitsDto';


export interface CalculateRequestDto { 
    dealerShowroomId?: number;
    vehiclePrice?: number;
    downPayment?: number | null;
    downPaymentPercent?: number | null;
    term?: number;
    limits?: CalculateRequestLimitsDto;
    additionalProducts?: Array<AdditionalProductRequestDto> | null;
    creditProductType?: CreditProductType;
}
export namespace CalculateRequestDto {
}


