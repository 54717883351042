import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { AutoSprConfiguration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { AutoSprApiService } from './api/auto.service';
import { CheckSprApiService } from './api/check.service';
import { CheckVehicleSprApiService } from './api/checkVehicle.service';
import { CreditReportSprApiService } from './api/creditReport.service';
import { ImportSprApiService } from './api/import.service';
import { PdnSprApiService } from './api/pdn.service';
import { StagesSprApiService } from './api/stages.service';
import { VerificationSprApiService } from './api/verification.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    AutoSprApiService,
    CheckSprApiService,
    CheckVehicleSprApiService,
    CreditReportSprApiService,
    ImportSprApiService,
    PdnSprApiService,
    StagesSprApiService,
    VerificationSprApiService ]
})
export class FxAutoSprApiModule {
    public static forRoot(configurationFactory: () => AutoSprConfiguration): ModuleWithProviders<FxAutoSprApiModule> {
        return {
            ngModule: FxAutoSprApiModule,
            providers: [ { provide: AutoSprConfiguration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: FxAutoSprApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('FxAutoSprApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
