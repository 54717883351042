import { inject } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { tap } from 'rxjs';
import { getInvalidControls, NotificationService } from '@lib-utils';
import { FormField } from './form-field';
import { FormFieldService } from './form-field.service';

/**
 * Коллбэк для подсвечивания валидационных ошибок в полях, на которых есть FormFieldDirective
 */
export function getDisplayFormValidationErrors$(
  formFieldService = inject(FormFieldService),
  notificationService = inject(NotificationService),
) {
  return (form: AbstractControl) => {
    form.markAllAsTouched();

    const errorFields = getInvalidControls(form)
      .map((control) => formFieldService.getFieldByControl(control))
      .filter((field): field is FormField => Boolean(field));

    return formFieldService.expandParentContainers$(errorFields.map((filed) => filed.id)).pipe(
      tap(() => {
        const firstFieldId = errorFields[0]?.id;
        firstFieldId && formFieldService.scrollToField(firstFieldId);
      }),
      tap(() => {
        const fieldLabels = errorFields.map((field) => field.label).filter(Boolean);
        const errorMessage = fieldLabels?.length
          ? 'Присутствуют ошибки в форме, проверьте поля:' + '\n&#8226;\t' + fieldLabels.join('\n&#8226;\t')
          : 'Присутствуют ошибки в форме, проверьте правильность заполнения полей';

        notificationService.showError(errorMessage);

        for (const field of errorFields) field.cdr?.markForCheck();
      }),
    );
  };
}
