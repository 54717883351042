import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { catchError, Observable, switchMap, throwError } from 'rxjs';
import { FeHttpError } from '@lib-utils';
import { FormFieldService } from './form-field.service';

@Injectable()
export class ShowFormErrorsInterceptor implements HttpInterceptor {
  #formFieldService = inject(FormFieldService);

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      catchError((error: FeHttpError<unknown> | HttpErrorResponse) => {
        return Array.isArray(error.error.errors)
          ? this.#formFieldService.displayErrors$(error.error.errors).pipe(switchMap(() => throwError(() => error)))
          : throwError(() => error);
      }),
    );
  }
}
