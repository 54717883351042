/**
 * Fx.Spr
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FraudVector } from './fraudVector';


/**
 * Информация для предотвращения мошенничества.
 */
export interface Fraud { 
    vector?: FraudVector;
    /**
     * Источники данных.
     */
    infoSources?: Array<string> | null;
    /**
     * Описание ошибки (если возникла).
     */
    error?: string | null;
    /**
     * Код ошибки (если возникла).
     */
    errorCode?: string | null;
}

