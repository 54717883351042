/**
 * FxAuto.BFF.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ApplicationLoanStateType } from './applicationLoanStateType';
import { ApplicationStageType } from './applicationStageType';


export interface ApplicationOptionsDto { 
    uid?: string;
    navigateableStages?: Array<ApplicationStageType> | null;
    currentStage?: ApplicationStageType;
    loanState?: ApplicationLoanStateType;
    isRevisionRequired?: boolean;
    statusName?: string | null;
    clientFullName?: string | null;
}
export namespace ApplicationOptionsDto {
}


