import { Nullable } from '@lib-utils';

export interface AutoParameterConditionDto {
  type?: Nullable<AutoCondition>;
  result?: Nullable<number>;
}

export const AutoCondition = {
  New: 'New',
  Used: 'Used',
} as const;

export type AutoCondition = (typeof AutoCondition)[keyof typeof AutoCondition];

export const AutoConditionMap: Record<AutoCondition, string> = {
  [AutoCondition.New]: 'Новое авто',
  [AutoCondition.Used]: 'Авто с пробегом',
};
