/**
 * FxAuto.BFF.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ApplicationStageType } from './applicationStageType';
import { ApplicationListStatusType } from './applicationListStatusType';
import { UserInfoDto } from './userInfoDto';
import { IdNameDto } from './idNameDto';
import { DateTime } from '@lib-utils';


export interface ApplicationListItemDto { 
    id?: number;
    client?: IdNameDto;
    creator?: UserInfoDto;
    createdOn?: DateTime;
    updatedOn?: DateTime;
    statusName?: string | null;
    statusType?: ApplicationListStatusType;
    currentStage?: ApplicationStageType;
    completedStagesNumber?: number;
    allStagesNumber?: number;
}
export namespace ApplicationListItemDto {
}


