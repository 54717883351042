import uniq from 'lodash-es/uniq';
import { Nullable } from '@lib-utils';
import { ACCEPT_FILE_TYPES } from '@lib-widgets/reactive-fields';
import { DocumentFileType } from '@lib-auto/api';

export const FileTypesMap: Record<DocumentFileType, Nullable<ACCEPT_FILE_TYPES>> = {
  NotSupported: null,
  Pdf: ACCEPT_FILE_TYPES.PDF_TYPE,
  Png: ACCEPT_FILE_TYPES.IMAGE_PNG_TYPE,
  Tiff: ACCEPT_FILE_TYPES.IMAGE_TIF_TYPES,
  Heic: ACCEPT_FILE_TYPES.IMAGE_HEIC_TYPE,
  Bmp: ACCEPT_FILE_TYPES.IMAGE_BMP_TYPE,
  Jpeg: ACCEPT_FILE_TYPES.IMAGE_JPEG_TYPE,
  Jfif: ACCEPT_FILE_TYPES.IMAGE_JPEG_TYPE,
  Xls: ACCEPT_FILE_TYPES.EXCEL_TYPES,
  Xlsx: ACCEPT_FILE_TYPES.EXCEL_TYPES,
  Doc: ACCEPT_FILE_TYPES.MSWORD_TYPES,
  Docx: ACCEPT_FILE_TYPES.MSWORD_TYPES,
};

export function getDocumentsAllowedTypes(fileTypeOptions: Nullable<DocumentFileType[]>) {
  return (
    uniq(fileTypeOptions?.map((item) => FileTypesMap[item])?.filter((item): item is ACCEPT_FILE_TYPES => !!item)) ?? []
  );
}
