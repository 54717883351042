export const AutoReportSource = {
  FsspByIP: 'FsspByIP',
  FsspByApplications: 'FsspByApplications',
  ClientIncomeReport: 'ClientIncomeReport',
  ScoringModelReport: 'ScoringModelReport',
  PhoneVerificationReport: 'PhoneVerificationReport',
  PhoneVerifiсationEfficiencyReport: 'PhoneVerifiсationEfficiencyReport',
  StatusChangesReport: 'StatusChangesReport',
  RiskApplicationsFlat: 'RiskApplicationsFlat',
  RiskCalculatedCreditHistory: 'RiskCalculatedCreditHistory',
  RiskAutoInfos: 'RiskAutoInfos',
} as const;

export type AutoReportSource = (typeof AutoReportSource)[keyof typeof AutoReportSource];

export const AutoReportSourceMap: Record<AutoReportSource, string> = {
  [AutoReportSource.FsspByIP]: 'Сведения ФССП по ИП',
  [AutoReportSource.FsspByApplications]: 'Сведения ФССП по заявкам',
  [AutoReportSource.ClientIncomeReport]: 'Отчет по доходам клиента',
  [AutoReportSource.ScoringModelReport]: 'Отчет по моделям',
  [AutoReportSource.PhoneVerificationReport]: 'Телефонная верификация',
  [AutoReportSource.PhoneVerifiсationEfficiencyReport]: 'Эффективность телефонной верификации',
  [AutoReportSource.StatusChangesReport]: 'Изменение статусов по заявкам',
  [AutoReportSource.RiskApplicationsFlat]: 'Общий риск отчет',
  [AutoReportSource.RiskCalculatedCreditHistory]: 'Риск отчет рассчитанной кредитной истории',
  [AutoReportSource.RiskAutoInfos]: 'Отчет по ТС',
};
