import { Nullable } from '@lib-utils';
import { ConditionType } from './auto-condition-type';

export interface SellCreditConditionDto {
  type?: Nullable<ConditionType>;
  value?: Nullable<number>;
  result?: Nullable<number>;
}

export interface AutoSellCreditConditionsDto {
  conditions: SellCreditConditionDto[];
  dealerIds: number[];
}
