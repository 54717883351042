/**
 * FxAuto.Lookup.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DealerOfficialStatus } from './dealerOfficialStatus';
import { State } from './state';


export interface DataSetDealerDto { 
    id?: number | null;
    name?: string | null;
    state?: State;
    officialStatus?: DealerOfficialStatus;
    clusterName?: string | null;
    companyGroupName?: string | null;
    organizationBranchIds?: Array<number> | null;
    defaultOrganizationBranchId?: number;
    showroomIds?: Array<number> | null;
    officialMakeIds?: Array<number> | null;
    creditProductIds?: Array<number> | null;
}
export namespace DataSetDealerDto {
}


