/**
 * Fx.Spr
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * <p>Type: <b>FxAuto.Spr.Domain.Enums.VehicleType</b></p><p>Members:</p> <ul> </ul> 
 */
export type VehicleType = 'New' | 'Used';

export const VehicleType = {
    New: 'New' as VehicleType,
    Used: 'Used' as VehicleType
};

