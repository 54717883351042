export const DealerAccreditation = {
  Accreditation1: 'Accreditation1',
  Accreditation2: 'Accreditation2',
} as const;

export type DealerAccreditation = (typeof DealerAccreditation)[keyof typeof DealerAccreditation];

export const DealerAccreditationMap: Record<DealerAccreditation, string> = {
  [DealerAccreditation.Accreditation1]: 'Решение об аккредитации 1',
  [DealerAccreditation.Accreditation2]: 'Решение об аккредитации 2',
};
