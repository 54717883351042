import { Nullable } from '@lib-utils';
import { FamilyStatusType } from '@lib-auto/api';

export interface AutoRiskBorrowerInfoDto {
  fullName?: string;
  birthdayDate?: Nullable<string>;
  birthplace?: Nullable<string>;

  phoneNumber?: Nullable<string>;
  email?: Nullable<string>;
  maritalStatus?: Nullable<FamilyStatusType>;

  passport?: {
    number: Nullable<string>;
    issueDate: Nullable<string>;
    expirationMonth: Nullable<number>;
    isReplacedByAge: Nullable<boolean>;
    issueDepartmentName: Nullable<string>;
    issueDepartmentCode: Nullable<string>;
  };

  driverLicense?: {
    number: Nullable<string>;
    issueDate: Nullable<string>;
  };

  juristicAddress?: Nullable<string>;
  factualAddress?: Nullable<string>;

  isTownApartmentRegistration?: boolean;
  hasActiveEGRIP?: boolean;

  signsOfFalsification?: [];
  checks?: {
    name: string;
    isPassed: boolean;
    comment: string;
  }[];
}
