export interface AutoDealerConnectionDto {
  type?: AutoDealerConnectionType;
  connectionId?: number;
}

export const AutoDealerConnectionType = {
  Organization: 'Organization',
  Requisites: 'Requisites',
  Showroom: 'Showroom',
  Agent: 'Agent',
  AgentKB: 'AgentKB',
};

export type AutoDealerConnectionType = (typeof AutoDealerConnectionType)[keyof typeof AutoDealerConnectionType];
