/**
 * Fx.Spr
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CreditObligationLiabilityType } from './creditObligationLiabilityType';
import { Obligation } from './obligation';
import { CreditReportSourceType } from './creditReportSourceType';
import { DateOnly } from '@lib-utils';


export interface CalculatedObligation { 
    obligation?: Obligation;
    sources?: Array<CreditReportSourceType> | null;
    isActive?: boolean;
    liabilityType?: CreditObligationLiabilityType;
    firstPaymentDate?: DateOnly;
    lastPaymentDate?: DateOnly;
    possibleDublicate?: boolean;
    accountingFactor?: number;
    costRate?: number | null;
    monthsToClosing?: number | null;
    paymentsCount?: number;
    monthlyPayment?: number;
    initialMonthlyPayment?: number;
    paymentString?: string | null;
    categoryCb?: string | null;
}

