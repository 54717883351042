export const AutoDocumentInspectionDocumentType = {
  PersonalDocuments: 'personal',
  DealDocuments: 'deal',
  Policies: 'policies',
  Invoices: 'invoices',
} as const;

export type AutoDocumentInspectionDocumentType =
  (typeof AutoDocumentInspectionDocumentType)[keyof typeof AutoDocumentInspectionDocumentType];

export const AutoDocumentInspectionDocumentTypeMap: Record<AutoDocumentInspectionDocumentType, string> = {
  [AutoDocumentInspectionDocumentType.PersonalDocuments]: 'Личные документы',
  [AutoDocumentInspectionDocumentType.DealDocuments]: 'Документы по сделке',
  [AutoDocumentInspectionDocumentType.Policies]: 'Полисы',
  [AutoDocumentInspectionDocumentType.Invoices]: 'Счета на оплату',
};
