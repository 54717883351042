import { FromGeneratedEnum } from '@lib-utils';
import { ApplicationStatus, ApplicationStatusGroup } from '@lib-auto/api-reports';

export const ApplicationStatusGroupMap: FromGeneratedEnum<ApplicationStatusGroup> = {
  [ApplicationStatusGroup.Draft]: 'Новая заявка',
  [ApplicationStatusGroup.DecisionAwaiting]: 'На одобрении',
  [ApplicationStatusGroup.RevisionRequired]: 'На доработке',
  [ApplicationStatusGroup.Approved]: 'Одобрено',
  [ApplicationStatusGroup.Deal]: 'Сделка',
  [ApplicationStatusGroup.LoanIssued]: 'Кредит выдан',
  [ApplicationStatusGroup.LoanPaid]: 'Кредит оплачен',
  [ApplicationStatusGroup.Outdated]: 'Устаревшая',
  [ApplicationStatusGroup.Declined]: 'Отказ',
};

export const ApplicationStatusMap: FromGeneratedEnum<ApplicationStatus> = {
  [ApplicationStatus.Draft]: 'Анкета',
  [ApplicationStatus.DecisionAwaiting]: 'На одобрении',
  [ApplicationStatus.RevisionRequired]: 'На доработке',
  [ApplicationStatus.Approved]: 'Одобрено',
  [ApplicationStatus.ApprovedLoanAndVehicle]: 'Одобрено ТС',
  [ApplicationStatus.ApprovedLoanDeclinedVehicle]: 'Отказ по объекту',
  [ApplicationStatus.DealNegotiation]: 'Согласование сделки',
  [ApplicationStatus.DealNegotiationProcessing]: 'Согласование сделки: Обработка',
  [ApplicationStatus.DealNegotiationRevisionRequired]: 'Согласование сделки: Доработка',
  [ApplicationStatus.Deal]: 'Сделка',
  [ApplicationStatus.DealProcessing]: 'Сделка: Обработка',
  [ApplicationStatus.DealRevisionRequired]: 'Сделка: Доработка',
  [ApplicationStatus.LoanIssued]: 'Кредит выдан',
  [ApplicationStatus.LoanIssuedRevisionRequired]: 'Кредит выдан: Доработка',
  [ApplicationStatus.LoanPaid]: 'Кредит оплачен',
  [ApplicationStatus.Outdated]: 'Устаревшая',
  [ApplicationStatus.Declined]: 'Отказ',
};
