/**
 * FxAuto.Lookup.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DataBankDetailsDto } from './dataBankDetailsDto';
import { State } from './state';
import { DataOrganizationDto } from './dataOrganizationDto';


export interface DataOrganizationBranchDto { 
    id?: number;
    legacyId?: number | null;
    state?: State;
    name?: string | null;
    kpp?: string | null;
    address?: string | null;
    cftId?: number | null;
    organization?: DataOrganizationDto;
    bankDetailsList?: Array<DataBankDetailsDto> | null;
}
export namespace DataOrganizationBranchDto {
}


