import { PercentageInputMode } from '@lib-widgets/reactive-fields';
import { AdditionalProductOptionDto, CalculateRequestDto, CalculationResultType } from '@lib-auto/api';

export interface FeCalculateRequestDto extends Omit<CalculateRequestDto, 'additionalProducts'> {
  additionalProducts?: AdditionalProductOptionDto[];
  dealerId?: number;
  downPaymentMode?: PercentageInputMode;
}

export interface FeSavedCalculation {
  calculateRequest: FeCalculateRequestDto;
  calculationResultType: CalculationResultType;
}
