import { MaskitoOptions } from '@maskito/core';
import fill from 'lodash-es/fill';
import { getArrOfRegExpDigits } from '@lib-utils';

export const PTS_MASK: MaskitoOptions = {
  mask: [/\d/, /\d/, ' ', /[а-яА-Я]/, /[а-яА-Я]/, ' ', ...getArrOfRegExpDigits(6)],
};

export const EPTS_MASK: MaskitoOptions = {
  mask: getArrOfRegExpDigits(15),
};

export const VIN_MASK: MaskitoOptions = {
  mask: fill(Array(17), /(?![ioqIOQ])[a-zA-Z\d]/),
};
