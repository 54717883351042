/**
 * Fx.Spr
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Cводные данные о кредитных обязательствах, полученные из БКИ
 */
export interface CalculatedObligationOverview {
    chRule1?: boolean;
    chRule2?: boolean;
    chRule3?: boolean;
    chRule4?: boolean;
    chRule4CurrentArrearsAmount?: number;
    significanceOkbSum?: number;
    significanceNbkiSum?: number;
    significanceEquifaxSum?: number;
    worstPaymentsString?: string | null;
    /**
     * Общее число просрочек до 30 дней за последние 12 месяцев
     */
    ttlDelq30L12M?: number;
    /**
     * Общее число просрочек от 30 до 59 дней за последние 12 месяцев
     */
    ttlDelq3059L12M?: number;
    /**
     * Общее число просрочек от 60 до 89 дней за последние 12 месяцев
     */
    ttlDelq6089L12M?: number;
    /**
     * Общее число просрочек 90+ дней за последние 12 месяцев
     */
    ttlDelq90PlusL12M?: number;
    maxOverdueSixMonths?: string;
    maxOverdueOneYear?: string;
    maxOverdueTwoYears?: string;
    maxOverdueThreeYears?: string;
}

