/**
 * FxAuto.BFF.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SharedApplicationDataIssueDto } from './sharedApplicationDataIssueDto';
import { DecisionDeclineType } from './decisionDeclineType';


export interface DecisionDecisionResultIssueDto { 
    reasonMessage?: string | null;
    reasonCode?: string | null;
    declineType?: DecisionDeclineType;
    applicationDataIssues?: Array<SharedApplicationDataIssueDto> | null;
}
export namespace DecisionDecisionResultIssueDto {
}


