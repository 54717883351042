/**
 * Fx.Spr
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * <p>Type: <b>FxAuto.Spr.Domain.Enums.Obligations.LiabilityStatusType</b></p><p>Members:</p> <ul> <p><li><i>Active</i> - Активный</li></p> <p><li><i>PaidForByCollateral</i> - Оплачен за счет обеспечения</li></p> <p><li><i>AccountClosed</i> - Счет закрыт</li></p> <p><li><i>TransferredToAnotherOrganizationForMaintenance</i> - Передан на обслуживание в другую организацию</li></p> <p><li><i>Dispute</i> - Спор</li></p> <p><li><i>Expired</i> - Просрочен</li></p> <p><li><i>ReturnIssues</i> - Проблемы с возвратом</li></p> <p><li><i>DataTransferTerminated</i> - Передача данных прекращена</li></p> <p><li><i>ObligationsEnforcement</i> - Принудительное исполнение обязательств</li></p> <p><li><i>BalanceCharge</i> - Списан с баланса</li></p> <p><li><i>Bankruptcy</i> - Банкротство, освобождение от требований</li></p> <p><li><i>BankruptcyResumed</i> - Возобновлена процедура банкротства</li></p> </ul> 
 */
export type LiabilityStatusType = 'Active' | 'PaidForByCollateral' | 'AccountClosed' | 'TransferredToAnotherOrganizationForMaintenance' | 'Dispute' | 'Expired' | 'ReturnIssues' | 'DataTransferTerminated' | 'ObligationsEnforcement' | 'BalanceCharge' | 'Bankruptcy' | 'BankruptcyResumed';

export const LiabilityStatusType = {
    Active: 'Active' as LiabilityStatusType,
    PaidForByCollateral: 'PaidForByCollateral' as LiabilityStatusType,
    AccountClosed: 'AccountClosed' as LiabilityStatusType,
    TransferredToAnotherOrganizationForMaintenance: 'TransferredToAnotherOrganizationForMaintenance' as LiabilityStatusType,
    Dispute: 'Dispute' as LiabilityStatusType,
    Expired: 'Expired' as LiabilityStatusType,
    ReturnIssues: 'ReturnIssues' as LiabilityStatusType,
    DataTransferTerminated: 'DataTransferTerminated' as LiabilityStatusType,
    ObligationsEnforcement: 'ObligationsEnforcement' as LiabilityStatusType,
    BalanceCharge: 'BalanceCharge' as LiabilityStatusType,
    Bankruptcy: 'Bankruptcy' as LiabilityStatusType,
    BankruptcyResumed: 'BankruptcyResumed' as LiabilityStatusType
};

