/**
 * FxAuto.Lookup.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AddressDto { 
    id?: number;
    regionId?: number;
    regionName?: string | null;
    cityWithType?: string | null;
    fullAddress?: string | null;
    postalCode?: string | null;
    apartment?: string | null;
    fiasId?: string;
    houseFiasId?: string | null;
    isAccredited?: boolean;
}

