/**
 * Fx.Spr
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type MethodImplAttributes = 'IL' | 'Native' | 'OPTIL' | 'Runtime' | 'Unmanaged' | 'NoInlining' | 'ForwardRef' | 'Synchronized' | 'NoOptimization' | 'PreserveSig' | 'AggressiveInlining' | 'AggressiveOptimization' | 'InternalCall' | 'MaxMethodImplVal';

export const MethodImplAttributes = {
    Il: 'IL' as MethodImplAttributes,
    Native: 'Native' as MethodImplAttributes,
    Optil: 'OPTIL' as MethodImplAttributes,
    Runtime: 'Runtime' as MethodImplAttributes,
    Unmanaged: 'Unmanaged' as MethodImplAttributes,
    NoInlining: 'NoInlining' as MethodImplAttributes,
    ForwardRef: 'ForwardRef' as MethodImplAttributes,
    Synchronized: 'Synchronized' as MethodImplAttributes,
    NoOptimization: 'NoOptimization' as MethodImplAttributes,
    PreserveSig: 'PreserveSig' as MethodImplAttributes,
    AggressiveInlining: 'AggressiveInlining' as MethodImplAttributes,
    AggressiveOptimization: 'AggressiveOptimization' as MethodImplAttributes,
    InternalCall: 'InternalCall' as MethodImplAttributes,
    MaxMethodImplVal: 'MaxMethodImplVal' as MethodImplAttributes
};

