import { inject } from '@angular/core';
import { CanActivateChildFn, Router } from '@angular/router';
import dropRight from 'lodash-es/dropRight';
import { map, tap } from 'rxjs/operators';
import { ConfigService } from '@lib-config';
import { IS_DEV_MODE_KEY, Nullable, toNullableNumber } from '@lib-utils';
import { ApplicationStageSteps, ApplicationStageStepUrl, AutoStateService } from '@lib-auto/datasource';
import { updateApplicationOptions$ } from './auto-state-utils';

export const availableStepGuardFn: CanActivateChildFn = (childRoute, state) => {
  const config = inject(ConfigService);
  if (config.isDevOrStage() && localStorage.getItem(IS_DEV_MODE_KEY)) return true;

  const router = inject(Router);
  const id = toNullableNumber(
    childRoute.pathFromRoot.reduce((acc, { params }) => Object.assign(acc, params), {} as Record<string, string>)['id'],
  );
  const targetStep = Object.entries(ApplicationStageStepUrl).find(
    ([_key, value]) => value === childRoute.routeConfig?.path,
  )?.[0] as Nullable<ApplicationStageSteps>;
  // Временно разрешаем переход на страницу-мок инспектора
  if (targetStep === ApplicationStageSteps.DocumentInspection) return true;
  // Проверяем возможность навигации на выбранный шаг, обновляем опции если не указано иное
  const autoStateService = inject(AutoStateService);
  const applicationOptions$ = autoStateService.updateOptionsInGuard
    ? updateApplicationOptions$(id, autoStateService)
    : autoStateService.applicationOptions$.pipe(tap(() => (autoStateService.updateOptionsInGuard = true)));

  return applicationOptions$.pipe(
    map((options) => {
      if (!options?.navigateableStages || !targetStep) return false;
      if (!options.navigateableStages?.includes(targetStep)) {
        // Переходим на актуальный шаг заявки. Отключаем повторный запрос опций в гварде
        autoStateService.updateOptionsInGuard = false;
        const applicationPath = dropRight(state.url.split('/'));
        return router.createUrlTree([...applicationPath, ApplicationStageStepUrl[options.currentStage!]]);
      }
      return true;
    }),
  );
};
