/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, isDevMode, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { CheckVehicleDto } from '../model/checkVehicleDto';
// @ts-ignore
import { GetCheckVehicleResponse } from '../model/getCheckVehicleResponse';
// @ts-ignore
import { ModelStateEntry } from '../model/modelStateEntry';
// @ts-ignore
import { ReRunCheckVehicleResponse } from '../model/reRunCheckVehicleResponse';
// @ts-ignore
import { ReRunManyCheckVehicleResponse } from '../model/reRunManyCheckVehicleResponse';
// @ts-ignore
import { RunAsyncCheckVehicleResponse } from '../model/runAsyncCheckVehicleResponse';
// @ts-ignore
import { RunCheckVehicleResponse } from '../model/runCheckVehicleResponse';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { AutoSprConfiguration }                                     from '../configuration';



const enum AuthorizationType {
  Bearer = 'Bearer',
}

const enum AuthorizationHeader {
  Authorization = 'Authorization',
}

const AcceptHeader = 'Accept';

@Injectable()
export class CheckVehicleSprApiService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new AutoSprConfiguration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: AutoSprConfiguration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }

    checkAuthentication(localVarHeaders: any, authType: AuthorizationType, paramName: AuthorizationHeader) {
      // authentication
      const localVarCredential = this.configuration.lookupCredential(authType as string); // name
      if (localVarCredential) {
        return localVarHeaders.set(paramName as string, localVarCredential); // keyParamName
      }

      return localVarHeaders;
    }

    getAcceptType(localVarHttpHeaderAcceptSelected?: string): 'text' | 'json' | 'blob' {
      if (localVarHttpHeaderAcceptSelected) {
        if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
          return 'text';
        } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
          return 'json';
        } else {
          return 'blob';
        }
      }

      return 'json';
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            httpParams = httpParams.append(key!, JSON.stringify(value));
        } else {
            httpParams = httpParams.append(key!, value);
        }

        return httpParams;
    }

    /**
     * @param externalApplicationId 
     * @param withInitProcess 
     * @param checkVehicleDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCheckVehicleExternalExternalApplicationIdRunAsyncPost(externalApplicationId: string, withInitProcess?: boolean, checkVehicleDto?: CheckVehicleDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<RunAsyncCheckVehicleResponse>;
    public apiCheckVehicleExternalExternalApplicationIdRunAsyncPost(externalApplicationId: string, withInitProcess?: boolean, checkVehicleDto?: CheckVehicleDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<RunAsyncCheckVehicleResponse>>;
    public apiCheckVehicleExternalExternalApplicationIdRunAsyncPost(externalApplicationId: string, withInitProcess?: boolean, checkVehicleDto?: CheckVehicleDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<RunAsyncCheckVehicleResponse>>;
    public apiCheckVehicleExternalExternalApplicationIdRunAsyncPost(externalApplicationId: string, withInitProcess?: boolean, checkVehicleDto?: CheckVehicleDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {




        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (withInitProcess !== undefined && withInitProcess !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>withInitProcess, 'withInitProcess');
        }

        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/CheckVehicle/external/${this.configuration.encodeParam({name: "externalApplicationId", value: externalApplicationId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/runAsync`;
        return this.httpClient.request<RunAsyncCheckVehicleResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: checkVehicleDto,
                params: localVarQueryParameters,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param externalApplicationId 
     * @param withInitProcess 
     * @param checkVehicleDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCheckVehicleExternalExternalApplicationIdRunPost(externalApplicationId: string, withInitProcess?: boolean, checkVehicleDto?: CheckVehicleDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<RunCheckVehicleResponse>;
    public apiCheckVehicleExternalExternalApplicationIdRunPost(externalApplicationId: string, withInitProcess?: boolean, checkVehicleDto?: CheckVehicleDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<RunCheckVehicleResponse>>;
    public apiCheckVehicleExternalExternalApplicationIdRunPost(externalApplicationId: string, withInitProcess?: boolean, checkVehicleDto?: CheckVehicleDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<RunCheckVehicleResponse>>;
    public apiCheckVehicleExternalExternalApplicationIdRunPost(externalApplicationId: string, withInitProcess?: boolean, checkVehicleDto?: CheckVehicleDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {




        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (withInitProcess !== undefined && withInitProcess !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>withInitProcess, 'withInitProcess');
        }

        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/CheckVehicle/external/${this.configuration.encodeParam({name: "externalApplicationId", value: externalApplicationId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/run`;
        return this.httpClient.request<RunCheckVehicleResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: checkVehicleDto,
                params: localVarQueryParameters,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param applicationId 
     * @param externalApplicationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCheckVehicleGet(applicationId?: number, externalApplicationId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<GetCheckVehicleResponse>;
    public apiCheckVehicleGet(applicationId?: number, externalApplicationId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<GetCheckVehicleResponse>>;
    public apiCheckVehicleGet(applicationId?: number, externalApplicationId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<GetCheckVehicleResponse>>;
    public apiCheckVehicleGet(applicationId?: number, externalApplicationId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {



        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (applicationId !== undefined && applicationId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>applicationId, 'applicationId');
        }
        if (externalApplicationId !== undefined && externalApplicationId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>externalApplicationId, 'externalApplicationId');
        }

        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/CheckVehicle`;
        return this.httpClient.request<GetCheckVehicleResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param internalApplicationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCheckVehicleInternalApplicationIdReRunAsyncPost(internalApplicationId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<ReRunCheckVehicleResponse>;
    public apiCheckVehicleInternalApplicationIdReRunAsyncPost(internalApplicationId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<ReRunCheckVehicleResponse>>;
    public apiCheckVehicleInternalApplicationIdReRunAsyncPost(internalApplicationId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<ReRunCheckVehicleResponse>>;
    public apiCheckVehicleInternalApplicationIdReRunAsyncPost(internalApplicationId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/CheckVehicle/${this.configuration.encodeParam({name: "internalApplicationId", value: internalApplicationId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/reRunAsync`;
        return this.httpClient.request<ReRunCheckVehicleResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param internalApplicationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCheckVehicleInternalApplicationIdReRunPost(internalApplicationId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<ReRunCheckVehicleResponse>;
    public apiCheckVehicleInternalApplicationIdReRunPost(internalApplicationId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<ReRunCheckVehicleResponse>>;
    public apiCheckVehicleInternalApplicationIdReRunPost(internalApplicationId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<ReRunCheckVehicleResponse>>;
    public apiCheckVehicleInternalApplicationIdReRunPost(internalApplicationId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {


        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/CheckVehicle/${this.configuration.encodeParam({name: "internalApplicationId", value: internalApplicationId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/reRun`;
        return this.httpClient.request<ReRunCheckVehicleResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param internalApplicationId 
     * @param withInitProcess 
     * @param checkVehicleDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCheckVehicleInternalInternalApplicationIdRunAsyncPost(internalApplicationId: number, withInitProcess?: boolean, checkVehicleDto?: CheckVehicleDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<RunAsyncCheckVehicleResponse>;
    public apiCheckVehicleInternalInternalApplicationIdRunAsyncPost(internalApplicationId: number, withInitProcess?: boolean, checkVehicleDto?: CheckVehicleDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<RunAsyncCheckVehicleResponse>>;
    public apiCheckVehicleInternalInternalApplicationIdRunAsyncPost(internalApplicationId: number, withInitProcess?: boolean, checkVehicleDto?: CheckVehicleDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<RunAsyncCheckVehicleResponse>>;
    public apiCheckVehicleInternalInternalApplicationIdRunAsyncPost(internalApplicationId: number, withInitProcess?: boolean, checkVehicleDto?: CheckVehicleDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {




        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (withInitProcess !== undefined && withInitProcess !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>withInitProcess, 'withInitProcess');
        }

        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/CheckVehicle/internal/${this.configuration.encodeParam({name: "internalApplicationId", value: internalApplicationId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/runAsync`;
        return this.httpClient.request<RunAsyncCheckVehicleResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: checkVehicleDto,
                params: localVarQueryParameters,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param internalApplicationId 
     * @param withInitProcess 
     * @param checkVehicleDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCheckVehicleInternalInternalApplicationIdRunPost(internalApplicationId: number, withInitProcess?: boolean, checkVehicleDto?: CheckVehicleDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<RunCheckVehicleResponse>;
    public apiCheckVehicleInternalInternalApplicationIdRunPost(internalApplicationId: number, withInitProcess?: boolean, checkVehicleDto?: CheckVehicleDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<RunCheckVehicleResponse>>;
    public apiCheckVehicleInternalInternalApplicationIdRunPost(internalApplicationId: number, withInitProcess?: boolean, checkVehicleDto?: CheckVehicleDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<RunCheckVehicleResponse>>;
    public apiCheckVehicleInternalInternalApplicationIdRunPost(internalApplicationId: number, withInitProcess?: boolean, checkVehicleDto?: CheckVehicleDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {




        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (withInitProcess !== undefined && withInitProcess !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>withInitProcess, 'withInitProcess');
        }

        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let localVarPath = `/api/CheckVehicle/internal/${this.configuration.encodeParam({name: "internalApplicationId", value: internalApplicationId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/run`;
        return this.httpClient.request<RunCheckVehicleResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: checkVehicleDto,
                params: localVarQueryParameters,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param ids 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCheckVehicleReRunManyPost(ids?: Array<number>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Array<ReRunManyCheckVehicleResponse>>;
    public apiCheckVehicleReRunManyPost(ids?: Array<number>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Array<ReRunManyCheckVehicleResponse>>>;
    public apiCheckVehicleReRunManyPost(ids?: Array<number>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Array<ReRunManyCheckVehicleResponse>>>;
    public apiCheckVehicleReRunManyPost(ids?: Array<number>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {


        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (ids) {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>ids, 'ids');
        }

        let localVarHeaders = this.defaultHeaders;

        // this.checkAuthentication();
        // HttpHeaders немутабильный, set вернет новый объект
        localVarHeaders = this.checkAuthentication(localVarHeaders, AuthorizationType.Bearer, AuthorizationHeader.Authorization)
         let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
          if (localVarHttpHeaderAcceptSelected === undefined) {
             // to determine the Accept header
                const httpHeaderAccepts: string[] = [
                     'text/plain',
                     'application/json',
                     'text/json'
              ];
               localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            }
           if (localVarHttpHeaderAcceptSelected !== undefined) {
                localVarHeaders = localVarHeaders.set(AcceptHeader, localVarHttpHeaderAcceptSelected);
           }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let localVarPath = `/api/CheckVehicle/reRunMany`;
        return this.httpClient.request<Array<ReRunManyCheckVehicleResponse>>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>this.getAcceptType(localVarHttpHeaderAcceptSelected),
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
