import { ChangeDetectorRef, ElementRef } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { Nullable } from '@lib-utils';

/**
 * Интерфейс, определяющий поле UI формы
 */
export interface FormField {
  id: string; // ID поля
  label: Nullable<string>; // Название поля
  control: Nullable<AbstractControl>; // Контрол, назначенный полю
  elementRef: ElementRef; // Элемент поля
  parentId?: string; // ID родительского контейнера
  cdr?: ChangeDetectorRef; // Ссылка на CDR компонента при необходимости ручного обновления
}

/**
 * Интерфейс, определяющий
 */
export interface FormContainer {
  id: string; // ID контейнера
  name: Nullable<string>; // Название контейнера
  parentId?: string; // ID родительского контейнера
  isExpanded: () => boolean; // Текущее состояние контейнера
  expand$: () => Observable<unknown>; // Раскрыть контейнер
}
