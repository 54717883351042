export const SecurityCheckResult = {
  CheckResult1: 'CheckResult1',
  CheckResult2: 'CheckResult2',
} as const;

export type SecurityCheckResult = (typeof SecurityCheckResult)[keyof typeof SecurityCheckResult];

export const SecurityCheckResultMap: Record<SecurityCheckResult, string> = {
  [SecurityCheckResult.CheckResult1]: 'Результат проверки СБ 1',
  [SecurityCheckResult.CheckResult2]: 'Результат проверки СБ 2',
};
