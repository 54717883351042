import { Nullable } from '@lib-utils';

export interface RewardRecepientDto {
  id?: Nullable<number>;
  name?: Nullable<string>;
  bank?: Nullable<string>;
  inn?: Nullable<string>;
  bic?: Nullable<string>;
  accountNumber?: Nullable<string>;
}
