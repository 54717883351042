/**
 * Fx.Spr
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PropertyAttributes = 'None' | 'SpecialName' | 'RTSpecialName' | 'HasDefault' | 'Reserved2' | 'Reserved3' | 'Reserved4' | 'ReservedMask';

export const PropertyAttributes = {
    None: 'None' as PropertyAttributes,
    SpecialName: 'SpecialName' as PropertyAttributes,
    RtSpecialName: 'RTSpecialName' as PropertyAttributes,
    HasDefault: 'HasDefault' as PropertyAttributes,
    Reserved2: 'Reserved2' as PropertyAttributes,
    Reserved3: 'Reserved3' as PropertyAttributes,
    Reserved4: 'Reserved4' as PropertyAttributes,
    ReservedMask: 'ReservedMask' as PropertyAttributes
};

