import { Nullable } from '@lib-utils';

export interface AutoRiskAdditionalCondition {
  id?: Nullable<number>;
  name: Nullable<string>;
  provider: Nullable<string>;
  sum: Nullable<number>;
  isIncludedInLoanBody: Nullable<boolean>;
}

export interface AutoRiskCalculatorDto {
  id?: Nullable<number>;

  creditSum: Nullable<number>;
  creditSumMax: Nullable<number>;
  initialPayment: Nullable<number>;
  initialPaymentMinPercent: Nullable<number>;
  monthlyPayment: Nullable<number>;
  monthlyPaymentMax: Nullable<number>;
  period: Nullable<number>;
  vehicleSum: Nullable<number>;
  productRate: Nullable<number>;

  programName: Nullable<string>;
  proofOfIncome: Nullable<string>;

  additionalConditions: Nullable<AutoRiskAdditionalCondition[]>;
}
