import { ColDef, GridOptions } from '@ag-grid-community/core';
import { TuiAppearance } from '@taiga-ui/core';
import { Observable } from 'rxjs';
import { Nullable } from '@lib-utils';
import {
  CustomActionAppearance,
  dateTimeTextCellDef,
  exactWidth,
  getActionCellDef,
  getProgressCellDef,
  tagCellDef,
} from '@lib-widgets/grid';
import { ApplicationListItemDto } from '@lib-auto/api';
import { ApplicationListStatusClassMap } from '@lib-auto/datasource';
import { ApplicationModules } from './application-modules';
import { formatApplicationId } from './format-application-id';

export interface AutoGridCallbacks {
  takeToWork: (id: Nullable<number>) => void | Observable<unknown>;
}

export const cabinetColumnsDesktop: Record<ApplicationModules, ColDef<ApplicationListItemDto>[]> = {
  [ApplicationModules.Applications]: [
    { colId: 'id', type: 'idLink', ...exactWidth(120) },
    { colId: 'createdOn', type: 'createdOn', ...exactWidth(180) },
    { colId: 'client', type: 'client', minWidth: 180, initialFlex: 1 },
    { colId: 'status', type: 'statusTag', minWidth: 260 },
    { colId: 'updatedOn', type: 'updatedOn', ...exactWidth(180) },
    { colId: 'manager', type: 'manager', minWidth: 180 },
    { colId: 'progress', type: 'progress', ...exactWidth(100) },
    { type: 'orderHistoryAction' },
  ],
  [ApplicationModules.Risks]: [
    { colId: 'id', type: 'idLink', ...exactWidth(120) },
    { colId: 'createdOn', type: 'createdOn', ...exactWidth(180) },
    { colId: 'client', type: 'client', minWidth: 180, initialFlex: 1 },
    { colId: 'status', type: 'statusTag', minWidth: 260 },
    { colId: 'updatedOn', type: 'updatedOn', ...exactWidth(180) },
    { colId: 'manager', type: 'manager', minWidth: 180 },
    // { type: 'rejectionCode', minWidth: 80, width: 130 },
  ],
  [ApplicationModules.Inspector]: [
    { colId: 'id', type: 'idText', ...exactWidth(100) },
    { colId: 'createdOn', type: 'createdOn', ...exactWidth(180) },
    { colId: 'client', type: 'client', minWidth: 180, initialFlex: 1 },
    { colId: 'status', type: 'statusTag', minWidth: 260 },
    { colId: 'updatedOn', type: 'updatedOn', ...exactWidth(180) },
    { colId: 'manager', type: 'manager', minWidth: 180 },
    { type: 'inspectorActions' },
  ],
  [ApplicationModules.Admin]: [],
};

const cabinetColumnsMobile: Record<ApplicationModules, ColDef<ApplicationListItemDto>[]> = {
  [ApplicationModules.Applications]: [
    { colId: 'id', type: 'idLink', resizable: false, suppressMovable: true, ...exactWidth(50) },
    { colId: 'client', type: 'client', resizable: false, suppressMovable: true, ...exactWidth(100) },
    { colId: 'status', type: 'statusText', resizable: false, suppressMovable: true, ...exactWidth(101) },
    { colId: 'updatedOn', type: 'updatedOn', resizable: false, suppressMovable: true, ...exactWidth(75) },
    { colId: 'createdOn', type: 'createdOn', resizable: false, suppressMovable: true, ...exactWidth(75) },
    { colId: 'manager', type: 'manager', resizable: false, suppressMovable: true, initialFlex: 1 },
    { colId: 'progress', type: 'progress', resizable: false, suppressMovable: true, ...exactWidth(34) },
    {
      type: 'orderHistoryAction',
      resizable: false,
      suppressMovable: true,
      ...exactWidth(34),
      cellStyle: { padding: '0 2px' },
    },
  ],
  [ApplicationModules.Risks]: [],
  [ApplicationModules.Inspector]: [],
  [ApplicationModules.Admin]: [],
};

export const getAutoGridOptions = (
  module: ApplicationModules,
  isMobile: Nullable<boolean>,
  callbacks: Partial<AutoGridCallbacks> = {},
): GridOptions<ApplicationListItemDto & { rejectionCode?: string }> => ({
  columnTypes: {
    idLink: {
      headerName: 'Номер',
      initialPinned: 'left',
      lockPinned: true,
      ...getActionCellDef<ApplicationListItemDto>({
        appearance: CustomActionAppearance.Link,
        getActionLabel: (data) => formatApplicationId(data?.id),
        getLink: (data) => (data?.id ? [data.id] : undefined),
      }),
      sortable: true,
      initialSort: 'desc',
      valueFormatter: ({ data }) => formatApplicationId(data?.id),
    },
    idText: {
      field: 'id',
      initialPinned: 'left',
      headerName: 'Номер',
      valueFormatter: ({ data }) => formatApplicationId(data?.id),
    },
    createdOn: {
      field: 'createdOn',
      headerName: 'Создана',
      sortable: true,
      ...dateTimeTextCellDef,
    },
    updatedOn: {
      field: 'updatedOn',
      headerName: 'Дата обновления',
      sortable: true,
      ...dateTimeTextCellDef,
    },
    client: {
      field: 'client.name',
      headerName: 'Клиент',
      sortable: true,
    },
    statusTag: {
      field: 'statusName',
      headerName: 'Статус',
      sortable: true,
      ...tagCellDef<ApplicationListItemDto>({
        styles: {
          'background-color': '#ebebeb',
          color: '#44475d',
        },
        getDefaultTagClass: ({ data }) => (data?.statusType && ApplicationListStatusClassMap[data.statusType]) ?? '',
        labelMap: null,
        valueAsLabel: true,
      }),
    },
    statusText: {
      field: 'statusName',
      headerName: 'Статус',
      sortable: true,
      cellClass: ({ data }) => [
        'text-center',
        (data?.statusType && ApplicationListStatusClassMap[data.statusType]) ?? '',
      ],
    },
    manager: {
      field: 'creator.fullName',
      headerName: 'Менеджер',
      sortable: true,
    },
    progress: {
      field: 'completedStagesNumber',
      headerName: 'Прогресс',
      sortable: false,
      ...getProgressCellDef<ApplicationListItemDto>({
        getCurrentValue: (data) => data?.completedStagesNumber ?? 0,
        getMaxValue: (data) => data?.allStagesNumber ?? 0,
      }),
    },
    rejectionCode: {
      field: 'rejectionCode',
      headerName: 'Код отказа',
      sortable: true,
    },
    orderHistoryAction: {
      headerName: '',
      initialPinned: 'right',
      ...getActionCellDef<ApplicationListItemDto>({
        icon: 'tuiIconClock',
        hint: 'История заявки',
        getLink: (data) => (data?.id ? [data.id + '/history'] : undefined),
      }),
    },
    inspectorActions: {
      headerName: 'Действия',
      initialPinned: 'right',
      ...getActionCellDef<ApplicationListItemDto>({
        actionLabel: 'Взять в работу',
        // TODO: убрать мок
        getAppearance: (data) =>
          data?.statusName === 'На одобрении' ? TuiAppearance.Flat : CustomActionAppearance.Hidden,
        getAction$: (data) => () => callbacks?.takeToWork?.(data?.id),
      }),
    },
  },
  columnDefs: isMobile ? cabinetColumnsMobile[module] : cabinetColumnsDesktop[module],
  sortingOrder: ['asc', 'desc'],
});

export const getAutoGridColumnDefs = (
  module: ApplicationModules,
  isMobile: Nullable<boolean>,
): ColDef<ApplicationListItemDto>[] => (isMobile ? cabinetColumnsMobile[module] : cabinetColumnsDesktop[module]);
