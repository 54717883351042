import { CreditProductType, VehicleMakeDto } from '@lib-auto/api';
import { AdminStatus, AutoAgentDto, AutoDealerRequisitesDto } from '../models';

export interface AutoDealerShowroomDto {
  id?: number;
  name?: string;
  issue?: string;
  address?: string;
  status?: AdminStatus;
  departmentId?: number;
  region?: string;
  cluster?: string;
  emailContacts?: string[];
  creditProductTypes?: CreditProductType[];
  makes?: VehicleMakeDto[];
  insurance?: string[];
  enableSms?: boolean;
  requisites?: AutoDealerRequisitesDto[];
  agents?: AutoAgentDto[];
  agentsKB?: AutoAgentDto[];
}
export const autoShowroomKeyMap: Partial<{ [K in keyof AutoDealerShowroomDto]: string }> = {
  address: 'Адрес',
};

//#region AutoShowroomInsurance

export const AutoShowroomInsurance = {
  Renaissance: 'Renaissance',
  Telemedicine: 'Telemedicine',
} as const;

export type AutoShowroomInsurance = (typeof AutoShowroomInsurance)[keyof typeof AutoShowroomInsurance];

export const AutoShowroomInsuranceMap: Record<AutoShowroomInsurance, string> = {
  [AutoShowroomInsurance.Renaissance]: 'Ренессанс 2-3 риска',
  [AutoShowroomInsurance.Telemedicine]: 'Телемедицина',
};

//#endregion AutoShowroomInsurance
