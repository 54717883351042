/**
 * Fx.Spr
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * <p>Type: <b>FxAuto.Spr.Domain.Enums.VerificationTaskType</b></p><p>Members:</p> <ul> </ul> 
 */
export type VerificationTaskType = 'Borrower' | 'Coworker' | 'Employer' | 'Living' | 'Registration' | 'Spouse' | 'Ndfl';

export const VerificationTaskType = {
    Borrower: 'Borrower' as VerificationTaskType,
    Coworker: 'Coworker' as VerificationTaskType,
    Employer: 'Employer' as VerificationTaskType,
    Living: 'Living' as VerificationTaskType,
    Registration: 'Registration' as VerificationTaskType,
    Spouse: 'Spouse' as VerificationTaskType,
    Ndfl: 'Ndfl' as VerificationTaskType
};

