/**
 * FxAuto.Reporting.Web
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ApplicationStatusGroup = 'Draft' | 'DecisionAwaiting' | 'RevisionRequired' | 'Approved' | 'Deal' | 'LoanIssued' | 'Outdated' | 'Declined' | 'LoanPaid';

export const ApplicationStatusGroup = {
    Draft: 'Draft' as ApplicationStatusGroup,
    DecisionAwaiting: 'DecisionAwaiting' as ApplicationStatusGroup,
    RevisionRequired: 'RevisionRequired' as ApplicationStatusGroup,
    Approved: 'Approved' as ApplicationStatusGroup,
    Deal: 'Deal' as ApplicationStatusGroup,
    LoanIssued: 'LoanIssued' as ApplicationStatusGroup,
    Outdated: 'Outdated' as ApplicationStatusGroup,
    Declined: 'Declined' as ApplicationStatusGroup,
    LoanPaid: 'LoanPaid' as ApplicationStatusGroup
};

