export const AutoPhoneVerificationType = {
  Borrower: 'borrower',
  Wife: 'wife',
  Employer: 'employer',
} as const;

export const AutoPhoneVerificationStatus = {
  NoAnswer: 'NoAnswer',
  Success: 'Success',
} as const;

export const AutoPhoneVerificationStatusMap: Record<AutoPhoneVerificationStatus, string> = {
  [AutoPhoneVerificationStatus.NoAnswer]: 'Не отвечает',
  [AutoPhoneVerificationStatus.Success]: 'Успешный дозвон',
};

export type AutoPhoneVerificationType = (typeof AutoPhoneVerificationType)[keyof typeof AutoPhoneVerificationType];
export type AutoPhoneVerificationStatus =
  (typeof AutoPhoneVerificationStatus)[keyof typeof AutoPhoneVerificationStatus];

export interface AutoRiskPhoneVerificationDetailsDto {
  type: AutoPhoneVerificationType;
  status: AutoPhoneVerificationStatus;
  createDate: string;
  startDate: string;
  endDate: string;
  managerFullName: string;
  conversation: {
    questionId: number;
    question: string;
    answerId?: number;
    answer?: string;
    score: number;
  }[];
}

export interface AutoRiskPhoneVerificationDto {
  verificationLevel: string;
  calls: AutoRiskPhoneVerificationDetailsDto[];
}
