/**
 * Fx.Spr
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CustomAttributeData } from './customAttributeData';
import { Type } from './type';
import { MemberInfo } from './memberInfo';
import { ParameterAttributes } from './parameterAttributes';


export interface ParameterInfo { 
    attributes?: ParameterAttributes;
    member?: MemberInfo;
    readonly name?: string | null;
    parameterType?: Type;
    readonly position?: number;
    readonly isIn?: boolean;
    readonly isLcid?: boolean;
    readonly isOptional?: boolean;
    readonly isOut?: boolean;
    readonly isRetval?: boolean;
    readonly defaultValue?: any | null;
    readonly rawDefaultValue?: any | null;
    readonly hasDefaultValue?: boolean;
    readonly customAttributes?: Array<CustomAttributeData> | null;
    readonly metadataToken?: number;
}

