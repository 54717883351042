/**
 * Fx.Spr
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AnswerResultInfo } from './answerResultInfo';
import { CallStatus } from './callStatus';
import { VerificationTaskType } from './verificationTaskType';


export interface VerificationTaskResultInfo { 
    id?: number;
    externalId?: string;
    type?: VerificationTaskType;
    status?: CallStatus;
    statusScore?: number;
    answers?: Array<AnswerResultInfo> | null;
    count?: number;
    countScore?: number;
}

