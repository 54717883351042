/**
 * FxAuto.Lookup.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AdditionalProductTypeDto } from './additionalProductTypeDto';
import { IdNameDto } from './idNameDto';


export interface CalculatorOptionsCommonDto { 
    showrooms?: Array<IdNameDto> | null;
    additionalProductTypes?: Array<AdditionalProductTypeDto> | null;
    calculationResultTypeNames?: { [key: string]: string; } | null;
    defaultVehiclePrice?: number;
    defaultDownPaymentPercent?: number;
    defaultTerm?: number;
}

