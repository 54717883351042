/**
 * FxAuto.BFF.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ApplicationDecisionStage = 'None' | 'VerificationRequesting' | 'VerificationAwaiting' | 'VerificationIssues' | 'DecisionAwaiting' | 'DecisionRequestPreparationFailed' | 'DecisionRequesting' | 'DecisionRequestFailed' | 'DecisionReceived';

export const ApplicationDecisionStage = {
    None: 'None' as ApplicationDecisionStage,
    VerificationRequesting: 'VerificationRequesting' as ApplicationDecisionStage,
    VerificationAwaiting: 'VerificationAwaiting' as ApplicationDecisionStage,
    VerificationIssues: 'VerificationIssues' as ApplicationDecisionStage,
    DecisionAwaiting: 'DecisionAwaiting' as ApplicationDecisionStage,
    DecisionRequestPreparationFailed: 'DecisionRequestPreparationFailed' as ApplicationDecisionStage,
    DecisionRequesting: 'DecisionRequesting' as ApplicationDecisionStage,
    DecisionRequestFailed: 'DecisionRequestFailed' as ApplicationDecisionStage,
    DecisionReceived: 'DecisionReceived' as ApplicationDecisionStage
};

