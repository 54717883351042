/**
 * Fx.Spr
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Сведения о залогах.
 */
export interface Collateral { 
    /**
     * Код предмета залога.
     */
    category?: string | null;
    /**
     * Идентификационный код предмета залога.
     */
    collateralId?: string | null;
    /**
     * Дата заключения договора залога.
     */
    openDate?: string | null;
    /**
     * Стоимость предмета залога.
     */
    value?: number;
    /**
     * Валюта стоимости предмета залога.
     */
    currency?: string | null;
    /**
     * Дата проведения оценки предмета залога.
     */
    assessmentDate?: string | null;
    /**
     * Признак иного обременения предмета залога.
     */
    isOtherBurden?: number;
    /**
     * Дата прекращения залога согласно договору.
     */
    endDate?: string | null;
    /**
     * Дата фактического прекращения залога.
     */
    factEndDate?: string | null;
    /**
     * Код причины прекращения залога.
     */
    terminationReason?: number;
    /**
     * Вид стоимости предмета залога.
     */
    valueType?: number;
    /**
     * Сумма обязательств по всем договорам, исполнение которых обеспечено предметом залога.
     */
    dealsAmount?: number;
    /**
     * Количество договоров, исполнение обязательств по которым обеспечено предметом залога.
     */
    dealsCount?: number;
}

