/**
 * FxAuto.BFF.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ApplicationStageType = 'GeneralInfo' | 'PassportData' | 'IncomeData' | 'AdditionalInfo' | 'LoanData' | 'DocumentsCollection' | 'Decision' | 'DealNegotiation' | 'Deal' | 'LoanIssued';

export const ApplicationStageType = {
    GeneralInfo: 'GeneralInfo' as ApplicationStageType,
    PassportData: 'PassportData' as ApplicationStageType,
    IncomeData: 'IncomeData' as ApplicationStageType,
    AdditionalInfo: 'AdditionalInfo' as ApplicationStageType,
    LoanData: 'LoanData' as ApplicationStageType,
    DocumentsCollection: 'DocumentsCollection' as ApplicationStageType,
    Decision: 'Decision' as ApplicationStageType,
    DealNegotiation: 'DealNegotiation' as ApplicationStageType,
    Deal: 'Deal' as ApplicationStageType,
    LoanIssued: 'LoanIssued' as ApplicationStageType
};

