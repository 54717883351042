/**
 * FxAuto.BFF.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Severity } from './severity';


export interface ValidationFailure { 
    propertyName?: string | null;
    errorMessage?: string | null;
    attemptedValue?: any | null;
    customState?: any | null;
    severity?: Severity;
    errorCode?: string | null;
    formattedMessagePlaceholderValues?: { [key: string]: any | null; } | null;
}
export namespace ValidationFailure {
}


