/**
 * Fx.Spr
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Extension } from './extension';
import { PastdueArrear } from './pastdueArrear';
import { Amendment } from './amendment';
import { Arrear } from './arrear';
import { DateOnly } from '@lib-utils';
import { Collateral } from './collateral';
import { DueArrear } from './dueArrear';
import { SecurityRepay } from './securityRepay';


/**
 * Данные по кредитному делу.
 */
export interface Loan { 
    /**
     * Уникальный идентификатор кредитного договора.
     */
    uuid?: string | null;
    /**
     * Дата подтверждения информации по счету.
     */
    infConfirmDate?: DateOnly | null;
    /**
     * Тип отношения к договору.
     */
    relationship?: string | null;
    /**
     * Код типа договора.
     */
    type?: string | null;
    /**
     * Статус договора.
     */
    status?: string | null;
    /**
     * Дата открытия договора.
     */
    openDate?: DateOnly | null;
    /**
     * Валюта договора (ISO буквенный код валюты).
     */
    currency?: string | null;
    /**
     * Кредитный лимит. Сумма в рублях по курсу ЦБ РФ.
     */
    creditLimit?: number;
    /**
     * Полная стоимость кредита (%/год).
     */
    creditCostRate?: number;
    /**
     * Оставшаяся непогашенная задолженность. Сумма в рублях по курсу ЦБ РФ.
     */
    outstanding?: number;
    /**
     * Дата закрытия счета (фактическая).
     */
    factCloseDate?: DateOnly | null;
    /**
     * Дата финального платежа (плановая).
     */
    finalPmtDate?: DateOnly | null;
    /**
     * Текущая просроченная задолженность. Сумма в рублях по курсу ЦБ РФ.
     */
    delqBalance?: number;
    /**
     * Дисциплина (своевременность) платежей.  <remarks>Расшифровка символов:  0 – Новый, оценка невозможна  X – Нет информации  1 – Оплата без просрочек  E - Просрочка от 1 до 7 дней  A – Просрочка от 1 до 29 дней  F - Просрочка от 8 до 29 дней  2 – Просрочка от 30 до 59 дней  3 – Просрочка от 60 до 89 дней  4 – Просрочка от 90 до 119 дней  5 – Просрочка более 120 дней  7 – Регулярные консолидированные платежи  8 – Погашение по кредиту с использованием залога  9 – Безнадёжный долг/ передано на взыскание/ пропущенный платеж  </remarks>
     */
    pmtString84M?: string | null;
    /**
     * Дата начала строки платежной дисциплины (PMT_STRING_84M).
     */
    pmtStringStart?: DateOnly | null;
    /**
     * Вид деятельности источника информации о кредитном обязательстве.
     */
    businessCategory?: number;
    /**
     * Источники данных.
     */
    infoSources?: Array<string> | null;
    /**
     * Признак своего кредита.
     */
    isOwn?: number;
    /**
     * Срочная задолженность по основному долгу на дату последнего платежа.
     */
    principalOutstanding?: number;
    /**
     * Просрочка по основному долгу на дату последнего платежа.
     */
    principalPastDue?: number;
    /**
     * Срочная задолженность по процентам на дату последнего платежа.
     */
    intOutstanding?: number;
    /**
     * Просрочка по процентам на дату последнего платежа.
     */
    intPastDue?: number;
    /**
     * Сумма иных срочных задолженностей на дату последнего платежа.
     */
    otherOutstanding?: number;
    /**
     * Сумма иных просрочек на дату последнего платежа.
     */
    otherPastDue?: number;
    /**
     * Сведения о задолженности.
     */
    arrears?: Array<Arrear> | null;
    /**
     * Сведения о срочной задолженности.
     */
    dueArrears?: Array<DueArrear> | null;
    /**
     * Блок с информацией о просроченных задолженностях.
     */
    pastdueArrears?: Array<PastdueArrear> | null;
    /**
     * Блок с информацией о просроченных задолженностях.
     */
    amendments?: Array<Amendment> | null;
    /**
     * Дата последнего платежа.
     */
    lastPaymentDate?: string | null;
    /**
     * Размер следующего платежа. Сумма в рублях по курсу ЦБ РФ.
     */
    nextPmt?: number;
    /**
     * Процентная ставка по кредиту.
     */
    interestRate?: number;
    /**
     * Дата расчета полной стоимости кредита (займа).
     */
    creditCostDate?: string | null;
    /**
     * Полная стоимость займа.
     */
    totalAmountOfFinance?: number;
    /**
     * Индикатор спорности.
     */
    disputeFlag?: string | null;
    /**
     * Признак оплаты залогом.
     */
    collateralCode?: string | null;
    /**
     * Сведения о залогах.
     */
    collaterals?: Array<Collateral> | null;
    /**
     * Сведения о погашениях требований кредитора по обязательству за счет обеспечения.
     */
    securityRepays?: Array<SecurityRepay> | null;
    /**
     * Рассчитанный ежемесячный платёж.
     */
    estNextPmt?: number;
    /**
     * Отношение Кредитный лимит/ Первоначальный размер лимита (в рублях).
     */
    curToBaseLimit?: number;
    /**
     * Максимальный ежемесячный платеж. Сумма в рублях по курсу ЦБ РФ.
     */
    maxPayment?: number;
    /**
     * Количество просрочек от 1 до 7 дней.
     */
    ttlDelq7?: number;
    /**
     * Количество просрочек от 8 до 29 дней.
     */
    ttlDelq829?: number;
    /**
     * Общее число просрочек до 5 дней.
     */
    ttlDelq5?: number;
    /**
     * Общее число просрочек от 5 до 29 дней.
     */
    ttlDelq529?: number;
    /**
     * Общее число просрочек от 30 до 59 дней.
     */
    ttlDelq3059?: number;
    /**
     * Общее число просрочек от 60 до 89 дней.
     */
    ttlDelq6089?: number;
    /**
     * Количество просрочек до 30 дней.
     */
    ttlDelq30?: number;
    /**
     * Количество просрочек 90+ дней.
     */
    ttlDelq90Plus?: number;
    /**
     * Максимальный объем просроченной задолженности. Сумма в рублях по курсу ЦБ РФ.
     */
    maxDelqBalance?: number;
    /**
     * Текущее количество дней просрочки.
     */
    currentDelq?: string | null;
    /**
     * Общая выплаченная сумма, включая сумму основного долга, проценты, пени и штрафы.
     */
    currBalanceAmt?: number;
    /**
     * Рассчитанная общая выплаченная сумма, включая сумму основного долга, проценты, пени и штрафы.
     */
    estCurrBalanceAmt?: number;
    /**
     * Код типа сделки, заполняется по справочнику.
     */
    categoryCode?: number;
    /**
     * Вид займа по ЦБ.
     */
    cbTypeCode?: number;
    /**
     * Код цели займа.
     */
    purposeCode?: string | null;
    /**
     * Признак потребительского кредита.
     */
    isConsumerLoan?: number;
    /**
     * Признак использования платежной карты.
     */
    isCardUsed?: number;
    /**
     * Признак возникновения обязательства в результате новация.
     */
    isNovation?: number;
    /**
     * Сумма обеспечиваемого обязательства.
     */
    ensuredAmount?: number;
    /**
     * Число солидарных должников.
     */
    coborrowersCount?: number;
    /**
     * Сумма ближайшего следующего платежа по основному долгу.
     */
    nextPmtPrincipal?: number;
    /**
     * Дата ближайшего следующего платежа по основному долгу.
     */
    nextPmtPrincipalDate?: string | null;
    /**
     * Сумма ближайшего следующего платежа по процентам.
     */
    nextPmtInterest?: number;
    /**
     * Дата ближайшего следующего платежа по процентам.
     */
    nextPmtInterestDate?: string | null;
    /**
     * Сумма минимального платежа по кредитной карте.
     */
    creditCardMinPmt?: number;
    /**
     * Дата начала беспроцентного периода.
     */
    graceStartDate?: string | null;
    /**
     * Дата окончания беспроцентного периода.
     */
    graceEndDate?: string | null;
    /**
     * Дата окончания срока уплаты процентов.
     */
    interestPmtFinalDate?: string | null;
    /**
     * FundTransferDate
     */
    fundTransferDate?: string | null;
    /**
     * Код основания прекращения обязательства.
     */
    terminationReason?: number;
    /**
     * Сумма, подлежащая возмещению.
     */
    principalRepaymentAmount?: number;
    /**
     * Сумма, выплаченная принципалом.
     */
    principalRepaidAmount?: number;
    /**
     * Признак соблюдения порядка возмещения.
     */
    isPrincipalRepaid?: number;
    /**
     * Сведения об учете обязательства.
     */
    isAccounting?: number;
    /**
     * Код вида кредитора – заимодавца.
     */
    lenderTypeCode?: number;
    /**
     * Признак возникновения обязательства в результате получения части прав кредитора от другого лица.
     */
    obtainPartial?: number;
    /**
     * Код типа кредитной линии.
     */
    creditLineCode?: number;
    /**
     * Признак плавающей (переменной) процентной ставки.
     */
    interestRateFloat?: number;
    /**
     * Признак частичной передачи прав кредитора другому лицу.
     */
    partialTransfer?: number;
    /**
     * УИд сделки, по которой права кредитора частично переданы другому лицу.
     */
    obtainPartialUuid?: string | null;
    /**
     * Дата возникновения обязательства субъекта.
     */
    commitDate?: string | null;
    /**
     * Валюта обеспечиваемого обязательства.
     */
    securedDealCurrency?: string | null;
    /**
     * Код типа обеспечиваемого обязательства.
     */
    commitCode?: number;
    /**
     * Дата расчета суммы и валюты обязательства.
     */
    calculationDate?: string | null;
    /**
     * УИд сделки, в результате которой возникло обеспечиваемое обязательство.
     */
    securedDealUuid?: string | null;
    /**
     * Порядковый номер транша.
     */
    trancheNum?: number;
    /**
     * Дата расчета.
     */
    principalCalcDate?: string | null;
    /**
     * Сумма обязательства, учтенная на внебалансовых счетах.
     */
    offBalanceAmt?: number;
    /**
     * Признак льготного финансирования с государственной поддержкой.
     */
    stateSupport?: number;
    /**
     * Информация о программе государственной поддержки.
     */
    stateSupportInfo?: string | null;
    extension?: Extension;
}

