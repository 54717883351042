/**
 * FxAuto.BFF.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ClientDataReadinessDto } from './clientDataReadinessDto';
import { ApplicationDataReadinessDto } from './applicationDataReadinessDto';
import { RequiredDocumentDto } from './requiredDocumentDto';


export interface ApplicationReadinessDto { 
    readonly isReady?: boolean;
    readonly areDocumentsReady?: boolean;
    applicationData?: ApplicationDataReadinessDto;
    clientData?: ClientDataReadinessDto;
    documentsRequired?: Array<RequiredDocumentDto> | null;
    readonly isLoanDataReady?: boolean;
    readonly isAdditionalInfoReady?: boolean;
    readonly isPassportDataReady?: boolean;
    readonly isIncomeDataReady?: boolean;
}

