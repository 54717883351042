import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { AutoConfiguration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { AdminApplicationApiService } from './api/adminApplication.service';
import { AdminImportApiService } from './api/adminImport.service';
import { ApplicationApiService } from './api/application.service';
import { ApplicationCreationApiService } from './api/applicationCreation.service';
import { ApplicationDecisionApiService } from './api/applicationDecision.service';
import { ApplicationDocumentApiService } from './api/applicationDocument.service';
import { ApplicationFileApiService } from './api/applicationFile.service';
import { ApplicationHistoryApiService } from './api/applicationHistory.service';
import { ApplicationListApiService } from './api/applicationList.service';
import { CallbackApiService } from './api/callback.service';
import { DealApiService } from './api/deal.service';
import { DealNegotiationApiService } from './api/dealNegotiation.service';
import { LoanIssuedApiService } from './api/loanIssued.service';
import { LookupAddressApiService } from './api/lookupAddress.service';
import { LookupCalculatorApiService } from './api/lookupCalculator.service';
import { LookupOrganizationApiService } from './api/lookupOrganization.service';
import { LookupVehicleApiService } from './api/lookupVehicle.service';
import { TemplateApiService } from './api/template.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    AdminApplicationApiService,
    AdminImportApiService,
    ApplicationApiService,
    ApplicationCreationApiService,
    ApplicationDecisionApiService,
    ApplicationDocumentApiService,
    ApplicationFileApiService,
    ApplicationHistoryApiService,
    ApplicationListApiService,
    CallbackApiService,
    DealApiService,
    DealNegotiationApiService,
    LoanIssuedApiService,
    LookupAddressApiService,
    LookupCalculatorApiService,
    LookupOrganizationApiService,
    LookupVehicleApiService,
    TemplateApiService ]
})
export class FxAutoApiModule {
    public static forRoot(configurationFactory: () => AutoConfiguration): ModuleWithProviders<FxAutoApiModule> {
        return {
            ngModule: FxAutoApiModule,
            providers: [ { provide: AutoConfiguration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: FxAutoApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('FxAutoApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
