import { FormControl, FormGroup, Validators } from '@angular/forms';
import fromPairs from 'lodash-es/fromPairs';
import { FileInfo, FormControlsOf, Nullable } from '@lib-utils';
import { RequiredDocumentDto } from '@lib-auto/api';
import { DocumentGroupDto, DocumentsFormDto } from '@lib-auto/datasource';

export function generateDocumentGroup(requiredDocuments: Nullable<RequiredDocumentDto[]>): DocumentGroupDto {
  const form = new FormGroup<FormControlsOf<DocumentsFormDto>>(
    fromPairs(requiredDocuments?.map(({ name }) => [name, new FormControl<FileInfo<number>[] | null>(null)])),
  );
  const documentControls =
    requiredDocuments?.map((document) => {
      const control = form.controls[document.name!];
      control?.setValidators(
        document?.from ?? 0 > 0 ? [Validators.required, Validators.minLength(document?.from!)] : [],
      );
      if (document?.isReadOnly) control?.disable();
      return {
        ...document,
        control,
      };
    }) || [];
  return {
    form,
    documentControls,
  };
}
