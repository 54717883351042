/**
 * FxAuto.BFF.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type TemplateType = 'IndividualConditions' | 'PaymentSchedule' | 'LoanApplication' | 'ComprehensiveServiceAgreement' | 'TransferForVehicle' | 'ClientMemo' | 'AdditionalProductTransferApplication' | 'AdditionalProductContract' | 'BorrowerMemoLoanRepayment' | 'RefusalNotice' | 'SOPDThirdParties' | 'SOPDAndRequest' | 'GuaranteeLetter';

export const TemplateType = {
    IndividualConditions: 'IndividualConditions' as TemplateType,
    PaymentSchedule: 'PaymentSchedule' as TemplateType,
    LoanApplication: 'LoanApplication' as TemplateType,
    ComprehensiveServiceAgreement: 'ComprehensiveServiceAgreement' as TemplateType,
    TransferForVehicle: 'TransferForVehicle' as TemplateType,
    ClientMemo: 'ClientMemo' as TemplateType,
    AdditionalProductTransferApplication: 'AdditionalProductTransferApplication' as TemplateType,
    AdditionalProductContract: 'AdditionalProductContract' as TemplateType,
    BorrowerMemoLoanRepayment: 'BorrowerMemoLoanRepayment' as TemplateType,
    RefusalNotice: 'RefusalNotice' as TemplateType,
    SopdThirdParties: 'SOPDThirdParties' as TemplateType,
    SopdAndRequest: 'SOPDAndRequest' as TemplateType,
    GuaranteeLetter: 'GuaranteeLetter' as TemplateType
};

