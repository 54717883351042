/**
 * Fx.Spr
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AnswerType } from './answerType';


export interface AnswerResultInfo { 
    questionId?: number;
    questionText?: string | null;
    answerId?: number | null;
    answerText?: string | null;
    answerType?: AnswerType;
    answerScore?: number | null;
}

