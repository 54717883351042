import {
  AutoPhoneVerificationStatus,
  AutoPhoneVerificationType,
  AutoRiskPhoneVerificationDto,
} from '../models/auto-risk-phone-verification';

export const autoRiskPhoneVerificationMock: AutoRiskPhoneVerificationDto = {
  verificationLevel: 'A3',
  calls: [
    {
      createDate: '2022-11-06T07:20:26.637855Z',
      startDate: '2022-11-06T07:40:26.637855Z',
      endDate: '2022-11-06T09:20:26.637855Z',
      managerFullName: 'Сляднева Екатерина',
      status: AutoPhoneVerificationStatus.NoAnswer,
      type: AutoPhoneVerificationType.Borrower,
      conversation: [],
    },
    {
      createDate: '2022-11-06T07:20:26.637855Z',
      startDate: '2022-11-06T07:40:26.637855Z',
      endDate: '2022-11-06T09:20:26.637855Z',
      managerFullName: 'Сляднева Екатерина',
      status: AutoPhoneVerificationStatus.Success,
      type: AutoPhoneVerificationType.Borrower,
      conversation: [
        {
          questionId: 1222,
          question: 'Есть ли у вас непосредственные подчиненные? Если да, то сколько?',
          answerId: 5222,
          answer: 'Да. 10 человек',
          score: 50,
        },
        {
          questionId: 1223,
          question:
            'Верификатор, отметьте, если есть подозрение на мошенничество, или иные подозрительные действия, которые могут быть расценены как фрод или аналогичные нарушения ',
          answerId: 5223,
          answer: 'Признаков мошенничества не обнаружено',
          score: 50,
        },
        {
          questionId: 1224,
          question:
            'Назовите сумму вашего среднемесячного дохода, получаемого по основному месту работы. Можно округлить до тысяч.',
          answerId: 5224,
          answer: '134 000 руб.',
          score: 12,
        },
      ],
    },
    {
      createDate: '2022-11-06T07:20:26.637855Z',
      startDate: '2022-11-06T07:40:26.637855Z',
      endDate: '2022-11-06T09:20:26.637855Z',
      managerFullName: 'Сляднева Екатерина',
      status: AutoPhoneVerificationStatus.Success,
      type: AutoPhoneVerificationType.Wife,
      conversation: [
        {
          questionId: 1222,
          question: 'Есть ли у вас непосредственные подчиненные? Если да, то сколько?',
          answerId: 5222,
          answer: 'Да. 10 человек',
          score: 150,
        },
        {
          questionId: 1223,
          question:
            'Верификатор, отметьте, если есть подозрение на мошенничество, или иные подозрительные действия, которые могут быть расценены как фрод или аналогичные нарушения ',
          answerId: 5223,
          answer: 'Признаков мошенничества не обнаружено',
          score: 50,
        },
        {
          questionId: 1224,
          question:
            'Назовите сумму вашего среднемесячного дохода, получаемого по основному месту работы. Можно округлить до тысяч.',
          answerId: 5224,
          answer: '134 000 руб.',
          score: 12,
        },
      ],
    },
    {
      createDate: '2022-11-06T07:20:26.637855Z',
      startDate: '2022-11-06T07:40:26.637855Z',
      endDate: '2022-11-06T09:20:26.637855Z',
      managerFullName: 'Сляднева Екатерина',
      status: AutoPhoneVerificationStatus.NoAnswer,
      type: AutoPhoneVerificationType.Employer,
      conversation: [],
    },
  ],
};
