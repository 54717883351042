/**
 * Fx.Spr
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type EventAttributes = 'None' | 'SpecialName' | 'RTSpecialName';

export const EventAttributes = {
    None: 'None' as EventAttributes,
    SpecialName: 'SpecialName' as EventAttributes,
    RtSpecialName: 'RTSpecialName' as EventAttributes
};

