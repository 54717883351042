/**
 * FxAuto.BFF.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type VerificationDocumentType = 'Passport' | 'DriverLicense' | 'VehiclePassport' | 'VehicleInformationLetter' | 'Sopd' | 'PaymentInvoice' | 'PaymentReceipt' | 'SaleContractVehicle' | 'ClientPhoto' | 'LifeInsurance' | 'AdditionalServicesRefusal' | 'ApplicationAndConsentForm' | 'RoadAccidentAssistancePolicy' | 'ApplicationForJoiningDKO' | 'ApplicationForTransferOfFunds' | 'PaymentSchedule' | 'IndividualConditions' | 'PassportFull' | 'ApplicationForTransferForVehicle';

export const VerificationDocumentType = {
    Passport: 'Passport' as VerificationDocumentType,
    DriverLicense: 'DriverLicense' as VerificationDocumentType,
    VehiclePassport: 'VehiclePassport' as VerificationDocumentType,
    VehicleInformationLetter: 'VehicleInformationLetter' as VerificationDocumentType,
    Sopd: 'Sopd' as VerificationDocumentType,
    PaymentInvoice: 'PaymentInvoice' as VerificationDocumentType,
    PaymentReceipt: 'PaymentReceipt' as VerificationDocumentType,
    SaleContractVehicle: 'SaleContractVehicle' as VerificationDocumentType,
    ClientPhoto: 'ClientPhoto' as VerificationDocumentType,
    LifeInsurance: 'LifeInsurance' as VerificationDocumentType,
    AdditionalServicesRefusal: 'AdditionalServicesRefusal' as VerificationDocumentType,
    ApplicationAndConsentForm: 'ApplicationAndConsentForm' as VerificationDocumentType,
    RoadAccidentAssistancePolicy: 'RoadAccidentAssistancePolicy' as VerificationDocumentType,
    ApplicationForJoiningDko: 'ApplicationForJoiningDKO' as VerificationDocumentType,
    ApplicationForTransferOfFunds: 'ApplicationForTransferOfFunds' as VerificationDocumentType,
    PaymentSchedule: 'PaymentSchedule' as VerificationDocumentType,
    IndividualConditions: 'IndividualConditions' as VerificationDocumentType,
    PassportFull: 'PassportFull' as VerificationDocumentType,
    ApplicationForTransferForVehicle: 'ApplicationForTransferForVehicle' as VerificationDocumentType
};

