/**
 * Fx.Spr
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * <p>Type: <b>FxAuto.Spr.Domain.Enums.MaritalType</b></p><p>Members:</p> <ul> </ul> 
 */
export type MaritalType = 'Married' | 'CivilMarriage' | 'Single' | 'Divorced' | 'Widow';

export const MaritalType = {
    Married: 'Married' as MaritalType,
    CivilMarriage: 'CivilMarriage' as MaritalType,
    Single: 'Single' as MaritalType,
    Divorced: 'Divorced' as MaritalType,
    Widow: 'Widow' as MaritalType
};

