export const AutoRiskVehicleType = {
  Car: 'Car',
  Track: 'Track',
} as const;

export const AutoRiskEngineType = {
  GasolineAtmo: 'GasolineAtmo',
  GasolineTurbo: 'GasolineTurbo',
  DieselAtmo: 'DieselAtmo',
  DieselTurbo: 'DieselTurbo',
} as const;

export const AutoRiskVehicleTypeMap: Record<AutoRiskVehicleType, string> = {
  [AutoRiskVehicleType.Car]: 'Легковой автомобиль',
  [AutoRiskVehicleType.Track]: 'Грузовой автомобиль',
};

export const AutoRiskEngineTypeMap: Record<AutoRiskEngineType, string> = {
  [AutoRiskEngineType.GasolineAtmo]: 'Бензиновый, атм.',
  [AutoRiskEngineType.GasolineTurbo]: 'Бензиновый, турбо.',
  [AutoRiskEngineType.DieselAtmo]: 'Дизельный, атм.',
  [AutoRiskEngineType.DieselTurbo]: 'Дизельный, турбо.',
};

export type AutoRiskVehicleType = (typeof AutoRiskVehicleType)[keyof typeof AutoRiskVehicleType];
export type AutoRiskEngineType = (typeof AutoRiskEngineType)[keyof typeof AutoRiskEngineType];
