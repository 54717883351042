/**
 * FxAuto.BFF.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type FamilyStatusType = 'Married' | 'NotMarried' | 'Divorced' | 'Widow';

export const FamilyStatusType = {
    Married: 'Married' as FamilyStatusType,
    NotMarried: 'NotMarried' as FamilyStatusType,
    Divorced: 'Divorced' as FamilyStatusType,
    Widow: 'Widow' as FamilyStatusType
};

