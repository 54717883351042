/**
 * FxAuto.BFF.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SavePassportBaseDto } from './savePassportBaseDto';
import { SavePassportDto } from './savePassportDto';
import { ClientNameChangeDto } from './clientNameChangeDto';


export interface SavePassportDataDto { 
    passport?: SavePassportDto;
    previousPassports?: Array<SavePassportBaseDto> | null;
    previousName?: ClientNameChangeDto;
    registrationAddress?: string | null;
    currentAddress?: string | null;
    homePhone?: string | null;
}

