/**
 * Fx.Spr
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ParameterAttributes = 'None' | 'In' | 'Out' | 'Lcid' | 'Retval' | 'Optional' | 'HasDefault' | 'HasFieldMarshal' | 'Reserved3' | 'Reserved4' | 'ReservedMask';

export const ParameterAttributes = {
    None: 'None' as ParameterAttributes,
    In: 'In' as ParameterAttributes,
    Out: 'Out' as ParameterAttributes,
    Lcid: 'Lcid' as ParameterAttributes,
    Retval: 'Retval' as ParameterAttributes,
    Optional: 'Optional' as ParameterAttributes,
    HasDefault: 'HasDefault' as ParameterAttributes,
    HasFieldMarshal: 'HasFieldMarshal' as ParameterAttributes,
    Reserved3: 'Reserved3' as ParameterAttributes,
    Reserved4: 'Reserved4' as ParameterAttributes,
    ReservedMask: 'ReservedMask' as ParameterAttributes
};

