/**
 * Fx.Spr
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type FieldAttributes = 'PrivateScope' | 'Private' | 'FamANDAssem' | 'Assembly' | 'Family' | 'FamORAssem' | 'Public' | 'FieldAccessMask' | 'Static' | 'InitOnly' | 'Literal' | 'NotSerialized' | 'HasFieldRVA' | 'SpecialName' | 'RTSpecialName' | 'HasFieldMarshal' | 'PinvokeImpl' | 'HasDefault' | 'ReservedMask';

export const FieldAttributes = {
    PrivateScope: 'PrivateScope' as FieldAttributes,
    Private: 'Private' as FieldAttributes,
    FamAndAssem: 'FamANDAssem' as FieldAttributes,
    Assembly: 'Assembly' as FieldAttributes,
    Family: 'Family' as FieldAttributes,
    FamOrAssem: 'FamORAssem' as FieldAttributes,
    Public: 'Public' as FieldAttributes,
    FieldAccessMask: 'FieldAccessMask' as FieldAttributes,
    Static: 'Static' as FieldAttributes,
    InitOnly: 'InitOnly' as FieldAttributes,
    Literal: 'Literal' as FieldAttributes,
    NotSerialized: 'NotSerialized' as FieldAttributes,
    HasFieldRva: 'HasFieldRVA' as FieldAttributes,
    SpecialName: 'SpecialName' as FieldAttributes,
    RtSpecialName: 'RTSpecialName' as FieldAttributes,
    HasFieldMarshal: 'HasFieldMarshal' as FieldAttributes,
    PinvokeImpl: 'PinvokeImpl' as FieldAttributes,
    HasDefault: 'HasDefault' as FieldAttributes,
    ReservedMask: 'ReservedMask' as FieldAttributes
};

