/**
 * Fx.Spr
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Nbki } from './nbki';
import { Okb } from './okb';
import { ScoringEquifax } from './scoringEquifax';


export interface Scoring { 
    okb?: Okb;
    nbki?: Nbki;
    equifax?: ScoringEquifax;
    fraudApplicationPd?: number | null;
    fraudPd?: number | null;
    creditRiskApplicationPd?: number | null;
    creditRiskPd?: number | null;
}

