import omit from 'lodash-es/omit';
import { Nullable } from '@lib-utils';
import { PercentageInputMode } from '@lib-widgets/reactive-fields';
import { CalculateRequestDto } from '@lib-auto/api';
import { FeCalculateRequestDto } from '@lib-auto/datasource';

export function toCalculateRequest(value: Nullable<FeCalculateRequestDto>): CalculateRequestDto | undefined {
  return value
    ? {
        // Бэк принимает только один из параметров downPayment и downPaymentPercent
        ...(value?.downPaymentMode === PercentageInputMode.Number
          ? omit(value, 'downPaymentPercent')
          : omit(value, 'downPayment')),
        additionalProducts: value?.additionalProducts?.map(({ type, price, providerId }) => ({
          type,
          providerId,
          price: price ?? undefined,
        })),
      }
    : undefined;
}
