/**
 * Fx.Spr
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Модель статуса КБКИ.
 */
export interface QbchStatus { 
    /**
     * Номер ОГРН КБКИ.
     */
    qbchOgrn?: string | null;
    /**
     * Статус ответа из КБКИ.
     */
    status?: number;
    /**
     * Код ошибки.
     */
    errorCode?: number | null;
    /**
     * Описание ошибки.
     */
    errorDescription?: string | null;
}

