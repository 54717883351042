/**
 * FxAuto.BFF.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CalculationResultDto } from './calculationResultDto';
import { ClientDto } from './clientDto';
import { DateOnly } from '@lib-utils';
import { EmploymentType } from './employmentType';
import { ApplicationHistoryItemDto } from './applicationHistoryItemDto';
import { DateTime } from '@lib-utils';
import { OrganizationDto } from './organizationDto';


export interface ApplicationHistoryDto { 
    uid?: string;
    createdAt?: DateTime | null;
    createdBy?: string | null;
    showroomName?: string | null;
    statusName?: string | null;
    birthday?: DateOnly | null;
    registrationAddress?: string | null;
    historyItems?: Array<ApplicationHistoryItemDto> | null;
    client?: ClientDto;
    incomeEmploymentType?: EmploymentType;
    incomeOrganizationPhoneNumbers?: Array<string> | null;
    incomeOrganization?: OrganizationDto;
    calculationResult?: CalculationResultDto;
    vehicleDescription?: string | null;
    vehicleVin?: string | null;
    canRefuse?: boolean;
    canRollbackDeal?: boolean;
}
export namespace ApplicationHistoryDto {
}


