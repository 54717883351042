/**
 * Fx.Spr
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CreditReportSourceType } from './creditReportSourceType';
import { DateTime } from '@lib-utils';


export interface CalculatedCreditReportInfo { 
    id?: number;
    sourceReportId?: number;
    requestId?: string | null;
    sources?: Array<CreditReportSourceType> | null;
    sourceReportIds?: Array<number> | null;
    requestIds?: Array<string> | null;
    created?: DateTime;
    updated?: DateTime;
}

