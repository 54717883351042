/**
 * Fx.Spr
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CreditBureauObligationOverview } from './creditBureauObligationOverview';
import { MedianPaymentsInfo } from './medianPaymentsInfo';
import { Address } from './address';
import { Legal } from './legal';
import { CreditBureauObligation } from './creditBureauObligation';
import { Fraud } from './fraud';
import { DateTime } from '@lib-utils';
import { Name } from './name';
import { ConnectorDataDetail } from './connectorDataDetail';
import { Passport } from './passport';
import { Score } from './score';
import { ScoringReportItem } from './scoringReportItem';
import { Loan } from './loan';
import { Employment } from './employment';
import { Phone } from './phone';


export interface CreditReportSchema { 
    /**
     * Дата отчета
     */
    reportDate?: DateTime;
    /**
     * Скоринговый балл Retail Score от НБКИ
     */
    pv2Score?: number | null;
    /**
     * Скоринговый балл ОКБ
     */
    okbScore?: number | null;
    /**
     * Скоринговый балл Эквифакс 27
     */
    eq27Score?: number | null;
    /**
     * Список кредитных обязательств, полученные из БКИ
     */
    obligations?: Array<CreditBureauObligation> | null;
    obligationOverview?: CreditBureauObligationOverview;
    /**
     * Вектор сработавших правил Equifax FPS
     */
    equifaxRules?: Array<string> | null;
    /**
     * AntiFraudScore Equifax
     */
    antiFraudScoreEq?: string | null;
    /**
     * Код сводного отчета
     */
    consolidatedReportCode?: string | null;
    /**
     * Скоринговый отчет
     */
    scoringReport?: { [key: string]: ScoringReportItem; } | null;
    /**
     * Информация о паспортах
     */
    passports?: Array<Passport> | null;
    /**
     * Идентификатор запроса, с которым запрашивался отчет
     */
    requestId?: string | null;
    /**
     * Информация о источнике.
     */
    connectorDataDetails?: Array<ConnectorDataDetail> | null;
    /**
     * Сведения о субъекте-ФЛ.
     */
    names?: Array<Name> | null;
    /**
     * Данные по кредитному делу.
     */
    loans?: Array<Loan> | null;
    /**
     * Блок с информацией по судебному решению.
     */
    legals?: Array<Legal> | null;
    /**
     * Блок с информацией по судебному решению.
     */
    frauds?: Array<Fraud> | null;
    /**
     * Скоринговый отчет.
     */
    scores?: Array<Score> | null;
    /**
     * Информация об адресе.
     */
    addresses?: Array<Address> | null;
    /**
     * Дата согласия.
     */
    consentDate?: DateTime | null;
    /**
     * Признак использования кэша из запроса.
     */
    cacheUse?: number;
    /**
     * Данные о телфонном номере.
     */
    phones?: Array<Phone> | null;
    /**
     * Сведения о работодателе.
     */
    employments?: Array<Employment> | null;
    equifaxRulesWithScores?: { [key: string]: number; } | null;
    medianPayments?: MedianPaymentsInfo;
}

